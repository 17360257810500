<template>
  <svg width="70" height="74" viewBox="0 0 70 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="34.1279" cy="34.1279" r="34.1279" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 69.05 73.4079)" fill="#292929"/>
    <circle cx="33.5665" cy="33.5665" r="33.5665" transform="matrix(1 0 0 -1 1.35547 72.8464)" fill="url(#paint0_radial_1051_688)"/>
    <circle cx="34.9219" cy="39.28" r="31.6961" fill="url(#paint1_radial_1051_688)"/>
    <circle cx="34.9219" cy="39.28" r="31.6961" fill="url(#paint2_linear_1051_688)" fill-opacity="0.5"/>
    <g filter="url(#filter0_f_1051_688)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M61.6257 53.3621C56.2891 62.0969 46.3288 67.9719 34.9223 67.9719C23.5159 67.9719 13.5556 62.0969 8.21899 53.3621C13.175 63.0727 23.2722 69.722 34.9223 69.722C46.5725 69.722 56.6697 63.0727 61.6257 53.3621Z" fill="#E2E2E2"/>
    </g>
    <g filter="url(#filter1_f_1051_688)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M62.4758 29.5916C60.176 24.2582 56.3403 19.4944 51.12 16.0253C42.859 10.5356 32.9519 9.49852 24.28 12.3535C33.1771 7.95076 43.4719 7.97711 51.6022 13.38C57.3292 17.1858 61.0127 23.015 62.4758 29.5916Z" fill="white"/>
    </g>
    <g filter="url(#filter2_f_1051_688)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3424 17.6384C9.80618 22.0312 7.22471 27.3162 5.99199 33.232C4.74284 39.2267 5.02207 45.1807 6.5625 50.681C4.26589 45.1601 3.54572 39.0855 4.81153 33.0109C6.06439 26.9984 9.10084 21.763 13.3424 17.6384Z" fill="white"/>
    </g>
    <g filter="url(#filter3_i_1051_688)">
    <circle cx="34.8237" cy="39.6749" r="22.0054" fill="white"/>
    </g>
    <g filter="url(#filter4_d_1051_688)">
    <path d="M49.545 54.3961C52.6829 51.2583 54.736 47.1999 55.4049 42.813C56.0738 38.4261 55.3234 33.9402 53.2633 30.0098C51.2031 26.0794 47.941 22.9101 43.9528 20.9642C39.9645 19.0184 35.4589 18.3978 31.0931 19.193L34.8238 39.6749L49.545 54.3961Z" fill="#A2A2A2"/>
    </g>
    <path d="M27.9497 35.7838C15.4473 50.9421 12.5595 61.1052 12.5595 61.1052C7.09623 55.3951 3.43384 46.9863 3.59027 38.5579C3.91169 21.2402 17.279 8.19686 34.1692 7.60093C42.2926 7.31431 51.1968 10.6679 56.8292 16.406C56.8292 16.406 38.7956 22.6338 27.9497 35.7838Z" fill="url(#paint3_linear_1051_688)" fill-opacity="0.9"/>
    <defs>
    <filter id="filter0_f_1051_688" x="7.46899" y="52.6121" width="54.9067" height="17.86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_1051_688"/>
    </filter>
    <filter id="filter1_f_1051_688" x="23.53" y="8.42902" width="39.6958" height="21.9126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_1051_688"/>
    </filter>
    <filter id="filter2_f_1051_688" x="3.19238" y="16.6384" width="11.1499" height="35.0426" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_1051_688"/>
    </filter>
    <filter id="filter3_i_1051_688" x="10.8184" y="17.6696" width="46.0107" height="46.0107" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="2"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1051_688"/>
    </filter>
    <filter id="filter4_d_1051_688" x="29.093" y="18.856" width="28.5498" height="39.5402" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1051_688"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1051_688" result="shape"/>
    </filter>
    <radialGradient id="paint0_radial_1051_688" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.5665 32.6336) rotate(-118.224) scale(32.1868 34.5049)">
    <stop offset="0.875" stop-color="#242424"/>
    <stop offset="1" stop-color="#B1B1B1"/>
    </radialGradient>
    <radialGradient id="paint1_radial_1051_688" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.9219 39.28) rotate(141.698) scale(32.7193)">
    <stop stop-color="#8E8E8E"/>
    <stop offset="0.821183" stop-color="#A1A1A1"/>
    <stop offset="0.917662" stop-color="#757575"/>
    <stop offset="0.975616" stop-color="#040200"/>
    </radialGradient>
    <linearGradient id="paint2_linear_1051_688" x1="60.0767" y1="19.3036" x2="26.4295" y2="54.6332" gradientUnits="userSpaceOnUse">
    <stop stop-color="#A1A1A1"/>
    <stop offset="0.401009" stop-color="#4C4C4C"/>
    <stop offset="0.963542" stop-color="#A1A1A1"/>
    </linearGradient>
    <linearGradient id="paint3_linear_1051_688" x1="15.317" y1="20.0032" x2="59.3882" y2="59.5034" gradientUnits="userSpaceOnUse">
    <stop offset="0.0198807" stop-color="white" stop-opacity="0.95"/>
    <stop offset="0.713542" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    </defs>
  </svg>
</template>