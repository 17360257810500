<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.00024 3.49998L1.00024 16C1.00024 17.1045 1.89567 18 3.00024 18L21.1876 18" :stroke="colorFill" stroke-width="1.75" stroke-linecap="round"/>
    <path d="M4.87512 14.625L8.5 9.62498L13.4375 14.0625L18.375 6.93748" :stroke="colorFill" stroke-width="1.75" stroke-linecap="square"/>
    <path d="M21.3993 2.73271L21.6723 8.17366L16.2049 4.37492L21.3993 2.73271Z" :fill="colorFill"/>
  </svg>
</template>
<script setup>
  import { ref, computed } from 'vue' 

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 25
    },
    height: {
      type: Number,
      default: 19
    },
    size: {
      type: Number,
      default: 1.0
    },
    color: {
      type: String,
      default: 'FFFFFF'
    }
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
  //const fill = ref('')

  const colorFill = computed(() => {
    return `#${props.color}`
  })
</script>