<template>
  <svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_1055_994)">
    <path d="M18.5727 3.39299C24.8753 4.9632 30.6902 9.10403 29.1233 15.3935C28.287 18.7504 20.2016 16.4765 17.3668 17.9839C14.8903 19.3008 12.9152 26.8275 9.97647 26.0953C3.67387 24.5251 2.75663 18.2277 4.32358 11.9382C5.89053 5.64865 12.2701 1.82278 18.5727 3.39299Z" fill="url(#paint0_radial_1055_994)"/>
    </g>
    <defs>
    <filter id="filter0_f_1055_994" x="0.654663" y="0.0406799" width="31.7271" height="29.1046" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_1055_994"/>
    </filter>
    <radialGradient id="paint0_radial_1055_994" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5727 24.526) rotate(-124.454) scale(22.5883 15.9851)">
    <stop offset="0.609375" stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0.1"/>
    </radialGradient>
    </defs>
  </svg>
</template>