<template>
  <Button 
    class="flex thumbnail-btn" 
    :eventName="'accordion'" 
    @clicked="clicked($event)"
  >
  <template #accordion>
    <div class="question-cointainer">
      <span>{{ faq.id }}. </span>
      <span
        class="faq-question"
        v-html="faq.question"
      ></span>
    </div>
    <Minus 
      v-if="isActive" 
      class="minus"
      :color="constant.GREY"
    />
    <Plus 
      v-else 
      class="plus" 
      :color="constant.GREY"
    />
  </template>
  </Button>
</template>
<script setup>
  import { computed } from 'vue'
  import Button from '../../../_generics/Button.vue'
  import Plus from '../../../_icons/Plus.vue'
  import Minus from '../../../_icons/Minus.vue'

  const props = defineProps({
    id: {
      type: Number,
      default: 0
    },
    constant: {
      type: Object,
      default: () => {}
    },
    faq: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    },
  })

  const constant = props.constant
  const emit = defineEmits(['activateFaqAccordion'])

  const clicked = (event) => {
    const settings = {
      id:  props.faq.id,
      isActive: !props.faq.isActive
    }
    emit('activateFaqAccordion', settings)
  }

</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';
  @import '../../../../assets/scss/mixins/layout.scss';
  
  button {
    padding: 0 30px 0 20px;
    color: $GREY;
    border-bottom: thin solid $BLACK-XLIGHT;
    font-size: $FONT-DEFAULT-SIZE;

    
    @include breakpoint(sm) {
      padding: 0 30px;
    }
    @include breakpoint(md) {
      padding: 0 30px;
    }
    @include breakpoint(lg) {
      padding: 0 30px;
    }
    @include breakpoint(xl) {
      padding: 0 30px;
    }
    @include breakpoint(xxl) {
      padding: 0 30px;
    }
    @include breakpoint(xxxl) {
      padding: 0 30px;
    }


    &.flex {

      &.thumbnail-btn {
        height: 100px;
        background-color: $WHITE;
      }

      .question-cointainer {
        display: flex;
        width: 100%;
        text-align: left;

        span:first-child {
          margin-right: 10px;
        }

        .faq-question {
          width: 90%;

          @include breakpoint(sm) {
            width: 100%;
          }
          @include breakpoint(md) {
            width: 100%;
          }
          @include breakpoint(lg) {
            width: 100%;
          }
          @include breakpoint(xl) {
            width: 100%;
          }
          @include breakpoint(xxl) {
            width: 100%;
          }
          @include breakpoint(xxxl) {
            width: 100%;
          }
        }
      }
    }
  }
</style>