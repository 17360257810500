<template>
  <Transition name="slider">
    <div
      v-if="!loading && route.name === constant.MINTING"
      class="mint-collateral-controls"
    >
      <div
        class="container"
      >
        <div
          class="control-btns"
        >
          <div class="flex">
            <Button 
              v-if="isMinted"
              class="default reset" 
              :eventName="constant.RESET_MINTED_COINS" 
              @clicked="resetMintQuantity($event)"
            >
              <ResetBtn>
              </ResetBtn>
            </Button>
            <div
              v-else
              class="default in-active reset"
            >
              <ResetBtn>
              </ResetBtn>
            </div>

            <Button 
              class="default" 
              :eventName="constant.GET_STARTED" 
              @clicked="nextScreen($event)"
            >
              <CloseBtn>
              </CloseBtn>
            </Button>
          </div>
        </div>

        <div class="sub-header">
          <h1>Mint FAssets</h1>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup>
  import {ref} from 'vue'
  import Button from '../_generics/Button.vue'
  import CloseBtn from '../_icons/CloseBtn.vue'
  import ResetBtn from '../_icons/ResetBtn.vue'

  const props = defineProps({
    constant: {
      type: Object, 
      default: () => {}
    },
    isMinted: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    route: {
      type: Object,
      default: () => {}
    }
  })

  const emit = defineEmits([
    'nextScreen',
    'resetMintQuantity',
    'deactivateAccordions'
  ])

  const constant = props.constant

  const nextScreen = (event) => {
    emit(constant.ACCORDION_DEACTIVATE, event)

    if(!props.isMinted)
      return emit('nextScreen', constant.GET_STARTED)
    emit('nextScreen', constant.SIMULATE)   
  }

  const resetMintQuantity = (event) => {
    emit('resetMintQuantity', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';
  @import '../../assets/scss/partials/_transitions.scss';

  .mint-collateral-controls {
    background-color: $BLACK-MEDIUM;
    position: fixed;
    z-index: 2;
    width: 100%;

    .container {
      height: 70px;
      position: relative;
      flex-direction: row;
      margin: 0 auto;
      max-width: $MAX-CONTENT-WIDTH;

      .sub-header {
        margin-top: 22px;

        h1 {
          margin-top: 20px;
          padding: 0;
        }
      }

      .control-btns {
        position: absolute;
        width: 100px;
        height: 40px;
        z-index: 1;
        right: 0;
        margin: 2px 6px 0 0;

        .flex {
          height: 100%;
          justify-content: space-around;

          .default, .reset {
            padding: 0;
            width: 36px;
            height: 36px;
          }

          .default {

            &.in-active {
              opacity: 50%;
            }
          }
        }
      }

    }
  }
</style>