<template>
  <svg width="189" height="92" viewBox="0 0 189 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5049 73.1452C13.8137 72.5837 13.1226 72.1733 12.4315 71.9142C11.7404 71.6334 11.0708 71.493 10.4229 71.493C9.60219 71.493 8.93266 71.6874 8.41431 72.0761C7.89597 72.4649 7.6368 72.9724 7.6368 73.5988C7.6368 74.0307 7.76638 74.3871 8.02555 74.6679C8.28473 74.9486 8.61949 75.197 9.02985 75.413C9.4618 75.6074 9.93695 75.7801 10.4553 75.9313C10.9952 76.0825 11.5244 76.2445 12.0427 76.4173C14.1161 77.1084 15.6279 78.0371 16.5782 79.2034C17.5501 80.3481 18.0361 81.8491 18.0361 83.7065C18.0361 84.9592 17.8201 86.093 17.3882 87.1081C16.9778 88.1232 16.3623 88.9979 15.5416 89.7323C14.7424 90.445 13.7489 90.9957 12.5611 91.3845C11.3948 91.7948 10.0665 92 8.57629 92C5.48782 92 2.62613 91.0821 -0.00878906 89.2463L2.71252 84.1277C3.66282 84.97 4.60232 85.5963 5.53102 86.0067C6.45972 86.417 7.37762 86.6222 8.28473 86.6222C9.32142 86.6222 10.0881 86.3846 10.5849 85.9095C11.1032 85.4343 11.3624 84.8944 11.3624 84.2896C11.3624 83.9225 11.2976 83.6093 11.168 83.3501C11.0384 83.0694 10.8225 82.821 10.5201 82.605C10.2177 82.3674 9.81816 82.1515 9.32142 81.9571C8.84627 81.7627 8.26313 81.5467 7.572 81.3092C6.75129 81.05 5.94138 80.7692 5.14226 80.4668C4.36474 80.1429 3.66282 79.7217 3.03649 79.2034C2.43175 78.685 1.935 78.0371 1.54625 77.2596C1.17908 76.4605 0.995504 75.4562 0.995504 74.2467C0.995504 73.0372 1.18988 71.9466 1.57864 70.9747C1.989 69.9812 2.55054 69.1389 3.26326 68.4477C3.99758 67.735 4.88309 67.1843 5.91978 66.7955C6.97807 66.4067 8.15514 66.2124 9.451 66.2124C10.6605 66.2124 11.9239 66.3851 13.2414 66.7307C14.5589 67.0547 15.8223 67.5406 17.0318 68.1886L14.5049 73.1452Z" fill="#0C0C0C"/>
    <path d="M29.9413 74.7003V91.3197H24.0775V74.7003H29.9413ZM23.6888 68.3181C23.6888 67.8646 23.7752 67.4434 23.9479 67.0547C24.1207 66.6443 24.3583 66.288 24.6607 65.9856C24.963 65.6832 25.3086 65.4456 25.6974 65.2729C26.1077 65.1001 26.5397 65.0137 26.9932 65.0137C27.4468 65.0137 27.8679 65.1001 28.2567 65.2729C28.667 65.4456 29.0234 65.6832 29.3258 65.9856C29.6281 66.288 29.8657 66.6443 30.0385 67.0547C30.2113 67.4434 30.2977 67.8646 30.2977 68.3181C30.2977 68.7717 30.2113 69.2036 30.0385 69.614C29.8657 70.0028 29.6281 70.3483 29.3258 70.6507C29.0234 70.9531 28.667 71.1906 28.2567 71.3634C27.8679 71.5362 27.4468 71.6226 26.9932 71.6226C26.5397 71.6226 26.1077 71.5362 25.6974 71.3634C25.3086 71.1906 24.963 70.9531 24.6607 70.6507C24.3583 70.3483 24.1207 70.0028 23.9479 69.614C23.7752 69.2036 23.6888 68.7717 23.6888 68.3181Z" fill="#0C0C0C"/>
    <path d="M36.5401 74.7003H42.4039V76.7412C44.0021 75.2078 45.7947 74.4411 47.7817 74.4411C50.2438 74.4411 52.1228 75.4778 53.4187 77.5512C54.7361 75.4562 56.6151 74.4087 59.0557 74.4087C59.8332 74.4087 60.5675 74.5275 61.2586 74.7651C61.9498 74.981 62.5437 75.3374 63.0405 75.8341C63.5372 76.3093 63.926 76.9356 64.2067 77.7131C64.5091 78.4907 64.6603 79.4302 64.6603 80.5316V91.3197H58.7965V83.1234C58.7965 82.2811 58.7317 81.5899 58.6021 81.05C58.4941 80.51 58.3214 80.0889 58.0838 79.7865C57.8678 79.4842 57.6086 79.279 57.3063 79.171C57.0039 79.063 56.6799 79.009 56.3344 79.009C54.4554 79.009 53.5159 80.3805 53.5159 83.1234V91.3197H47.6521V83.1234C47.6521 82.2811 47.5981 81.5899 47.4901 81.05C47.3821 80.4884 47.2201 80.0457 47.0042 79.7217C46.8098 79.3978 46.5506 79.1818 46.2266 79.0738C45.9027 78.9442 45.5247 78.8794 45.0928 78.8794C44.7256 78.8794 44.38 78.9334 44.0561 79.0414C43.7321 79.1494 43.4405 79.3654 43.1814 79.6893C42.9438 79.9917 42.7494 80.4237 42.5982 80.9852C42.4686 81.5251 42.4039 82.2379 42.4039 83.1234V91.3197H36.5401V74.7003Z" fill="#0C0C0C"/>
    <path d="M85.2805 72.2705H77.6349V76.3525H84.6002V81.7303H77.6349V91.3197H71.2852V66.8927H85.2805V72.2705Z" fill="#6401E1"/>
    <path d="M96.3275 82.9614C96.3275 83.5013 96.4247 84.0089 96.619 84.484C96.8134 84.9376 97.0726 85.3371 97.3966 85.6827C97.7421 86.0283 98.1417 86.2982 98.5952 86.4926C99.0704 86.687 99.5779 86.7842 100.118 86.7842C100.636 86.7842 101.122 86.687 101.576 86.4926C102.051 86.2982 102.45 86.0283 102.774 85.6827C103.12 85.3371 103.39 84.9376 103.584 84.484C103.8 84.0305 103.908 83.5445 103.908 83.0262C103.908 82.5078 103.8 82.0219 103.584 81.5683C103.39 81.0932 103.12 80.6828 102.774 80.3373C102.45 79.9917 102.051 79.7217 101.576 79.5273C101.122 79.333 100.636 79.2358 100.118 79.2358C99.5779 79.2358 99.0704 79.333 98.5952 79.5273C98.1417 79.7217 97.7421 79.9917 97.3966 80.3373C97.0726 80.6828 96.8134 81.0824 96.619 81.5359C96.4247 81.9679 96.3275 82.443 96.3275 82.9614ZM103.714 74.7003H109.61V91.3197H103.714V89.4731C102.461 91.0497 100.766 91.838 98.6276 91.838C97.4182 91.838 96.3059 91.6221 95.2908 91.1901C94.2757 90.7366 93.3902 90.1102 92.6343 89.3111C91.8784 88.512 91.2844 87.5725 90.8525 86.4926C90.4421 85.4127 90.2369 84.2356 90.2369 82.9614C90.2369 81.7735 90.4421 80.6504 90.8525 79.5921C91.2628 78.5123 91.8352 77.5728 92.5695 76.7736C93.3038 75.9745 94.1785 75.3482 95.1936 74.8946C96.2087 74.4195 97.3318 74.1819 98.5628 74.1819C100.636 74.1819 102.353 74.9054 103.714 76.3525V74.7003Z" fill="#6401E1"/>
    <path d="M127.207 78.9766C126.127 78.4151 125.069 78.1343 124.032 78.1343C123.492 78.1343 123.049 78.2423 122.704 78.4583C122.38 78.6742 122.218 78.9658 122.218 79.333C122.218 79.5273 122.239 79.6893 122.283 79.8189C122.347 79.9269 122.466 80.0349 122.639 80.1429C122.833 80.2509 123.103 80.3481 123.449 80.4344C123.816 80.5208 124.291 80.6288 124.874 80.7584C126.494 81.0824 127.704 81.6655 128.503 82.5078C129.302 83.3285 129.701 84.3868 129.701 85.6827C129.701 86.6546 129.507 87.5293 129.118 88.3068C128.73 89.0843 128.19 89.7431 127.499 90.283C126.807 90.8013 125.976 91.2009 125.004 91.4817C124.032 91.784 122.963 91.9352 121.797 91.9352C119.551 91.9352 117.337 91.2981 115.155 90.0238L117.294 85.8771C118.935 87.0001 120.49 87.5617 121.959 87.5617C122.499 87.5617 122.941 87.4429 123.287 87.2053C123.633 86.9678 123.805 86.6654 123.805 86.2982C123.805 86.0822 123.773 85.9095 123.708 85.7799C123.665 85.6287 123.557 85.4991 123.384 85.3911C123.211 85.2615 122.952 85.1535 122.607 85.0672C122.283 84.9592 121.851 84.8512 121.311 84.7432C119.497 84.376 118.222 83.8361 117.488 83.1234C116.775 82.389 116.419 81.3847 116.419 80.1105C116.419 79.1818 116.592 78.3503 116.937 77.616C117.283 76.86 117.78 76.2229 118.427 75.7046C119.075 75.1862 119.853 74.7867 120.76 74.5059C121.689 74.2251 122.715 74.0847 123.838 74.0847C125.674 74.0847 127.455 74.4411 129.183 75.1538L127.207 78.9766Z" fill="#6401E1"/>
    <path d="M146.282 78.9766C145.202 78.4151 144.144 78.1343 143.107 78.1343C142.567 78.1343 142.124 78.2423 141.779 78.4583C141.455 78.6742 141.293 78.9658 141.293 79.333C141.293 79.5273 141.314 79.6893 141.357 79.8189C141.422 79.9269 141.541 80.0349 141.714 80.1429C141.908 80.2509 142.178 80.3481 142.524 80.4344C142.891 80.5208 143.366 80.6288 143.949 80.7584C145.569 81.0824 146.778 81.6655 147.578 82.5078C148.377 83.3285 148.776 84.3868 148.776 85.6827C148.776 86.6546 148.582 87.5293 148.193 88.3068C147.804 89.0843 147.264 89.7431 146.573 90.283C145.882 90.8013 145.051 91.2009 144.079 91.4817C143.107 91.784 142.038 91.9352 140.871 91.9352C138.625 91.9352 136.412 91.2981 134.23 90.0238L136.368 85.8771C138.01 87.0001 139.565 87.5617 141.033 87.5617C141.573 87.5617 142.016 87.4429 142.362 87.2053C142.707 86.9678 142.88 86.6654 142.88 86.2982C142.88 86.0822 142.848 85.9095 142.783 85.7799C142.74 85.6287 142.632 85.4991 142.459 85.3911C142.286 85.2615 142.027 85.1535 141.681 85.0672C141.357 84.9592 140.925 84.8512 140.386 84.7432C138.571 84.376 137.297 83.8361 136.563 83.1234C135.85 82.389 135.494 81.3847 135.494 80.1105C135.494 79.1818 135.666 78.3503 136.012 77.616C136.358 76.86 136.854 76.2229 137.502 75.7046C138.15 75.1862 138.928 74.7867 139.835 74.5059C140.763 74.2251 141.789 74.0847 142.912 74.0847C144.748 74.0847 146.53 74.4411 148.258 75.1538L146.282 78.9766Z" fill="#6401E1"/>
    <path d="M166.49 80.5964C166.296 79.7757 165.896 79.117 165.292 78.6202C164.687 78.1235 163.953 77.8751 163.089 77.8751C162.182 77.8751 161.436 78.1127 160.853 78.5878C160.292 79.063 159.935 79.7325 159.784 80.5964H166.49ZM159.622 83.9981C159.622 86.525 160.81 87.7885 163.186 87.7885C164.46 87.7885 165.421 87.2701 166.069 86.2334H171.739C170.594 90.0346 167.732 91.9352 163.153 91.9352C161.75 91.9352 160.465 91.73 159.298 91.3197C158.132 90.8877 157.128 90.283 156.285 89.5055C155.465 88.728 154.828 87.7993 154.374 86.7194C153.92 85.6395 153.694 84.43 153.694 83.091C153.694 81.7087 153.91 80.4668 154.342 79.3654C154.774 78.2423 155.389 77.292 156.188 76.5145C156.987 75.737 157.948 75.143 159.071 74.7327C160.216 74.3007 161.501 74.0847 162.927 74.0847C164.331 74.0847 165.594 74.3007 166.717 74.7327C167.84 75.143 168.79 75.7477 169.568 76.5469C170.345 77.346 170.939 78.3287 171.35 79.495C171.76 80.6396 171.965 81.9355 171.965 83.3825V83.9981H159.622Z" fill="#6401E1"/>
    <path d="M184.674 79.5921V91.3197H178.811V79.5921H176.867V74.7003H178.811V69.7112H184.674V74.7003H188.011V79.5921H184.674Z" fill="#6401E1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.9802 18.4366C102.005 11.2601 110.634 3.55815 122.025 3.91974C137.329 4.40556 148.591 16.7808 148.136 31.0092C147.92 37.8032 145.312 44.472 140.359 49.4362C135.335 54.4712 128.236 57.4107 119.769 57.2344C113.638 57.1066 107.045 54.5218 101.187 48.2451C99.3041 46.2276 97.4713 43.6453 95.7371 41.0394C93.8449 43.7843 91.838 46.5415 89.8753 48.4596C83.534 54.6568 77.179 57.2358 70.4106 57.2358C60.8072 57.2358 53.803 53.122 49.3356 47.6031C45.06 42.3212 43.1885 35.8766 43.1885 30.8011C43.1885 25.5466 44.973 18.9804 49.2672 13.5988C53.7503 7.9805 60.7835 3.91645 70.4106 3.91645C81.9624 3.91645 90.1906 11.2865 95.9802 18.4366ZM103.809 29.5308C104.003 29.8303 104.195 30.1262 104.384 30.4181C104.583 30.725 104.779 31.0274 104.972 31.3247C107.346 34.9821 109.143 37.6452 110.726 39.3418C114.317 43.1891 117.625 44.1383 120.041 44.1886C125.192 44.2959 128.779 42.5672 131.122 40.2195C133.535 37.8011 134.974 34.3728 135.094 30.593C135.315 23.6735 129.817 17.2223 121.611 16.9618C116.181 16.7894 110.716 20.8452 104.807 28.2535C104.47 28.676 104.138 29.102 103.809 29.5308ZM87.9127 29.3462C87.5336 28.8292 87.1493 28.3174 86.7589 27.8118C81.409 20.8837 76.269 16.9651 70.4106 16.9651C64.8518 16.9651 61.536 19.1441 59.4667 21.7374C57.2085 24.5674 56.2371 28.1814 56.2371 30.8011C56.2371 33.0373 57.1745 36.5479 59.4778 39.3932C61.5892 42.0016 64.934 44.1872 70.4106 44.1872C73.2797 44.1872 76.4768 43.3085 80.7552 39.1274C82.0414 37.8704 83.7522 35.4698 86.2633 31.7749C86.4655 31.4773 86.6714 31.1738 86.8809 30.865C87.2158 30.3713 87.5598 29.8641 87.9127 29.3462Z" fill="url(#paint0_linear_1584_960)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.9563 20.95C101.99 13.4171 110.59 5.13239 121.974 5.49378C136.423 5.9524 146.988 17.6127 146.562 30.959C146.357 37.3892 143.89 43.6669 139.244 48.3239C134.543 53.0345 127.868 55.8279 119.802 55.6599C114.119 55.5415 107.923 53.1541 102.338 47.1706C100.084 44.7552 97.8762 41.4569 95.7625 38.2099C95.7593 38.2147 95.7561 38.2194 95.7529 38.2241C93.4617 41.5954 91.0628 45.097 88.7745 47.3333C82.6821 53.2872 76.7083 55.6609 70.4104 55.6609C61.3051 55.6609 54.7426 51.7799 50.5595 46.6123C46.5219 41.6244 44.7632 35.5339 44.7632 30.8011C44.7632 25.8646 46.4496 19.6547 50.4981 14.581C54.6899 9.32783 61.2743 5.49129 70.4104 5.49129C81.9404 5.49129 90.0658 13.3215 95.8401 20.7991C95.8789 20.8493 95.9176 20.8997 95.9563 20.95ZM101.885 29.4617C102.288 30.0798 102.682 30.6871 103.066 31.2797C103.263 31.5847 103.458 31.8857 103.651 32.1823C106.007 35.811 107.879 38.5994 109.575 40.4164C113.439 44.5568 117.144 45.7034 120.008 45.7631C125.559 45.8787 129.57 44.0039 132.236 41.3319C134.956 38.6062 136.536 34.7869 136.668 30.6432C136.917 22.8416 130.724 15.6754 121.66 15.3877C115.403 15.1891 109.503 19.8407 103.576 27.2716C103.001 27.9929 102.437 28.7243 101.885 29.4617ZM89.8376 29.3203C89.2404 28.4846 88.6307 27.6592 88.0052 26.8492C82.5971 19.8458 77.0473 15.3902 70.4104 15.3902C64.3607 15.3902 60.5963 17.7968 58.2356 20.7552C55.7317 23.8931 54.6621 27.8634 54.6621 30.8011C54.6621 33.38 55.7123 37.2446 58.2536 40.3841C60.6494 43.3437 64.4358 45.762 70.4104 45.762C73.7502 45.762 77.3284 44.6782 81.8557 40.2537C83.2795 38.8623 85.0848 36.3105 87.5656 32.6601C87.7676 32.363 87.9728 32.0605 88.1811 31.7534C88.7129 30.9696 89.2653 30.1552 89.8376 29.3203Z" fill="url(#paint1_linear_1584_960)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M100.782 30.5762C100.782 47.35 87.1842 60.9479 70.4103 60.9479C53.6365 60.9479 40.0386 47.35 40.0386 30.5762C40.0386 13.8023 53.6365 0.204407 70.4103 0.204407C87.1842 0.204407 100.782 13.8023 100.782 30.5762ZM70.4103 59.1481C86.1902 59.1481 98.9823 46.356 98.9823 30.5762C98.9823 14.7963 86.1902 2.00421 70.4103 2.00421C54.6305 2.00421 41.8384 14.7963 41.8384 30.5762C41.8384 46.356 54.6305 59.1481 70.4103 59.1481Z" fill="url(#paint2_linear_1584_960)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.8832 30.8011C90.8832 47.6993 104.582 61.3979 121.48 61.3979C138.378 61.3979 152.077 47.6993 152.077 30.8011C152.077 13.903 138.378 0.204407 121.48 0.204407C104.582 0.204407 90.8832 13.903 90.8832 30.8011ZM121.48 59.5981C105.576 59.5981 92.683 46.7052 92.683 30.8011C92.683 14.897 105.576 2.00421 121.48 2.00421C137.384 2.00421 150.277 14.897 150.277 30.8011C150.277 46.7052 137.384 59.5981 121.48 59.5981Z" fill="url(#paint3_linear_1584_960)"/>
    <defs>
      <linearGradient id="paint0_linear_1584_960" x1="49.7128" y1="30.5749" x2="141.625" y2="30.5749" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6300E0" stop-opacity="0"/>
      <stop offset="1" stop-color="#6300E0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1584_960" x1="64.3361" y1="30.575" x2="134.529" y2="30.5749" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0C0C0C"/>
      <stop offset="1" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1584_960" x1="41.8384" y1="31.2511" x2="92.3455" y2="31.1386" gradientUnits="userSpaceOnUse">
      <stop/>
      <stop offset="1" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1584_960" x1="150.277" y1="33.0686" x2="100.051" y2="33.3321" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2A0060"/>
      <stop offset="1" stop-color="#2A0060" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
  <span class="trademark">&trade;</span>
</template>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  
  .trademark {
    position: relative;
    color: $BLACK;
    margin: 96px 0 0 0;
  }
</style>