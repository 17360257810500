<template>
  <div 
    id="mint" 
    class="page"
  >

    <ButtonToggle
      class="container"
      :constant="constant"
      :toggleButtons="screensMintCollateral"
      @btnToggleClicked="btnToggleClicked($event)"
    />

    <MintAsset
      v-if="screensMintCollateral[0].isActive"
      :constant="constant"
      :coins="coins"
      :isMinted="isMinted"
      :activeNetwork="activeNetwork"
      :accordionScreens="accordionScreens"
      :configs="configs"
      :collateralFAssetIsActive="collateralFAssetIsActive"
      :networkCollateralAllocation="networkCollateralAllocation"
      @activateAccordion="activateAccordion($event)"
      @updateMintQuantity="updateMintQuantity($event)"
      @updateModifiedCoinPrice="updateModifiedCoinPrice($event)"
    />

    <MintCollateral
      v-else
      :constant="constant"
      :chartData="chartsData[2]"
      :configs="configs"
      :collateralManagementDualIsActive="collateralManagementDualIsActive"
      :collateralFAssetIsActive="collateralFAssetIsActive"
      :networkCollateralAllocation="networkCollateralAllocation"
      :activeNetwork="activeNetwork"
      @updateCollateralType="updateCollateralType($event)"
      @updateChartSettings="updateChartSettings($event)"
      @updateNetworkCollateralAllocation="updateNetworkCollateralAllocation($event)"
    />

  </div>
</template>
<script setup>
  import { event } from 'vue-gtag'
  import { ref, computed, watch } from 'vue'
  import ButtonToggle from '../_generics/ButtonToggle.vue'
  import MintAsset from './MintAsset.vue'
  import MintCollateral from './MintCollateral.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coins: {
      type: Array,
      default: () => []
    },
    isMinted: {
      type: Boolean,
      default: false
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    accordionScreens: {
      type: Array,
      default: () => []
    },
    screensMintCollateral: {
      type: Array,
      default: () => []
    },
    configs: {
      type: Object,
      default: () => {}
    },
    chartsData: {
      type: Array,
      default: () => []
    },
  })
  
  const emit = defineEmits([
    'activateAccordion', 
    'updateMintQuantity',
    'updateChartSettings',
    'updateCollateralType',
    'updateModifiedCoinPrice',
    'updateScreenMintCollateral',
    'throttleAllMaxMintQuantities',
    'updateNetworkCollateralAllocation',
  ])
  const constant = props.constant

  const collateralFAssetIsActive = computed(() => {
    return props.activeNetwork.collateralManagement[1].isActive
  })

  const collateralManagementDualIsActive = computed(() => {
    const type = props.activeNetwork.collateralManagement.find((t) => t.isActive)
    return type
  })

  const networkCollateralAllocation = computed(() => {
    if(collateralFAssetIsActive.value) {
      return props.activeNetwork.collateralManagement[1].allocation
    }
    return props.activeNetwork.collateralManagement[0].allocation
  })

  const activateAccordion = (event) => {
    setTimeout(() => {
      scrollSmoothToAnchor(props.coins[event - 1].ticker)
    }, 333)
    
    emit(constant.ACCORDION_ACTIVATE, event)
  }

  const btnToggleClicked = (event) => {
    const screenActive = {
      id: event === constant.MINT_FASSETS ? 1 : 2
    }

    if(constant.MINT_FASSETS === event ) {
      trackEvent('fmint_toggle', 'fmint_toggle_btn', 'fmint_click')
    } else {
      trackEvent('collateral_toggle', 'collateral_toggle_btn', 'collateral_click')
    }

    emit('updateScreenMintCollateral', screenActive)
  }

  const updateMintQuantity = (event) => {
    emit('updateMintQuantity', event)
  }

  const updateModifiedCoinPrice = (event) => {
    emit('updateModifiedCoinPrice', event)
  }

  const updateCollateralType = (event) => {
    emit('updateCollateralType', event)
  }

  const updateChartSettings = (event) => {
    emit('updateChartSettings', event)
  }

  const updateNetworkCollateralAllocation = (event) => {
    emit('updateNetworkCollateralAllocation', event)
  }

  watch(() => [props.configs.settings[constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID - 1].set], (newValue, oldValue) => {
    if(parseFloat(newValue[0]) < parseFloat(oldValue[0])) {
      emit('throttleAllMaxMintQuantities', newValue[0])
    }
  })

  const scrollSmoothToAnchor = (id) => {
    let currentScroll = document.getElementById(id)
    window.scroll({top: currentScroll.offsetTop + 75, behavior: "smooth"})
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .page {
    padding-top: calc($HEADER-HEIGHT + 110px);

    .container {
      height: auto;
      margin-bottom: 15px;
    }
  }
</style>