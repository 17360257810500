<template>
  <svg width="70" height="73" viewBox="0 0 70 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M69.0458 38.8487C69.0458 20.0004 53.7663 4.72083 34.9179 4.72083C16.0696 4.72083 0.790039 20.0004 0.790039 38.8487C0.790039 57.697 16.0696 72.9766 34.9179 72.9766C53.7663 72.9766 69.0458 57.697 69.0458 38.8487Z" fill="#292929"/>
    <path d="M58.6528 15.1136C45.5443 2.00504 24.2912 2.00504 11.1826 15.1136C-1.92589 28.2221 -1.92589 49.4752 11.1826 62.5837C24.2912 75.6923 45.5443 75.6923 58.6528 62.5837C71.7614 49.4752 71.7614 28.2221 58.6528 15.1136Z" fill="url(#paint0_radial_1127_609)"/>
    <path d="M66.6138 38.8487C66.6138 56.354 52.423 70.5448 34.9177 70.5448C17.4124 70.5448 3.22164 56.354 3.22164 38.8487C3.22164 21.3434 17.4124 7.15263 34.9177 7.15263C52.423 7.15263 66.6138 21.3434 66.6138 38.8487Z" fill="url(#paint1_radial_1127_609)"/>
    <path d="M66.6138 38.8487C66.6138 56.354 52.423 70.5448 34.9177 70.5448C17.4124 70.5448 3.22164 56.354 3.22164 38.8487C3.22164 21.3434 17.4124 7.15263 34.9177 7.15263C52.423 7.15263 66.6138 21.3434 66.6138 38.8487Z" fill="url(#paint2_linear_1127_609)" fill-opacity="0.5"/>
    <g filter="url(#filter0_f_1127_609)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M61.6213 51.9306C56.2847 60.6655 46.3244 66.5404 34.918 66.5404C23.5115 66.5404 13.5512 60.6654 8.2146 51.9306C13.1706 61.6412 23.2678 68.2906 34.918 68.2906C46.5681 68.2906 56.6653 61.6412 61.6213 51.9306Z" fill="#FFD600"/>
    </g>
    <g filter="url(#filter1_f_1127_609)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M62.4758 29.5907C60.176 24.2573 56.3403 19.4935 51.12 16.0245C42.859 10.5347 32.9519 9.49763 24.28 12.3527C33.1771 7.94988 43.4719 7.97622 51.6022 13.3791C57.3292 17.1849 61.0127 23.0141 62.4758 29.5907Z" fill="white"/>
    </g>
    <g filter="url(#filter2_f_1127_609)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3382 17.2073C9.80203 21.6001 7.22056 26.8851 5.98784 32.8009C4.73869 38.7956 5.01792 44.7496 6.55835 50.2499C4.26174 44.729 3.54157 38.6544 4.80738 32.5798C6.06024 26.5673 9.09669 21.332 13.3382 17.2073Z" fill="white"/>
    </g>
    <g filter="url(#filter3_i_1127_609)">
    <circle cx="34.8254" cy="39.6754" r="22.0054" fill="white"/>
    </g>
    <g filter="url(#filter4_d_1127_609)">
    <path d="M49.5467 54.3966C52.6846 51.2587 54.7377 47.2003 55.4066 42.8134C56.0755 38.4265 55.3251 33.9407 53.265 30.0103C51.2048 26.0798 47.9427 22.9105 43.9545 20.9647C39.9662 19.0188 35.4606 18.3982 31.0948 19.1934L34.8255 39.6754L49.5467 54.3966Z" fill="#FD8A16"/>
    </g>
    <path d="M27.9543 36.1622C15.4519 51.3205 12.5642 61.4836 12.5642 61.4836C7.10087 55.7736 3.43848 47.3648 3.59491 38.9363C3.91633 21.6187 17.2836 8.57527 34.1739 7.97934C42.2972 7.69273 51.2015 11.0463 56.8339 16.7844C56.8339 16.7844 38.8002 23.0122 27.9543 36.1622Z" fill="url(#paint3_linear_1127_609)" fill-opacity="0.9"/>
    <defs>
    <filter id="filter0_f_1127_609" x="7.4646" y="51.1806" width="54.9067" height="17.86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_1127_609"/>
    </filter>
    <filter id="filter1_f_1127_609" x="23.53" y="8.42813" width="39.6958" height="21.9126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_1127_609"/>
    </filter>
    <filter id="filter2_f_1127_609" x="3.18823" y="16.2073" width="11.1499" height="35.0426" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_1127_609"/>
    </filter>
    <filter id="filter3_i_1127_609" x="10.8201" y="17.67" width="46.0107" height="46.0107" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="2"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1127_609"/>
    </filter>
    <filter id="filter4_d_1127_609" x="28.0947" y="17.8564" width="30.5498" height="41.5402" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="1.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1127_609"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1127_609" result="shape"/>
    </filter>
    <radialGradient id="paint0_radial_1127_609" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.9179 37.9002) rotate(-118.224) scale(32.7251 35.082)">
    <stop offset="0.875" stop-color="#242424"/>
    <stop offset="1" stop-color="#B1B1B1"/>
    </radialGradient>
    <radialGradient id="paint1_radial_1127_609" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.9179 38.8487) rotate(141.698) scale(35.2296)">
    <stop stop-color="#F57B01"/>
    <stop offset="0.821183" stop-color="#FF8D1B"/>
    <stop offset="0.917662" stop-color="#8F4902"/>
    <stop offset="0.975616" stop-color="#040200"/>
    </radialGradient>
    <linearGradient id="paint2_linear_1127_609" x1="60.0724" y1="18.8723" x2="26.4252" y2="54.2019" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F57B01"/>
    <stop offset="0.401009" stop-color="#7B3E00"/>
    <stop offset="0.963542" stop-color="#F57B01"/>
    </linearGradient>
    <linearGradient id="paint3_linear_1127_609" x1="15.3217" y1="20.3816" x2="59.3929" y2="59.8818" gradientUnits="userSpaceOnUse">
    <stop offset="0.0198807" stop-color="white" stop-opacity="0.95"/>
    <stop offset="0.713542" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    </defs>
  </svg>
</template>