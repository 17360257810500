<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M0.961792 10.84V8.76H19.1618V10.84H0.961792ZM8.96179 0.32H11.1618V19.28H8.96179V0.32Z" 
      :fill="fill"
    />
  </svg>
</template>
<script setup>
  const props = defineProps({
    color: {
      type: String,
      default: 'FFFFFF'
    },
  })

  const fill = `#${props.color}`
</script>