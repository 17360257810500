<template>
  <svg 
    :width="fWidth" 
    :height="fHeight"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path transform="matrix(.73596 .67702 -.73596 .67702 0 1.60178)" :stroke="`#${color}`" stroke-width="2.5" stroke-linecap="round" d="M1.25-1.25h24.6752"/>
    <path transform="matrix(.73596 -.67702 -.73596 -.67702 0 18.3982)" :stroke="`#${color}`" stroke-width="2.5" stroke-linecap="round" d="M1.25-1.25h24.6752"/>
  </svg>
</template>
<script setup>
  import { ref } from 'vue'
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 20
    },
    size: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: 'FFFFFF'
    },
  })

  const constant = props.constant
  const viewBox = `0 0 ${props.width} ${props.height}`
  const fill = `#${props.color}`

  const fWidth = ref(0)
  fWidth.value = props.width * props.size

  const fHeight = ref(0)
  fHeight.value = props.height * props.size
</script>