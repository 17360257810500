<template>
  <div class="slider-container">
    <InputMultiRange 
      :constant="constant"
      :values="values"
      @sliderValues="sliderValues($event)"
    />
    <div class="percentage-range flex">
      <span>0.00%</span>
      <span>100.00%</span>
    </div>
  </div>
</template>
<script setup>
  import { computed } from 'vue'
  import InputMultiRange from './InputMultiRange.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const emit = defineEmits([
    'updateChartSettings',
    'updateNetworkCollateralAllocation'
  ])

  const constant = props.constant

  const values = computed(() => {
    let val = []
    val.push(parseInt(props.networkCollateralAllocation[1].set * 100))
    val.push(parseInt(props.networkCollateralAllocation[2].set * 100))
    val.push(parseInt(props.networkCollateralAllocation[0].set * 100))
    return val
  })

  const sliderValues = (event) => {
    const collateralSettings = {
      id: 3,
      data: event,
      rotation: calRotation(event)
    }
    emit('updateChartSettings', collateralSettings)

    const networkCollateral = {
      id: props.activeNetwork.id,
      data: event,
    }
    emit('updateNetworkCollateralAllocation', networkCollateral)
  }

  const calRotation = (data) => {
    return (((90 / 25) * data[2]) / 2) + 180
  }

</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .slider-container {
    position: relative;
    margin: 0 auto;
    min-width: 300px;
    max-width: 362px;

    @include breakpoint(sm) {
      max-width: 510px;
    }
    @include breakpoint(md) {
      max-width: 660px;
    }
    @include breakpoint(lg) {
      max-width: 860px;
    }
    @include breakpoint(xl) {
      max-width: 860px;
    }
    @include breakpoint(xxl) {
      max-width: 860px;
    }
    @include breakpoint(xxxl) {
      max-width: 860px;
    }
    
    .percentage-range{
      padding: 0 5px;
    }
  }
</style>