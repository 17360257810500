import { defineStore } from 'pinia'
import Constant from '../global/Constant.js'

export const useConfigurationStore = defineStore('configurationStore', {
  state: () => ({
    configurations: [
      {
        id: 1,
        name: Constant.SONGBIRD_NETWORK,
        settings: [
          {
            id: 1,
            key:'mxnp',
            name: 'Max SGB Price',
            type: 'multiplier',
            catagory: 'Coin Prices',
            valueType: 'default',
            hide: true, //Configurations Settings - Not Used
            min: 1.00,
            max: 10.00, //SimulatePriceControllerInput - SGB Max Coin Price calculation
            set: 0,
            default: 10.00,
          },
          {
            id: 2,
            key: 'mxcp',
            name: 'Max Price',
            type: 'multiplier',
            catagory: 'Coin Prices',
            valueType: 'hundredth',  //default
            hide: false,
            min: 10.00,
            max: 100.00,
            set: 0,
            default: 20.00,
          },
          {
            id: 3,
            key: 'mncp',
            name: 'Min Price',
            type: 'multiplier',
            catagory: 'Coin Prices',
            valueType: 'hundredth',
            hide: false,
            min: 0.01,
            max: 0.99,
            set: 0,
            default: 0.10,
          },
          {
            id: 4,
            key: 'mxms',
            name: 'Max Mint',
            catagory: 'Max Mintable Coin Supply',
            type: 'percentage',
            valueType: 'thousandth',
            hide: false,
            min: 0.01,
            max: 0.10,
            set: 0,
            default: 0.05,
          },
          {
            id: 5,
            key: 'ncr',
            name: 'Set Rate',
            catagory: 'Collateral Ratio',
            type: 'percentage', //multiplier
            valueType: 'hundredth',
            hide: false,
            min: 1.00,
            max: 5.00,
            set: 0,
            default: 1.50,
          },
        ],
        topHolders: [
          {
            id: 1,
            key: 'th10',
            name: '0x6699E078e90d646E02C2a2976a738C34dC7bF0Bf',
            count: 1691801533.595680493025,
            default: false,
            omit: false
          },
          {
            id: 2,
            key: 'th1',
            name: '0xa7235ad1532c178603B457E9671e32eD61ed8372',
            count: 1296715286.660672181725,
            default: false,
            omit: false
          },
          {
            id: 3,
            key: 'th2',
            name: '0x8E7d6a701BAEa22Eec108CF86D5d44fD1b0DdA38',
            count: 722670249.2251225,
            default: false,
            omit: false
          },
          {
            id: 4,
            key: 'th3',
            name: '0xCdBe08521dC52a1F61178Fdf78b40E8EE4e52fFE',
            count: 477426966.56274,
            default: false,
            omit: false
          },
          {
            id: 5,
            key: 'th4',
            name: '0x3812B106a55ED4C741b56E4D46d664C9189FaFa2',
            count: 292655226.087111387,
            default: false,
            omit: false
          },
          {
            id: 6,
            key: 'th5',
            name: '0x8078637A052E39A2Fc8833878D70Ae41f7310290',
            count: 286038701.793937474615910952,
            default: false,
            omit: false
          },
          {
            id: 7,
            key: 'th6',
            name: '0x4AE6E3682831A8C3253FF902d11eAE0DDFCC9910',
            count: 279422258.843032355045,
            default: false,
            omit: false
          },
          {
            id: 8,
            key: 'th7',
            name: '0xF977814e90dA44bFA03b6295A0616a897441aceC',
            count: 210870238.578992001823428,
            default: false,
            omit: false
          },
        ],
        topHoldersTotalCoinExclusion: 0,
      },
      {
        id: 2,
        name: Constant.FLARE_NETWORK,
        settings: [
          {
            id: 1,
            key:'mxnp',
            name: 'Max FLR Price',
            catagory: 'Coin Prices',
            type: 'multiplier',
            valueType: 'default',
            hide: true,
            min: 1.00,  //Configurations Settings - Not Used
            max: 10.00, //SimulatePriceControllerInput - FLR Max Coin Price calculation
            set: 0,
            default: 10.00,
          },
          {
            id: 2,
            key: 'mxcp',
            name: 'Max Price',
            catagory: 'Coin Prices',
            type: 'multiplier',
            valueType: 'hundredth', //default
            hide: false,
            min: 10.00,
            max: 100.00,
            set: 0,
            default: 20.00,
          },
          {
            id: 3,
            key: 'mncp',
            name: 'Min Price',
            type: 'multiplier',
            catagory: 'Coin Prices',
            valueType: 'hundredth',
            hide: false,
            min: 0.01,
            max: 0.99,
            set: 0,
            default: 0.10,
          },
          {
            id: 4,
            key: 'mxms',
            name: 'Max Mint',
            catagory: 'Max Mintable Coin Supply',
            type: 'percentage',
            valueType: 'thousandth',
            hide: false,
            min: 0.01,
            max: 0.10,
            set: 0,
            default: 0.05,
          },
          {
            id: 5,
            key: 'ncr',
            name: 'Set Rate',
            catagory: 'Collateral Ratio',
            type: 'percentage', //multiplier
            valueType: 'hundredth', //tenth
            hide: false,
            min: 1.00,
            max: 5.00,
            set: 0,
            default: 1.50,
          },
        ],
        topHolders: [
          {
            id: 1,
            key: 'th10',
            name: 'Network Staked',
            count: 1016549016.00,
            default: false, //true
            omit: false //true
          },
          {
            id: 2,
            key: 'th1',
            name: 'Flare Foundation',
            count: 1468136794.00,
            default: false,
            omit: false
          },
          {
            id: 3,
            key: 'th2',
            name: 'Flare Network Limited',
            count: 1875000000.00,
            default: false,
            omit: false
          },
          {
            id: 4,
            key: 'th3',
            name: 'VC Fund',
            count: 1500000000.00,
            default: false,
            omit: false
          },
          {
            id: 5,
            key: 'th4',
            name: 'Flare Founding Team',
            count: 1050000000.00,
            default: false,
            omit: false
          },
          {
            id: 6,
            key: 'th5',
            name: 'Rest of Flare Team',
            count: 225000000.00,
            default: false,
            omit: false
          },
          {
            id: 7,
            key: 'th6',
            name: 'Future Flare Team',
            count: 450000000.00,
            default: false,
            omit: false
          },
          {
            id: 8,
            key: 'th7',
            name: 'Advisors',
            count: 300000000.00,
            default: false,
            omit: false
          },
          {
            id: 9,
            key: 'th8',
            name: 'Backer Group 1',
            count: 478125000.00,
            default: false,
            omit: false
          },
          {
            id: 10,
            key: 'th9',
            name: 'Backer Group 2',
            count: 375000000.00,
            default: false,
            omit: false
          },
        ],
        topHoldersTotalCoinExclusion: 0,
      }
    ],
    localStorageCookie: {
      agreed: false
    },
    appVersion: 'v0.8.778', //semantic versioning
    creator: '@gpkjunky'
  }),
  getters: {
    getSystemConfigs() {
      return this.configurations
    },
    getAppVersion() {
      return this.appVersion
    },
    getCreator() {
      return this.creator
    },
    getLocalStorageCookie() {
      return this.localStorageCookie
    },
    getLocalStorageData(){
      return this.getLocalStorage(Constant.LOCAL_STORAGE_KEY)
    }
  },
  actions: {
    setLocalStorageCookiesDefault() {
      this.localStorageCookie.agreed = false
    },
    getSystemConfigsByName(networkName) {
      const id = this.getSystemConfigsId(networkName)
      const configs = this.configurations.find((configs) => configs.id === id)
      return configs
    },
    getSystemConfigsId(networkName) {
      return this.configurations.find((network) => network.name.toLowerCase() === networkName.toLowerCase()).id
    },

    getQueryParams(networkName) {
      const config = this.getSystemConfigsByName(networkName)

      let url = ''
      //url += this.getExcludesQueryParams(config)  
      url += this.getSettingsQueryParams(config)
      return url
    },
    getSettingsQueryParams(config) {
      const len = config.settings.length
      let queryParams = ''

      for(let i = 0; i < len; ++i) {
        if(config.settings[i].set != config.settings[i].default) {
          let qKey = config.settings[i].key
          queryParams += `&${qKey}=${config.settings[i].set}`
        }
      }
      return queryParams
      //= 	%3D
      //& 	%26
    },
    getExcludesQueryParams(config) {
      const len = config.topHolders.length 
      let queryParams = ''

      for(let i = 0; i < len; ++i) {
        let qKey = config.topHolders[i].key
        queryParams += `&${qKey}=${config.topHolders[i].omit ? 1 : 0}`
      }
      return queryParams
    },

    setQueryConfigsData(queryParams, activeNetworkName) {
      this.setQueryConfigSettingsData(queryParams, activeNetworkName)
      this.setQueryConfigExcludesData(queryParams, activeNetworkName)
    },
    setQueryConfigSettingsData(queryParams, activeNetworkName) {
      const configs = this.getSystemConfigsByName(activeNetworkName)
      const len = configs.settings.length

      for(let i = 0; i < len; ++i) {
        if(queryParams[configs.settings[i].key]) {
          let setting = configs.settings[i]
          this.updateSettingItem({
            networkName: activeNetworkName,
            id: setting.id,
            set: parseFloat(queryParams[setting.key])
          })
        }
      }
    },
    setQueryConfigExcludesData(queryParams, activeNetworkName) {
      const configs = this.getSystemConfigsByName(activeNetworkName)
      const len = configs.topHolders.length
  
      for(let i = 0; i < len; ++i) {
        if(queryParams[configs.topHolders[i].key]) {
          let topHolder = configs.topHolders[i]
          this.updateExcludeItem({
            networkName: activeNetworkName,
            id: topHolder.id,
            checked: parseInt(queryParams[topHolder.key]) === 1 ? true : false
          })
        }
      }
    },
    resetAllConfigurationsToDefault() {
      this.getSystemConfigs.forEach((configs) => {
        this.setSettingsDefault(configs)
        this.setExcludesDefault(configs)
      })
    },
    resetConfiguration(settings) {
      const configs = this.getSystemConfigsByName(settings.networkName)
      settings.type === Constant.SETTINGS_EXCLUDES ?
        this.resetExcludes(configs, settings) :
        this.resetSettings(configs, settings)
    },
    resetExcludes(configs, settings){
      configs.topHolders.forEach((tHolder) => {
        tHolder.omit = tHolder.default
      })

      this.updateExclusionTotalCount(configs)
    },
    resetSettings(configs, settings){
      this.setSettingsDefault(configs)
    },

    setExcludesDefault(configs) {      
      configs.topHolders.forEach((tHolder) => {
        if(!tHolder.default) {
          tHolder.omit = false
        } else {
          tHolder.omit = true
        }
      })

      this.updateExclusionTotalCount(configs)
    },
    updateExclusionTotalCount(configs) {
      configs.topHoldersTotalCoinExclusion = 0
      configs.topHolders.forEach(th => {
        if(th.omit) {
          configs.topHoldersTotalCoinExclusion += th.count
        }
      })
    },
    setSettingsDefault(configs) {
      configs.settings.forEach(setting => {
        setting.set =  setting.default
      })
    },

    updateConfigurationItem(settings) {
      if(settings.type === Constant.COOKIE_ACCEPT) {
        this.localStorageCookie.agreed = settings.agreed
        this.setLocalStorage(Constant.LOCAL_STORAGE_COOKIE_ACCEPTANCE, this.localStorageCookie.agreed)
        return 
      }

      settings.type === Constant.SETTINGS_EXCLUDES ? 
        this.updateExcludeItem(settings) :
        this.updateSettingItem(settings)
    },
    updateExcludeItem(settings) {
      const configs = this.getSystemConfigsByName(settings.networkName)
      const holder = configs.topHolders.find((holder) => holder.id === settings.id)
      holder.omit = settings.checked
      this.updateExclusionTotalCount(configs)
    },
    updateSettingItem(settings) {
      const configs = this.getSystemConfigsByName(settings.networkName)
      const setting = configs.settings.find((setting) => setting.id === settings.id)
      
      if(settings.set === null) {
        setting.set = setting.default
      } else {
        setting.set = settings.set
      }
    },

    setConfigsDefaults(networkName) {
      const configs = this.getSystemConfigsByName(networkName)
      const cookiesAccepted = this.getLocalStorage(Constant.LOCAL_STORAGE_COOKIE_ACCEPTANCE)

      //Set Cookies Accepted Data
      if(cookiesAccepted) {
        this.localStorageCookie.agreed = cookiesAccepted
      }

      //Set App Data from Configuration Defaults
      this.setExcludesDefault(configs)
      this.setSettingsDefault(configs)
    },

    getSerializedPresistedAppData(data) {
      const serializedData = {}
      const keyValues = data.split('&')
      const len = keyValues.length
      for (let i = 0; i < len; i++) {
        let kv = keyValues[i].split('=')
        serializedData[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1] || '')
      }
      return serializedData
    },
    setPresistedConfigsData(data) {
      if(this.localStorageCookie.agreed) {
        this.setLocalStorage(Constant.LOCAL_STORAGE_KEY, data)
      }
    },
    setLocalStorage(storageName, data) {
      if(this.localStorageCookie.agreed) {
        localStorage.setItem(storageName, JSON.stringify(data))
      }
    },
    getLocalStorage(storageName) {
      return JSON.parse(localStorage.getItem(storageName))
    },
    deleteLocalStorage(storageName) {
      localStorage.removeItem(storageName)
    },
    deleteAllLocalStorage() {
      localStorage.removeItem(Constant.LOCAL_STORAGE_COOKIE_ACCEPTANCE)
      localStorage.removeItem(Constant.LOCAL_STORAGE_KEY)
      localStorage.removeItem(Constant.LOCAL_STORAGE_NETWORK_KEY)
    }
  }
})