<template>
  <Transition name="cookie">
    <div
      v-if="showCookieDisclosure"
      class="container cookie-disclosure"
    >
      <div class="flex">
        <div class="disclaimer">
          <p>By clicking "Accept Cookies", you agree to store cookies on your device to enhance SimFasset's performance, configurations, and&nbsp;usage. <span class="color-red">At this time, there is NO need to connect your wallet.</span></p>
        </div>
        <div class="flex">
          <Button
            class="cookie-btn"
            :eventName="constant.COOKIE_DISCLOSURE"
            @clicked="clicked($event)"
          >
            Dismiss
          </Button>
          <Button
            class="cookie-btn"
            :eventName="constant.COOKIE_ACCEPT"
            @clicked="clicked($event)"
          >
            Accept Cookies
          </Button>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import { event } from "vue-gtag"
  import Button from '../../_generics/Button.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    cookies: {
      type: Object,
      default: () => {}
    },
    cookieDisclosureScreen: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  })

  const emit = defineEmits([
    'activateScreenSubNav',
    'updateConfiguration'
  ])
  const constant = props.constant
  const showDisclosure = ref(true)

  const showCookieDisclosure = computed(() => {
    return (!props.cookies.agreed && props.cookieDisclosureScreen.isActive && showDisclosure.value) || 
        (!showDisclosure.value && props.cookieDisclosureScreen.isActive)
  })

  const clicked = (event) => {
    if(event === constant.COOKIE_ACCEPT) {
      const settings = {
        type: event,
        agreed: true
      }
      trackEvent('cookies_acceptance', 'use_cookies', true)
      emit('updateConfiguration', settings)
      emit('activateScreenSubNav', constant.COOKIE_DISCLOSURE)
      return
    }
    trackEvent('cookies_dismissed', 'no_cookies', true)
    showDisclosure.value = false;
    emit('activateScreenSubNav', event)
  }
  
  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }

</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/_variables.scss';
  @import '../../../assets/scss/mixins/layout.scss';
  
  .container {
    position: fixed;
    bottom: 0px;
    height: 225px;
    width: 100%;
    z-index: -1;
    align-items: flex-start;

      @include breakpoint(sm) {
        height: 205px;
      }
      @include breakpoint(md) {
        height: 160px;
      }
      @include breakpoint(lg) {
        height: 160px;
      }
      @include breakpoint(xl) {
        height: 140px;
      }
      @include breakpoint(xxl) {
        height: 130px;
      }
      @include breakpoint(xxxl) {
        height: 130px;
      }

    &.cookie-disclosure {
      box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.25);
      -moz-box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.25);
      -webkit-box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.25);
      background: $WHITE;
      background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, $WHITE 55%);
    }

    .flex {
      flex-direction: column;

        @include breakpoint(md) {
          flex-direction: row;
        }
        @include breakpoint(lg) {
          flex-direction: row;
        }
        @include breakpoint(xl) {
          flex-direction: row;
        }
        @include breakpoint(xxl) {
          flex-direction: row;
        }
        @include breakpoint(xxxl) {
          flex-direction: row;
        }

      .disclaimer {
        width: 100%;
      }

      p {
        max-width: $MAX-CONTENT-WIDTH;
        line-height: $FONT-LARGE;
        text-align: center;
        font-size: $FONT-XSMALL;
        color: $PURPLE;
        padding: 5px 0 0 0;
        margin: 15px 10px;

        @include breakpoint(sm) { //576
          margin: 15px 5% 15px 5%;
        }

        @include breakpoint(md) { //768
          text-align: left;
          padding: 5px 0 0 0;
          margin: 15px 10px 15px 5%;
        }
        @include breakpoint(lg) { //1024
          text-align: left;
          padding: 5px 20px 0 20px;
          margin: 15px 10px 15px 5%;
        }
        @include breakpoint(xl) { //1200
          text-align: left;
          padding: 5px 20px 0 20px;
          margin: 15px 10px 15px 2.5%;
        }
        @include breakpoint(xxl) { //1400
          text-align: left;
          padding: 5px 20px 0 20px;
          margin: 15px 10px 15px 2.5%;
        }
        @include breakpoint(xxxl) { //1900
          text-align: left;
          padding: 5px 20px 0 20px;
          margin: 15px 10px 15px 2.5%;
        }
      }

      .flex { //Button Container
        flex-direction: row;
        margin: 0 auto;
        justify-content: space-around;
        max-width: $MAX-CONTENT-WIDTH;
        width: 100%;
        margin: 0;

        @include breakpoint(md) {
          margin: 0 3% 0 0;
          max-width: 300px;
        }
        @include breakpoint(lg) {
          margin: 0 5% 0 0;
          max-width: 300px;
        }
        @include breakpoint(xl) {
          margin: 0 5% 0 0;
          max-width: 300px;
        }
        @include breakpoint(xxl) {
          margin: 0 5% 0 0;
          max-width: 300px;
        }
        @include breakpoint(xxxl) {
          margin: 0 5% 0 0;
          max-width: 300px;
        }

        .cookie-btn {
          border: thin solid $PURPLE;
          padding: 10px;
          margin: 0;
          min-width: 140px;
          border-top-left-radius: 15px;
          border-bottom-right-radius: 15px;
          color: $PURPLE;
          font-size: $FONT-XSMALL;

            @include breakpoint(lg) {
              margin-top: 20px;
            }
            @include breakpoint(xl) {
              margin-top: 10px;
            }
            @include breakpoint(xxl) {
              margin-top: 10px;
            }
            @include breakpoint(xxxl) {
              margin-top: 10px;
            }

          &:last-child {
            margin-left: 40px;
            color: $WHITE;
            background-color: $PURPLE;

            @include breakpoint(sm) {
              margin-left: 10px;  
            }
            @include breakpoint(md) {
              margin-left: 10px;  
            }
          }
        }
      }
    }
  }

  .cookie-enter-from {
    bottom: -200px;

    @include breakpoint(sm) {
      bottom: -195px;
    }
    @include breakpoint(md) {
      bottom: -170px;
    }
    @include breakpoint(lg) {
      bottom: -140px;
    }
    @include breakpoint(xl) {
      bottom: -140px;
    }
    @include breakpoint(xxl) {
      bottom: -125px;
    }
    @include breakpoint(xxxl) {
      bottom: -125px;
    }
  }
  .cookie-enter-to {
    bottom: 0;
  }
  .cookie-enter-active {
    transition: all 0.15s ease-out;
  }
  
  .cookie-leave-from {
    bottom: 0;
  }
  .cookie-leave-to {
    bottom: -200px;

    @include breakpoint(sm) {
      bottom: -195px;
    }
    @include breakpoint(md) {
      bottom: -170px;
    }
    @include breakpoint(lg) {
      bottom: -140px;
    }
    @include breakpoint(xl) {
      bottom: -140px;
    }
    @include breakpoint(xxl) {
      bottom: -125px;
    }
    @include breakpoint(xxxl) {
      bottom: -125px;
    }
  }
  .cookie-leave-active{
    transition: all .25s ease-in;
  }
</style>