<template>
    <h2>Details</h2>
    <table>
      <tbody>
        <tr>
          <td class="txt-left">{{ coin.ticker }} Circulating Supply</td>
          <td class="txt-right">{{ circulatingSupply }}</td>
        </tr>

        <tr>
          <td class="txt-left"
            :class="{'color-purple-xxlight': isNativeCollateral}"
          >
            {{ activeNetwork.ticker }} Coins TVL - {{ collateralRateNative() }}%
          </td>
          <td class="txt-right"
            :class="{'color-purple-xxlight': isNativeCollateral}"
          >
            ${{ NATIVEcollateral }}
          </td>
        </tr>

        <tr>
          <td class="txt-left"><span class="txt-uppercase">Stable</span> Coins TVL - {{ collateralRateUSD() }}%</td>
          <td class="txt-right">${{ USDcollateral }}</td>
        </tr>

        <tr
          v-if="collateralFAssetIsActive"
        >
          <td class="txt-left">
            <span class="txt-uppercase">ETH</span> Coins TVL - {{ collateralRateFAsset() }}%
          </td>
          <td class="txt-right">${{ FASSETcollateral }}</td>
        </tr>

        <tr>
          <td class="txt-left color-white">Total Coins TVL</td>
          <td class="txt-right color-white">${{ collateral }}</td>
        </tr>
        <tr>
          <td 
            class="txt-left"
            :class="{'color-purple-xxlight' : showPriceSimulator}"
          >
            {{ coin.ticker }} Price
            <Button
              class="price-simulate-btn"
              :eventName="`priceSimulate`"
              @click="clicked($event)"
            >
              <PriceSliderIcon 
                class="price-simulator-btn"
                :color="priceIconColor"
              />
            </Button>
          </td>
          <td 
            class="txt-right"
            :class="{'color-purple-xxlight' : showPriceSimulator}"
          >
            ${{ currencyFormatter(mPrice) }}
          </td>
        </tr>
      </tbody>
    </table>
    <Transition>
      <InputRangePrice
        v-if="showPriceSimulator"
        :constant="constant"
        :coin="coin"
        :configs="configs"
        @updateModifiedCoinPrice="updateModifiedCoinPrice($event)"
      />
    </Transition>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import InputRangePrice from '../_generics/InputRangePrice.vue'
  import Button from '../_generics/Button.vue'
  import PriceSliderIcon from '../_icons/PriceSimulatorIcon.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    collateral: {
      type: String,
      default: ''
    },
    isNativeCollateral: {
      type: Boolean,
      default: false
    },
    circulatingSupply: {
      type: String,
      default: ''
    },
    USDcollateral: {
      type: String,
      default: ''
    },
    NATIVEcollateral: {
      type: String,
      default: ''
    },
    FASSETcollateral: {
      type: String,
      default: ''
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const emit = defineEmits([
    'updateModifiedCoinPrice'
  ])

  const constant = props.constant
  const showPriceSim = ref(false)

  const mPrice = computed(() => {
    return props.coin.modifiedPrice > 0 ? props.coin.modifiedPrice : props.coin.price
  })

  const showPriceSimulator = computed (() => {
    const show =  showPriceSim.value || props.coin.modifiedPrice > 0
    if(show) {
      showPriceSim.value = show
    }
    return show
  })

  const priceIconColor = computed(() => {
    const color = showPriceSimulator.value ? constant.PURPLE_XXLIGHT : constant.WHITE
    return color
  })

  const collateralRateUSD = () => {
    return parseFloat(props.networkCollateralAllocation[0].set * 100).toFixed(0)
  }

  const collateralRateNative = () => {
    if(props.collateralFAssetIsActive) {
    return parseFloat(props.networkCollateralAllocation[2].set * 100).toFixed(0)
    }
    return parseFloat(props.networkCollateralAllocation[1].set * 100).toFixed(0)
  }

  const collateralRateFAsset = () => {
    if(props.collateralFAssetIsActive) {
      return parseFloat(props.networkCollateralAllocation[1].set *100).toFixed(0)
    }
    return 0
  }

  const calCollateralRate = () => {
    return getConfigsSettingById(constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set
  }

  const clicked = (event) => {
    showPriceSim.value = !showPriceSim.value
    
    const mCoin = {
      id: props.coin.id,
      modifiedPrice: 0
    }
    emit('updateModifiedCoinPrice', mCoin)
  }

  const updateModifiedCoinPrice = (event) => {
    emit('updateModifiedCoinPrice', event)
  }

  const currencyFormatter = (value) => {
    return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const getConfigsSettingById = (settingId) => {
    const setting = props.configs.settings.find((setting) => setting.id === settingId)
    return setting
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  h2 {
    margin-top: 10px;
    font-size: $FONT-SMALL;
  }

  table {
    font-size: $FONT-XSMALL;
    padding: 0;
    margin: 0 auto 10px auto;
    width: 70%;
    min-width: 340px;

    tr {
      line-height: $FONT-XLARGE;
      letter-spacing: 1px;
      color: $GREY-LIGHT;

      &:first-child, &:last-child {
        color: $WHITE;
      }

      .color-white {
        color: $WHITE;
      }
    }
  }

  .price-simulate-btn {
    position: absolute;
    height: 20px;
  }

  .v-enter-from {
    height: 0;
  }
  .v-enter-to {
    height: $PRICE-SIMULATOR-HEIGHT;
  }
  .v-enter-active {
    transition: height 0.15s ease-in;
  }

  .v-leave-from {
    height: $PRICE-SIMULATOR-HEIGHT;
  }
  .v-leave-to {
    height: 0;
  }
  .v-leave-active{
    transition: height 0.15s ease-out;
  }
</style>