<template>
  <Mint
    :constant="constant"
    :coins="coins"
    :isMinted="coinStore.isMinted"
    :activeNetwork="networkStore.getActiveNetwork"
    :accordionScreens="screenManagerStore.getScreensAccordion"
    :configs="configurationStore.getSystemConfigsByName(networkStore.getActiveNetwork.name)"
    :screensMintCollateral="screenManagerStore.getScreensMintCollateral"
    :chartsData="chartStore.getChartsData"
    @activateAccordion="activateAccordion($event)"
    @updateMintQuantity="updateMintQuantity($event)"
    @updateCollateralType="updateCollateralType($event)"
    @updateChartSettings="updateChartSettings($event)"
    @updateNetworkCollateralAllocation="updateNetworkCollateralAllocation($event)"
    @updateModifiedCoinPrice="updateModifiedCoinPrice($event)"
    @updateScreenMintCollateral="updateScreenMintCollateral($event)"
    @throttleAllMaxMintQuantities="throttleAllMaxMintQuantities(event)"
  />
</template>
<script setup>
  import { ref, inject, onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  import { useScreenManagementStore } from '../stores/ScreenManagementStore.js'
  import { useNetworkStore } from '../stores/NetworkStore.js'
  import { useCoinStore } from '../stores/CoinStore.js'
  import { useChartStore } from '../stores/ChartStore.js'
  import { useConfigurationStore } from '../stores/ConfigurationStore.js'

  import Mint from '../components/Mint/Mint.vue'

  const constant = inject('constant')
  const screenManagerStore = useScreenManagementStore()
  const coinStore = useCoinStore()
  const coins = coinStore.getCoins
  const networkStore = useNetworkStore()
  const chartStore = useChartStore()
  const configurationStore = useConfigurationStore()

  const router = useRouter()

  onMounted(() =>{
    scrollSmoothToTop()
  })

  const activateAccordion = (event) => {
    screenManagerStore.setActiveAccordionScreen(event)
  }

  const updateScreenMintCollateral = (event) => {
    screenManagerStore.setActiveMintCollateralScreen(event)
  }

  const updateMintQuantity = (event) => {
    coinStore.updateMintQuantity(event)
    networkStore.resetModifiedCoinPrice(networkStore.getActiveNetwork.name)
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    configurationStore.setPresistedConfigsData(getPersistedAppData())
  }

  const throttleAllMaxMintQuantities = (event) => {
    coinStore.throttleAllMaxMintQuantities()
  }
  
  const updateModifiedCoinPrice = (event) => {
    coinStore.updateModifiedCoinPrice(event)
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    configurationStore.setPresistedConfigsData(getPersistedAppData())
  }

  const updateChartSettings = (event) => {
    chartStore.setChartSettings(event)
  }

  const updateCollateralType = (event) => {
    networkStore.resetModifiedCoinPrice(networkStore.getActiveNetwork.name)
    networkStore.updateNetworkCollateralType(event)
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    configurationStore.setPresistedConfigsData(getPersistedAppData())
  }

  const updateNetworkCollateralAllocation = (event) => {
    networkStore.updateNetworkCollateralAllocation(event)
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    configurationStore.setPresistedConfigsData(getPersistedAppData())
  }

  const getPersistedAppData = () => {
    let data = persistedDataObject()

    let properties = ''
    properties += networkStore.getQueryParams()
    properties += coinStore.getQueryParams()
    properties += configurationStore.getQueryParams(networkStore.getActiveNetwork.name)

    networkStore.getActiveNetwork.name === constant.FLARE_NETWORK ?
      data[1].val = properties :
      data[0].val = properties
      
    return data
  }

  const persistedDataObject = () => {
    const data = configurationStore.getLocalStorage(constant.LOCAL_STORAGE_KEY)
    return [
      {
        id: 1,
        val: data && data[0].val ? data[0].val : null
      },
      {
        id: 2,
        val: data && data[1].val ? data[1].val : null
      },
    ]
  }

  const scrollSmoothToTop = () => {
    (function smoothscroll(){
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
        let newPosition = 0
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll)
          newPosition = currentScroll - (currentScroll / 5)
          window.scrollTo(0, newPosition)
        }
    })()
  }
</script>