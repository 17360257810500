<template>
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.8451 20.387C5.8451 21.6903 4.78858 22.7468 3.4853 22.7468C2.18201 22.7468 1.12549 21.6903 1.12549 20.387C1.12549 19.0837 2.18201 18.0272 3.4853 18.0272C4.78858 18.0272 5.8451 19.0837 5.8451 20.387Z" stroke="white"/>
    <path d="M17.532 9.20007H5.84043C4.45258 9.20007 0.625488 10.5256 0.625488 14.8154H12.317C13.8311 14.8154 17.4479 13.4476 17.532 9.20007Z" stroke="white"/>
    <path d="M23.1675 0.747009H5.84043C4.45259 0.747009 0.625488 2.07258 0.625488 6.36238H17.9105C19.4245 6.36238 23.0834 4.99458 23.1675 0.747009Z" stroke="white"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>