<template>
  <div
    :class="classes"
  >
    <h2>Frequently Asked Questions</h2>
    <div
      class="accordion-item" 
      v-for="faq in faqs" 
      :key="faq.id"
    >
      <AppHeaderFaqThumbnail
        :id="faq.id"
        :constant="constant"
        :faq="faq"
        :isActive="faq.isActive"
        @activateFaqAccordion="activateFaqAccordion($event)" 
      />
      <AppHeaderFaqAnswer
        :id="faq.id"
        :constant="constant"
        :answer="faq.answer"
        :isActive="faq.isActive"
      />
    </div>
  </div>
</template>
<script setup>
  import AppHeaderFaqThumbnail from './AppHeaderFaqThumbnail.vue'
  import AppHeaderFaqAnswer from './AppHeaderFaqAnswer.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    faqs: {
      type: Array,
      default: () => []
    },
    classes: {
      type: String,
      default: ''
    }
  })

  const constant = props.constant
  const emit = defineEmits(['activateFaqAccordion'])

  const activateFaqAccordion = (event) => {
    emit('activateFaqAccordion', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';
  @import '../../../../assets/scss/mixins/layout.scss';

  h2 {
    text-transform: unset;
    font-weight: 600;
    font-size: $FONT-LARGE;
  }

  h2, p {
    color: $BLACK;
    background-color: $WHITE;
    padding: 25px 0;
    margin-bottom: 0;
    border-bottom: thin solid $BLACK-XLIGHT;
  }

  .accordion-item {
    background-color: $GREY-XXXLIGHT;
    margin: 0;
    width: initial;
  }
</style>