<template>
  <Transition name="modal">
    <div
      v-if="show" 
      class="modal-bg"
      @click="clicked($event)"
    >
      <div class="modal-container">
        <div class="modal">
          <header>
            <Button 
              class="control-btn default" 
              :eventName="constant.GET_STARTED" 
              @clicked="clicked($event)"
            >
              <Close 
                :color="constant.BLACK"
                :width="22"
                :height="20"
                :size="0.8"
              />
            </Button>
          </header>
          <slot name="modal-content"></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup>
  import Button from './Button.vue'
  import Close from '../_icons/Close.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    },
  })

  const emit = defineEmits([
    'hideModal'
  ])

  const clicked = (event) => {
    close()
  }

  const close = () => {
    emit('hideModal', null) 
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .modal-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    overflow-y: hidden;
    height: 100%;
    background-color: $BLACK-LIGHT;

    .modal-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%; 

      .modal {
        position: relative;
        background-color: $WHITE;
        padding: 10px;
        border-radius: 5px;
        width: 85%;
        max-width: 500px;
        margin: auto;

        header {
          position: relative;

          .control-btn {
            margin: 0;
          }
        }
      }
    }
  }

  .modal-enter-from {
    opacity: 0;
  }
  .modal-enter-to {
    opacity: 1;
  }
  .modal-enter-active {
    transition: all 0.25s ease-out;
  }
  
  .modal-leave-from {
    opacity: 1;
  }
  .modal-leave-to {
    opacity: 0;
  }
  .modal-leave-active{
    transition: all .15s ease-in;
  }
</style>