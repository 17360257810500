<template>
  <GetStarted 
    :constant="constant"
    :activeNetwork="networkStore.getActiveNetwork"
    :screensCarousel="screenManager.getScreensCarousel"
    @nextScreen="nextPrimaryScreen($event)"
    @nextCarouselScreen="nextCarouselScreen($event)"
  />
</template>
<script setup>
  import { ref, inject } from 'vue'
  import { useRouter } from 'vue-router'
  import { useScreenManagementStore } from '../stores/ScreenManagementStore.js'
  import { useNetworkStore } from '../stores/NetworkStore.js'
  import GetStarted from '../components/GetStarted/GetStarted.vue'

  const constant = inject('constant')
  const router = useRouter()

  const screenManager = useScreenManagementStore()
  const networkStore = useNetworkStore()

  const nextPrimaryScreen = (event) => {
    screenManager.deactivateScreensSubNav()
    router.push({path : `/${event}`})
  }
  const nextCarouselScreen = (event) => {
    screenManager.setActiveCarouselScreen(event)
  }
</script>