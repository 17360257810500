<template>
  <svg 
    :width="width" 
    :height="height" 
    :viewBox="viewBox" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M298.419 93.128c-2.338-7.2367-7.04-21.7835-7.137-22.0775l14.854 12.3708-7.717 9.7067ZM296.039 110.549l1.775-14.7021-9.433 16.9461 7.658-2.244ZM297.564 104.525l1.191-10.1832 12.669 1.5737-13.86 8.6095ZM306.56 84.1912l5.099 10.9291-12.553-1.4963 7.454-9.4328ZM309.109 87.9973l6.604-2.9534-3.391 9.7233-3.213-6.7699ZM314.712 90.3604l1.594-4.5679 1.84 7.6535-3.434-3.0856ZM126.983.46045h11.9636v12.0418H126.983z" fill="#5401BB"/>
    <path fill="#5401BB" d="M133.17 12.5022h5.77663v18.7316H133.17zM133.17 37.9319h5.77663v24.0673H133.17zM145.541 37.9319h5.77663V55.731H145.541zM138.947 6.25854V.46065h6.16281v5.79789zM157.967 6.68018V.46874h5.30999v6.21144zM157.967 18.73v-6.22766h5.30999V18.73zM157.516 31.2341v-5.82222h18.1344v5.82222z"/>
    <path fill="#5401BB" d="M145.577 12.5022V6.67999h23.8789v5.82221zM157.516 31.2341h-6.23522V19.1518h6.23522z"/>
    <path fill="#5401BB" d="M151.281 31.2341h-5.70421V12.5025h5.70421zM188.475 6.25854V.46065h5.76054v5.79789zM182.264 18.7463v-5.84654h5.77663v5.84654zM163.695 25.4116v-6.2155h5.77663v6.2155zM194.638 24.9739v-5.82222h5.84099v5.82222zM194.638 49.4954V31.672h5.84099v17.8234zM163.695 37.4614v-6.22766h5.84099v6.22766zM157.967 43.7136v-5.78167h5.72835v5.78167zM157.967 68.2432v-5.806h5.74445v5.806zM145.11 74.4709v-5.806h6.21108v5.806z"/>
    <path fill="#5401BB" d="M139.372 86.9546V62.417h5.74445v24.5376zM175.651 68.2432v-5.806h12.3819v5.806zM188.467 74.4626v-5.79789h5.76858v5.79789zM176.073 86.9424v-6.22766h5.76858v6.22766zM157.906 86.9424v-5.78978h5.35424v5.78978zM182.298 98.9761V87.3965h5.74243v11.5796zM188.041 98.9761v-5.78978h6.18192v5.78978zM176.085 105.252v-5.83856h5.72835v5.83856zM169.89 117.724v-12.0338h5.76054v12.0338zM182.298 111.464v-5.77369h5.72835v5.77369zM188.484 142.229v-12.0176h6.18594v12.0176z"/>
    <path fill="#5401BB" d="M182.273 142.229v-5.78991h6.21108v5.78991zM182.282 148.473v-6.24388h5.75852v6.24388zM188.026 123.935v-5.77369h6.64352v5.77369z"/>
    <path fill="#5401BB" d="M182.298 124.422v-6.25997h5.72835v6.25997z"/>
    <path fill="#5401BB" d="M169.888 130.195v-5.77369h18.1384v5.77369zM194.67 111.48v-5.82234h11.9515V111.48zM194.67 154.717v-43.2368h5.72836v43.2368zM157.484 130.195v-6.2601h6.22717v6.2601zM169.456 117.724v-5.77369h.43244v5.77369z"/>
    <path fill="#5401BB" d="M157.484 123.935v-11.9851h11.9716v11.9851zM145.564 93.1982v-5.78978h5.75652v5.78978zM163.683 92.7483v-5.3519h11.9595v5.3519z"/>
    <path fill="#5401BB" d="M163.699 105.228V92.7402h5.75651v12.4878zM139.365 117.708v-12.0176h18.1183v12.0176zM175.643 80.7146v-5.79789h12.398v5.79789zM206.609 74.4709v-5.79789h5.76054v5.79789zM212.78 80.7146v-5.79789h5.76054v5.79789zM218.991 87.4128v-6.2601h5.76054v6.2601zM200.398 130.188v-12.0257h24.3455v12.0257zM212.796 98.9922v-6.25199h18.1425v6.25199zM218.991 105.658v-6.66542h5.75249v6.66542zM206.609 87.4128v-6.2601h5.76054v6.2601z"/>
    <path fill="#5401BB" d="M200.415 92.7402v-5.32757h30.5244v5.32757zM194.662 92.7402V81.1525h5.75249v11.5877zM225.154 80.7146v-5.79789h18.1586v5.79789zM200.849 80.7146v-5.79789h5.76054v5.79789zM126.525 37.4614v-5.78167h6.24326v5.78167zM71.6553 55.7068v-5.78167h5.79272v5.78167zM71.7036 92.6997v-5.3519h5.76054v5.3519zM120.798 111.464v-5.79789h5.76054v5.79789zM139.384 123.951v-6.24388h5.76054v6.24388zM120.798 136.439v-6.23577h5.76054v6.23577z"/>
    <path fill="#5401BB" d="M120.798 130.204v-5.79789h6.2452v5.79789zM127.044 130.204v-18.2938h5.76054v18.2938zM108.843 117.708v-5.79789h5.76054v5.79789zM102.632 123.951v-5.79789h5.76054v5.79789zM90.2725 130.204v-5.79789h5.76054v5.79789zM108.843 130.204v-5.79789h5.76054v5.79789zM139.384 136.439v-5.79789h5.76054v5.79789zM133.172 142.699v-6.2601h5.76054v6.2601zM120.769 167.205v-24.5052h18.1647v24.5052z"/>
    <path fill="#5401BB" d="M138.933 148.473v-5.77356h6.21108v5.77356zM138.933 160.961v-5.77356h6.21108v5.77356zM145.546 142.237v-5.79789h5.76054v5.79789zM145.546 154.725v-5.79789h5.76054v5.79789zM145.546 167.213v-5.79789h5.76054v5.79789zM151.726 179.692v-12.0174h5.76054v12.0174zM120.798 179.692v-5.79789h18.1324v5.79789zM163.697 142.237v-5.79789h5.76054v5.79789zM157.548 148.473v-5.77356h6.14873v5.77356zM163.697 154.733v-5.806h5.76054v5.806zM169.859 160.977v-5.806h5.8068v5.806zM157.89 167.205v-5.806h11.5673v5.806z"/>
    <path fill="#5401BB" d="M157.486 179.676v-5.806h30.5546v5.806zM182.282 160.977v-5.806h5.75852v5.806zM176.104 167.221v-5.806h5.75852v5.806zM188.041 173.457v-5.806h18.597v5.806z"/>
    <path fill="#5401BB" d="M182.282 173.87v-6.21968h5.75852V173.87zM102.654 61.9832v-6.24793h5.75249v6.24793zM114.618 61.9832v-5.78965h6.1789v5.78965zM120.796 68.6809v-6.2397h5.75249v6.2397zM120.796 74.9209v-6.2397h5.75249v6.2397zM126.549 74.4626v-5.78167h6.18694v5.78167zM133.187 80.7104v-5.80182h6.18694v5.80182zM127 93.1982v-.45005h11.9274v.45005zM127 98.9922v-5.79383h24.3214v5.79383zM120.798 81.1606v-.45828h5.75249v.45828zM120.798 87.4045v-6.24388h11.9455v6.24388z"/>
    <path fill="#5401BB" d="M108.853 92.7483v-5.34379h30.0739v5.34379zM96.4673 80.7024v-5.78167h30.0819v5.78167zM163.711 80.7146V55.731h5.76054v24.9836zM169.472 80.7146V56.1933h6.17085v24.5213zM120.805 49.4954v-36.547h5.72031v36.547zM108.857 68.231v-5.79383h5.74042V68.231zM96.4673 68.231v-5.79383h5.74445V68.231zM90.2725 74.4646v-5.79383h5.76054v5.79383zM84.0776 80.7087v-5.79383h5.76054v5.79383zM114.609 99.4626v-6.7142h5.76054v6.7142zM84.0688 98.9761v-5.77762h11.9638v5.77762zM96.4673 111.448v-6.20739h11.9394v6.20739zM84.0688 117.708v-6.2601h5.76884v6.2601z"/>
    <path fill="#5401BB" d="M96.4673 117.708v-6.2601h5.74445v6.2601z"/>
    <path fill="#5401BB" d="M89.8379 117.708v-5.78978h6.62944v5.78978zM96.4673 105.24v-5.77762h23.903V105.24zM77.874 105.69v-6.22766h11.9638V105.69zM71.6787 111.448v-5.75734h18.1588v5.75734z"/>
    <path fill="#5401BB" d="M71.6787 130.195v-18.7479h5.76884v18.7479zM84.0688 99.4626v-.48654h5.76884v.48654zM90.2725 86.9546v-5.79383h18.1344v5.79383zM96.4673 81.1606v-.45828h11.9394v.45828zM71.6553 74.4709v-5.806h11.9716v5.806zM77.8823 68.6648v-.44599h5.74445v.44599zM77.8823 68.2188v-5.82222h6.19499v5.82222zM84.0776 68.2188V55.7391h5.76054v12.4797z"/>
    <path fill="#5401BB" d="M84.0776 55.7393v-5.81398h30.5405v5.81398zM169.922 43.7136v-5.81411h5.72835v5.81411zM200.833 68.2268v-6.24388h5.77663v6.24388zM212.78 68.2268v-6.24388h11.9475v6.24388zM224.728 68.2268v-5.82222h18.585v5.82222zM243.763 61.9832v-5.78167h5.74445v5.78167zM243.763 74.4626v-5.78978h5.74445v5.78978zM243.763 86.9504v-5.78978h5.74445v5.78978zM237.581 105.22V86.9465h5.74445V105.22z"/>
    <path fill="#5401BB" d="M243.325 105.22v-5.76951h6.19901V105.22zM243.663 111.464v-6.24388h5.8611v6.24388zM243.759 136.439v-5.78978h5.76456v5.78978zM231.373 130.195v-5.78978h6.20706v5.78978zM212.804 118.162v-12.504h11.9394v12.504zM224.744 123.935v-18.2777h6.19499v18.2777zM237.581 130.195v-12.0499h5.76456v12.0499zM243.759 148.473v-5.78978h5.76456v5.78978zM219.011 142.229v-5.78978h24.3173v5.78978zM219.011 148.911v-6.68176h5.76456v6.68176zM224.776 148.473v-6.24388h6.16281v6.24388zM237.649 160.977v-5.78978h11.8751v5.78978zM225.174 160.961v-5.806h5.78065v5.806zM237.649 173.457v-12.4797h5.78065v12.4797zM206.638 167.221v-5.806h5.78065v5.806zM212.804 160.961v-6.24388h5.78065v6.24388zM206.605 142.245v-5.79789h5.78065v5.79789zM219.011 136.439v-6.25199h5.73238v6.25199zM200.857 160.961v-6.24388h5.78065v6.24388zM200.398 154.717v-5.806h24.3938v5.806zM206.638 179.692v-5.806h5.78065v5.806zM224.792 173.457v-5.806h6.16281v5.806zM212.804 173.457v-5.806h6.20706v5.806z"/>
    <path fill="#5401BB" d="M219.011 179.692v-18.2775h5.78065v18.2775zM231.389 86.9504v-6.23577h5.75249v6.23577zM176.069 55.7393v-5.77344h6.21108v5.77344zM206.609 56.2095v-6.27619h18.1183v6.27619zM188.491 61.9832v-5.77356h36.2367v5.77356zM224.728 55.7393v-5.80587h12.4061v5.80587zM176.101 37.4614v-5.78978h5.72835v5.78978z"/>
    <path fill="#5401BB" d="M182.28 55.7393V37.9321h5.75249v17.8072zM182.264 31.2502v-5.83843h11.9716v5.83843zM151.317 55.731v-5.76533h18.1545V55.731zM194.638 12.5186V6.68017h5.84099v5.83843zM138.947 55.731v-5.78978h6.59324V55.731z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M114.521.58203H71.6553V43.6567h42.8657V.58203Zm-6.114 6.07984H77.8983V37.6237h30.5087V6.66187Z" fill="#5401BB"/>
    <path fill="#5401BB" d="M84.1416 13.0374h18.0218v18.0991H84.1416z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M249.427.58203h-42.866V43.6567h42.866V.58203Zm-6.114 6.07984h-30.509V37.6237h30.509V6.66187Z" fill="#5401BB"/>
    <path fill="#5401BB" d="M219.047 13.0374h18.0218v18.0991H219.047z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M114.521 136.553H71.6553v43.074h42.8657v-43.074Zm-6.114 6.08H77.8983v30.961h30.5087v-30.961Z" fill="#5401BB"/>
    <path fill="#5401BB" d="M84.1416 149.008h18.0218v18.0991H84.1416z"/>
    <ellipse cx="5.37965" cy="106.144" rx="4.40553" ry="4.44029" fill="#5401BB"/>
    <path d="M27.0186 88.7742H9.00773c-2.13798 0-8.0336 2.0581-8.0336 8.7187H18.9849c2.3324 0 7.9041-2.1237 8.0337-8.7187ZM35.7001 75.6494H9.00773c-2.13798 0-8.0336 2.0582-8.0336 8.7187H27.6016c2.3324 0 7.9689-2.1237 8.0985-8.7187Z" fill="#5401BB"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 319
    },
    height: {
      type: Number,
      default: 180
    },
    size: {
      type: Number,
      default: 1.0
    },
    color: {
      type: String,
      default: '5401BB'
    }
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
  const fill = `#${props.color}`
</script>