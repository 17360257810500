<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.25 24.4923C.25 11.3244 10.8841.65128 24 .65128S47.75 11.3244 47.75 24.4923 37.1159 48.3333 24 48.3333.25 37.6602.25 24.4923Z" fill="#fff" stroke="#E61E57" stroke-width=".5"/>
    <mask id="a" fill="#fff">
      <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093 0 30.8817 0 24.4923c0-6.3893 2.52856-12.517 7.02943-17.03491C11.5303 2.93945 17.6348.40129 24 .40129V48.5833Z"/>
    </mask>
    <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093 0 30.8817 0 24.4923c0-6.3893 2.52856-12.517 7.02943-17.03491C11.5303 2.93945 17.6348.40129 24 .40129V48.5833Z" fill="#E61E57" stroke="#E61E57" stroke-width="1.5" mask="url(#a)"/>
    <circle cx="7.53366" cy="31.2241" r="2.03366" fill="#fff"/>
    <path d="M17.5225 23.2689H9.20844c-.98692 0-3.70844.9426-3.70844 3.9932h8.3141c1.0766 0 3.6486-.9727 3.7084-3.9932ZM21.5303 17.2578H9.20869c-.98693 0-3.70845.9426-3.70845 3.9931H17.7919c1.0767 0 3.6786-.9726 3.7384-3.9931Z" fill="#fff"/>
    <path d="m27.7484 17.8693-2.6814.0184 5.6688 5.5339c.17.1783 1.5307 1.6444 3.9141 1.6281 2.3835-.0163 3.4571-1.3196 3.773-1.6109l5.6322-5.6811-2.6615.0182-4.2612 4.296c-1.1745 1.0747-1.7605 1.0787-2.4954 1.0838-.7348.005-1.4002.0096-2.5995-1.0988l-4.2891-4.1876ZM27.8562 33.7393l-2.6814.0184 5.593-5.6111c.1676-.1806 1.5082-1.6652 3.8917-1.6815 2.3834-.0163 3.4747 1.2722 3.7945 1.5591l5.7089 5.6034-2.6615.0183-4.3192-4.2373c-1.189-1.0585-1.775-1.0545-2.5099-1.0495-.7349.0051-1.4002.0096-2.5844 1.1343l-4.2317 4.2459Z" fill="#E61E57"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>