<template>
  <div class="multi-range-slider">
    <InputRange 
      :constant="constant"
      :disabled="disableLeft"
      :class="'left-input-range input-left'"
      v-model:modelValue="modelValueLeft"
      @sliderValue="sliderLeftValueUpdate($event)"
      @blur="blur($event)"
    />

    <InputRange 
      :constant="constant"
      :disabled="disableRight"
      :class="'right-input-range input-right'"
      :modelValue="modelValueRight"
      @sliderValue="sliderRightValueUpdate($event)"
      @blur="blur($event)"
    />

    <div class="slider">
      <div class="track"></div>
      <div class="range-track-left"
        :style="leftRangeTrackStyle"
      ></div>
      <div class="range-track"
        :style="rangeTrackStyle"
      ></div>
      <div 
        class="thumb left"
        :style="leftThumbStyle"
      ></div>
      <div 
        class="thumb right"
        :style="rightThumbStyle"
      ></div>
    </div>

  </div>
</template>
<script setup>
  import { ref, computed, onMounted, onUnmounted  } from 'vue'
  import InputRange from './InputRange.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    minLeft: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    values: {
      type: Array,
      default: () => []
    }
  })

  const emit = defineEmits([
    'sliderValues'
  ])

  const constant = props.constant

  let small = 575 //576 Increases
  let medium = 767 //768 Increases
  let large = 1023 // 1024 Increases
  const screenShim = ref(17.5)

  const modelValueLeft = ref(25)
  const modelValueRight = ref(75)

  const disableLeft = ref(false)
  const disableRight = ref(false)

  //Thumb Controllers
  const leftThumbStyle = computed(() => {
    return {'left': `${leftPercentage.value}%`}
  })
  const rightThumbStyle = computed(() => {
    return {'right': `${rightPercentage.value}%`}
  })

  //Range Bars Colors
  const leftRangeTrackStyle = computed(() => {
    return {'right': `${100 - leftPercentage.value}%`}
  })
  const rangeTrackStyle = computed(() => {
    return {'left': `${leftPercentage.value}%`,'right': `${rightPercentage.value}%`}
  })

  //Precentages
  const leftPercentage = computed(() => {
    return modelValueLeft.value - ((screenShim.value / 100) * modelValueLeft.value) / 2
  })
  const rightPercentage = computed(() => {
    return 100 - modelValueRight.value - (((screenShim.value / 100) * (100 - modelValueRight.value)) / 2)
  })

  const blur = (event) => {
    disableLeft.value = false
    disableRight.value = false
  }

  const calSliderValues = () => {
    let values = []
    
    values.push(100 - modelValueRight.value)
    values.push(modelValueLeft.value)
    values.push(modelValueRight.value - modelValueLeft.value)
    
    emit('sliderValues', values)
  }

  const sliderLeftValueUpdate = (tick) => {
    if(tick >= modelValueRight.value) {
      disableLeft.value = true
    }
    modelValueLeft.value = Math.min(parseInt(tick), parseInt(modelValueRight.value))
    calSliderValues()
  }

  const sliderRightValueUpdate = (tick) => {
    if(tick <= modelValueLeft.value) {
      disableRight.value = true
    }
    modelValueRight.value = Math.max(parseInt(tick), parseInt(modelValueLeft.value))
    calSliderValues()
  }

  const initializeSlider = () => {
    modelValueLeft.value = props.values[0]
    modelValueRight.value = 100 - props.values[2]
  }

  const windowResize = (event = null) => {
    const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if(width <= small) {
      screenShim.value = 17.5
    } else if (width > small && width <= medium) {
      screenShim.value = 11
    } else if ( width > medium && width <= large) {
      screenShim.value = 9
    } else {
      screenShim.value = 6.5
    }
  }

  initializeSlider()

  onMounted(() => {
    window.addEventListener("resize", windowResize)
    windowResize()
  })

  onUnmounted(() => {
    window.removeEventListener("resize", windowResize);
  })
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .multi-range-slider {
    position: relative;
    min-width: 340px;
    max-width: 850px;

    .left-input-range, .right-input-range {
      position: absolute;
      margin: 0 auto;
      pointer-events: none;
      z-index: 2;
      height: 10px;
      width: 100%;
      left: 0;
      opacity: 0;

      // &.input-left {
      //   top: -100px;
      //   opacity: 0.8;
      // }

      // &.input-right {
      //   top: -50px;
      //   opacity: 0.8;
      // }

      &::-moz-range-thumb {
        pointer-events: all;
        border-radius: 0;
        border: none;
        width: 30px;
        height: 35px;
        background-image: none;
        background-color: red;
      }

      &::-webkit-slider-thumb {
        pointer-events: all;
        border-radius: 0;
        border: none;
        width: 30px;
        height: 35px;
        background-image: none;
        background-color: red;
      }
    }

    .left-input-range {
      &::-moz-range-thumb {
        width: 40px;
        background-color: greenyellow;
      }

      &::-webkit-slider-thumb {
        width: 40px;
        background-color: greenyellow;
      }
    }

    .slider {
      position: relative;
      z-index: 1;
      height: 4px;
      margin: 25px 5px 6px 5px;
      max-width: 850px;

      .track {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: $WHITE;
      }
      .range-track-left {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        bottom: 0;
        border-radius: 25px;
        background-color: $BLUE;
      }
      .range-track {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: $PURPLE-XLIGHT;
      }

      .thumb {
        position: absolute;
        z-index: 3;
        width: 30px;
        height: 30px;
        background-color: $WHITE;
        border: thin solid $PURPLE_XXLIGHT;
        background-image: radial-gradient(circle at center, $WHITE 30%, $GREY-XXXLIGHT 100%);
        border-radius: 50%;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);

        &.left {
          left: 25%;
          transform: translate(-5px, -15px);
        }
        &.right {
          right: 25%;
          transform: translate(5px, -15px);
        }
      }
    }
  }
</style>