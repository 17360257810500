<template>
    <div 
      id="get-started" 
      class="page"
    >
      <Carousel 
        :constant="constant"
        :screensCarousel="screensCarousel"
        :activeNetwork="activeNetwork"
      />

      <h1>Simulate how FAsset minting on the <span class="">{{ activeNetwork.name }}</span> can impact <span class="color-purple-xxlight">{{ activeNetwork.ticker }}'s</span>&nbsp;market&nbsp;price.</h1>

      <AnimatedButton 
        :label="'Let\'s go'"
        :event="constant.MINTING"
        @nextScreen="nextScreen($event)"
      />
    </div>
</template>
<script setup>
  import { ref } from 'vue'
  import { event } from "vue-gtag"
  import Carousel from './Carousel.vue'
  import AnimatedButton from '../_generics/AnimatedButton.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    screensCarousel: {
      type: Array,
      default: () => {}
    }
  })
  const constant = props.constant
  const emit = defineEmits([
    'nextScreen',
    'nextCarouselScreen'
  ])

  const nextScreen = (event) => {
    trackEvent('lets_go', 'lets_go_btn', 'lets_go_click')
    emit('nextScreen', constant.MINTING)
  }

  const nextCarouselScreen = (event) => {
    emit('nextCarouselScreen', event)
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .page {
    h1 {
      margin-top: 20px;
      padding: 0;
      text-transform: initial;
      margin: 30px auto 40px auto;
      max-width: 80%;
      font-weight: normal;


      @include breakpoint(sm) {
        max-width: 70%;
      }
      @include breakpoint(md) {
        max-width: 60%;
      }
      @include breakpoint(lg) {
        max-width: 50%;
      }
      @include breakpoint(xl) {
        max-width: 50%;
      }
      @include breakpoint(xxl) {
        max-width: 50%;
      }
      @include breakpoint(xxxl) {
        max-width: 50%;
      }
    }

    p {
      font-size: $FONT-SMALL;
      margin: 10px;
    }

    .sub-header {
      margin-bottom: 10px;
    }
  }
</style>