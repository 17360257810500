<template>
    <FlareNetworkIcon
      v-if="props.activeNetwork.name === constant.FLARE_NETWORK"
      :constant="constant"
      :class="classes"
      :showCircleLogo="showCircleLogo"
      :showSolidDefaultLogo="showSolidDefaultLogo"
      :isActiveNetwork="activeNetwork.name === constant.FLARE_NETWORK"
      :isActiveNetworkSubNav="isActiveNetworkSubNav"
      :width="flrWidth"
      :height="flrHeight"
      :size="flrSize"
      :color="flrColor"
      :stroke="stroke"
    />
    <SongbirdNetworkIcon
      v-else
      :constant="constant"
      :class="classes"
      :showCircleLogo="showCircleLogo"
      :showSolidDefaultLogo="showSolidDefaultLogo"
      :isActiveNetwork="activeNetwork.name === constant.SONGBIRD_NETWORK"
      :isActiveNetworkSubNav="isActiveNetworkSubNav"
      :width="sgbWidth"
      :height="sgbHeight"
      :size="sgbSize"
      :color="sgbColor"
      :stroke="stroke"
    />
</template>
<script setup>
  import FlareNetworkIcon from './FlareNetworkIcons.vue'
  import SongbirdNetworkIcon from './SongbirdNetworkIcons.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    isActiveNetwork: {
      type: Boolean,
      default: false
    },
    isActiveNetworkSubNav: {
      type: Boolean,
      default: false
    },
    showCircleLogo: {
      type: Boolean,
      default: false
    },
    showSolidDefaultLogo: {
      type: Boolean,
      default: true
    },
    sgbWidth: {
      type: Number,
      default: 20
    },
    sgbHeight: {
      type: Number,
      default: 28
    },
    sgbSize: {
      type: Number,
      default: 1.0
    },
    flrWidth: {
      type: Number,
      default: 14
    },
    flrHeight: {
      type: Number,
      default: 14
    },
    flrSize: {
      type: Number,
      default: 1.0
    },
    flrColor: {
      type: String,
      default: '000000'
    },
    sgbColor: {
      type: String,
      default: '000000'
    },
    classes: {
      type: String,
      default: ''
    },
    stroke: {
      type: Boolean,
      default: false
    }
  })

   const constant = props.constant

</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/_variables.scss';
  
    .logo {
      position: absolute;
    }
</style>