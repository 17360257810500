<template>
  <button 
    type="button"
    :class="$attrs.class" 
    v-on:click="clicked($event)"
  >
    <slot></slot>
    <slot name="animate"></slot>
    <slot name="accordion"></slot>
  </button>
</template>
<script setup>
  const props = defineProps({
    eventName: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    // id: {
    //   type: Number,
    //   default: 0
    // }
  })
  const emit = defineEmits(['clicked'])
  const clicked = (event) => {
    // if(props.id > 0) {
    //   const modEvent = {
    //     name: props.eventName,
    //     id: props.id,
    //     isActive: props.isActive
    //   }
    //   return emit('clicked', modEvent)
    // }
    emit('clicked', props.eventName)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  
  button {
    background-color: $CLEAR;
    border: none;
    cursor: pointer;

    &.animated {
      height: 70px;
      width: 250px;
      font-size: 1.2rem;
      font-weight: 600;
      background-color: $WHITE-LIGHT;
      color: $ORANGE-LIGHT;
      border: solid $WHITE thin;
    }

    &.flex {
      margin: 0;

      &.thumbnail-btn {
        width: 100%;
        background-color: $WHITE-LIGHT;
        height: 80px;
      }
    }
  }
</style>