<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24.75" r="23.0879" transform="rotate(-.39 24 24.75)" fill="#000" stroke="#fff" stroke-width="1.5"/>
    <path d="m13.2607 12.4585-4.19381.0286 8.86621 8.6226c.2659.2778 2.394 2.5622 6.1218 2.5368 3.7279-.0255 5.4071-2.0562 5.9011-2.5101l8.8091-8.8519-4.1627.0284-6.6647 6.6937c-1.8371 1.6746-2.7535 1.6809-3.9029 1.6887-1.1494.0078-2.1901.0149-4.0658-1.712l-6.7083-6.5248ZM13.4294 37.186l-4.1938.0286 8.7477-8.7428c.2622-.2814 2.3589-2.5946 6.0867-2.62 3.7278-.0254 5.4346 1.9822 5.9347 2.4293l8.9291 8.7309-4.1627.0284-6.7554-6.6022c-1.8597-1.6493-2.7762-1.6431-3.9256-1.6352-1.1494.0078-2.1901.0149-4.0421 1.7673l-6.6186 6.6157Z" fill="#fff"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>