<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.25 24.4923C.25 11.3244 10.8841.65127 24 .65127S47.75 11.3244 47.75 24.4923 37.1159 48.3333 24 48.3333.25 37.6602.25 24.4923Z" fill="#fff" stroke="#E61E57" stroke-width=".5"/>
    <mask id="a" fill="#fff">
      <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093 0 30.8816 0 24.4923s2.52856-12.517 7.02943-17.03491C11.5303 2.93944 17.6348.40129 24 .40127V48.5833Z"/>
    </mask>
    <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093 0 30.8816 0 24.4923s2.52856-12.517 7.02943-17.03491C11.5303 2.93944 17.6348.40129 24 .40127V48.5833Z" fill="#E61E57" stroke="#E61E57" stroke-width="1.5" mask="url(#a)"/>
    <circle cx="6.53366" cy="30.4586" r="2.03366" fill="#fff"/>
    <path d="M16.5225 22.5034H8.20844c-.98692 0-3.70844.9427-3.70844 3.9932h8.3141c1.0766 0 3.6486-.9727 3.7084-3.9932ZM20.5303 16.4923H8.20869c-.98693 0-3.70845.9426-3.70845 3.9932H16.7919c1.0767 0 3.6786-.9727 3.7384-3.9932Z" fill="#fff"/>
    <path d="m36.137 16.4958-9.1832 15.9733 2.9375.0086 7.2406-12.5183.7485 3.1525-5.4227 9.3732 2.8137.0082 3.5266-5.9335 1.5444 5.9482 2.5354.0074-2.3239-9.0077 1.663-3.0369-2.4427-.0071-1.0554-3.9604-2.5818-.0075Z" fill="#E61E57"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })

  const viewBox = `0 0 48 49`
  const width = props.width * props.size
  const height = props.height * props.size
</script>