<template>
  <div
    v-if="startTimer"
    class="container"
  >
    <div class="flex">
      <CheckMark />
      <h2>Copied!</h2>
    </div>
    <div>
      <p>
      {{ message }}
      </p>
    </div>
    <CountDown 
      :start="startTimer"
      :classes="'animation-duration-3000'"
    />
  </div>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import CheckMark from '../_icons/CheckMark.vue'
  import CountDown from '../_generics/CountDown.vue'

  const props = defineProps({
    start: {
      type: Boolean,
      default: false
    },
    pathName: {
      type: String,
      default: ''
    }
  })

  const autoClose = ref('')

  const startTimer = computed(() => {
      if(props.start)
        autoClose.value = setTimeout(close, 3000)

    return props.start
  })

  const emit = defineEmits([
    'hideModal'
  ])

  const message = computed(() => {
    let msg = ''

    switch(props.pathName) {
      case 'home' : msg = "Share SimFasset with a friend."
      break
      case 'fmint' : msg = "Your FAsset simulation is ready to share!"
      break
      case 'simulate' : msg = "Your FAsset simulation is ready to share!"
      break
    }
    return msg
  })

  const close = () => {
    clearTimeout(autoClose.value)
    emit('hideModal', null)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .container {
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    
    .flex {
      width: 100%;
      margin-top: 10px;
      justify-content: center;

      h2 {
        color: $BLACK;
        font-weight: 600;
        margin:  0 0 0 10px;
      }
    }
    p {
      margin: 20px 10px;
      font-size: $FONT-XSMALL;
      color: $BLACK;
    }
  }
</style>