<template>
  <div class="nav-control-panel">
    <Transition name="subnav">  
      <div
        v-if="networkScreen.isActive"
        :class="classes"
        class="subnav-cpanel left"
      >
        <AppNavControlSubPanelNetwork
          :constant="constant"
          :networkScreen="networkScreen"
          :isActiveSgbNetwork="isActiveSgbNetwork"
          :isActiveFlrNetwork="isActiveFlrNetwork"
          @activateNetwork="activateNetwork($event)"
        />
      </div>
    </Transition>
 
    <Transition name="subnav">
      <div
        v-if="shareScreen.isActive"
        :class="classes"
        class="subnav-cpanel right"
      >
        <AppNavControlSubPanelShare
          :constant="constant"
          :networkScreen="networkScreen"
          :isActiveSms="isActiveSms"
          @shareApplication="shareApplication($event)"
        />
      </div>
    </Transition>
  </div>
</template>
<script setup>
  import AppNavControlSubPanelNetwork from './AppNavControlSubPanelNetwork.vue'
  import AppNavControlSubPanelShare from './AppNavControlSubPanelShare.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    networkScreen: {
      type: Object,
      default: () => {}
    },
    shareScreen: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: String,
      default: ''
    },
    isActiveFlrNetwork: {
      type: Boolean,
      default: false
    },
    isActiveSgbNetwork: {
      type: Boolean,
      default: false
    },
    isActiveSms: {
      type: Boolean,
      default: false
    },
  })
  const constant = props.constant
  const emit = defineEmits([
    'activateNetwork',
    'shareApplication',
  ])

  const activateNetwork = (event) => {
    emit('activateNetwork', event)
  }

  const shareApplication = (event) => {
    emit('shareApplication', event)
  }

</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/_variables.scss';
  @import '../../../assets/scss/mixins/layout.scss';
  @import '../../../assets/scss/partials/_transitions.scss';

  .nav-control-panel {
    position: relative;
    height: $NAV-HEIGHT;
    width: 100%;
    margin: 0 auto;
    max-width: $MAX-CONTENT-WIDTH;

    .subnav-cpanel {
      width: 150px;
    }

    .right {
      right: 5%;
      border-radius: 50px 7px 0 0;

      @include breakpoint(lg) {
        right: 0;
        margin-right: 10px;
      }
      @include breakpoint(xl) {
        right: 0;
        margin-right: 10px;
      }
      @include breakpoint(xxl) {
        right: 0;
        margin-right: 10px;
      }
      @include breakpoint(xxxl) {
        right: 0;
        margin-right: 10px;
      }
    }
    .left {
      left: 5%;
      border-radius: 7px 50px 0 0;

      @include breakpoint(lg) {
        left: 0;
        margin-left: 10px;
      }
      @include breakpoint(xl) {
        left: 0;
        margin-left: 10px;
      }
      @include breakpoint(xxl) {
        left: 0;
        margin-left: 10px;
      }
      @include breakpoint(xxxl) {
        left: 0;
        margin-left: 10px;
      }
    }
  }
</style>