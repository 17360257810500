<template>
  <div class="nav-controls">

    <div class="nav-simulate-btn">
      <Button class="simulate-btn">
        <SimulateDeactivated class="btn-icon" />
      </Button>

      <Transition name="simulate">
        <Button 
          v-if="isActiveSimulateBtn" 
          class="simulate-btn-active" 
          :eventName="constant.SIMULATE" 
          @clicked="clicked($event)"
        >
          <SimualateActiveShimmer
            v-if="isActiveSimulateBtn"
            class="btn-icon-active-shimmer"
          />
          <SimulateActive class="btn-icon-active"/>
        </Button>
      </Transition>
    </div>

    <div class="nav-control-set">
      <Button 
        class="network-toggle" 
        :eventName="constant.NETWORK" 
        @clicked="clicked($event)"
      >
        <ActiveNetworkLogo 
          :constant="constant"
          :activeNetwork="activeNetwork"
          :showSolidDefaultLogo="isActiveNetworkSubNav"
          :isActiveNetworkSubNav="isActiveNetworkSubNav"
          :flrSize="1.7688"
          :flrColor="constant.WHITE"
          :sgbWidth="18"
          :sgbHeight="26"
          :sgbSize="1.0"
          :sgbColor="constant.WHITE"
        />
      </Button>
      <Button 
        class="share" 
        :eventName="constant.SHARE" 
        @clicked="clicked($event)"
      >
        <ShareActive 
          v-if="isActiveShareSubNav"
        />
        <ShareStroke
          v-else
        />
      </Button>
    </div>
  </div>
</template>
<script setup>
  
  import Button from '../../../_generics/Button.vue'
  import SimulateDeactivated from '../../../_icons/SimulateDeactivate.vue'
  import SimulateActive from '../../../_icons/SimulateActive.vue'
  import SimualateActiveShimmer from '../../../_icons/SimulateActiveShimmer.vue'
  import ActiveNetworkLogo from '../../../_icons/Network/ActiveNetworkLogo.vue'
  import ShareStroke from '../../../_icons/Share/ShareStroke.vue'
  import ShareActive from '../../../_icons/Share/ShareActive.vue'
  
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    isActiveNetworkSubNav: {
      type: Boolean,
      default: false
    },
    isActiveShareSubNav: {
      type: Boolean,
      default: false
    },
    isActiveFlrNetwork: {
      type: Boolean,
      default: false
    },
    isActiveSimulateBtn: {
      type: Boolean,
      default: false
    }, 
  })
  const constant = props.constant
  const emit = defineEmits(['clicked'])

  const clicked = (event) => {
    emit('clicked', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';
  @import '../../../../assets/scss/mixins/layout.scss';

  button {
    position: absolute;
  }

  .nav-controls {
    position: relative;
    height: $NAV-HEIGHT;
    margin: -$NAV-HEIGHT auto 0 auto;
    max-width: $MAX-CONTENT-WIDTH;

    .nav-simulate-btn {
      position: relative;
      width: 100%;
      margin: 0 auto;
      height: $NAV-HEIGHT;
      background-image: url('../../../../assets/imgs/navigation-bg.svg');
      background-repeat: no-repeat;
      background-position: center;

      .simulate-btn, .simulate-btn-active {
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        padding: 0;
        width: 68px;
        height: 74px;
        bottom: 8px;
        left: 0;
        right: 0;
        z-index: 102;

        .btn-icon {
          margin: -5px 0 0 0;
        }
      }

      .simulate-btn {
        pointer-events: none;
      }
      
      .simulate-btn-active { //hit state
        bottom: 12px;
        right: -2px;
        height: 72px;
        width: 72px;
        border-radius: 50%;

        &:before {
          content: '';
          background-image: conic-gradient(
            from 0deg, 
            transparent,
            #0a0971 60%,
            #ffffff
          );
          width: 150%;
          height: 150%;
          position: absolute;
          left: -25%;
          top: -25%;
          animation: rotate 2.0s infinite linear;
          z-index: -1;
        }
      }

      .btn-icon-active {
        margin: -3px 0 0 0;
      }

      .btn-icon-active-shimmer {
        position: absolute;
        margin: 0 0 0 2px;
      }
    }

    .nav-control-set {
      position: absolute;
      margin: -$NAV_HEIGHT auto 0 auto;
      height: $NAV_HEIGHT;
      width: 100%;

      .network-toggle, .share {
        margin-top: 10px;
        padding: 5px;
        width: 35px;
      }

      .network-toggle {
        left: 5%;
        
        @include breakpoint(lg) {
          left: 0;
          margin-left: 10px;
        }
        @include breakpoint(xl) {
          left: 0;
          margin-left: 10px;
        }
        @include breakpoint(xxl) {
          left: 0;
          margin-left: 10px;
        }
        @include breakpoint(xxxl) {
          left: 0;
          margin-left: 10px;
        }
      }

      .share {
        right: 5%;

        @include breakpoint(lg) {
          right: 0;
          margin-right: 10px;
        }
        @include breakpoint(xl) {
          right: 0;
          margin-right: 10px;
        }
        @include breakpoint(xxl) {
          right: 0;
          margin-right: 10px;
        }
        @include breakpoint(xxxl) {
          right: 0;
          margin-right: 10px;
        }
      }
    }
  }

  .simulate-enter-active {
    transition: all 0.9s ease-in;
  }

  .simulate-leave-active {
    transition: all 0.5s ease-in;
  }

  .simulate-leave-active {
    transition-delay: 0.25s;
  }

  .simulate-enter-from, 
  .simulate-leave-to {
    opacity: 0;
  }

  .simulate-enter-active .btn-icon-active-shimmer,
  .simulate-leave-active .btn-icon-active-shimmer  {
    transition: all 0.7s ease-out;
  }

  .simulate-enter-active .btn-icon-active-shimmer {
    transition-delay: 0.5s;
  }

  .simulate-enter-from .btn-icon-active-shimmer,
  .simulate-leave-to .btn-icon-active-shimmer  {
    opacity: 0.001;
  }
</style>