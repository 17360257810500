<template>
  <input type="text"
    :id="`quantity${coin.id}`"
    v-model="displayValue" 
    @blur="inputHandler" 
    @focus="inputHandler"
    @keyup.enter="keyupHandler"
  />
</template>
<script setup>
  import { ref, computed } from 'vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    modelValue: {
      type: Number,
      default: 0
    },
    coin: {
      type: Object,
      default: () => {}
    },
    mxMintRate: {
      type: Number,
      default: 0.00
    },
    configs: {
      type: Object,
      default: () => {}
    }
  })
  const emit = defineEmits([
    'updateInputMintQuantity',
    'errorMsg'
  ])
  const constant = props.constant

  let inputFocused = ref(false)

  const maxMintSupply = ref(0)

  const displayValue = computed({
    get: () => {
        if(inputFocused.value) {
         
          if(props.modelValue === 0)
            return ''
          return props.modelValue.toString()
        } else {
          return mask(props.modelValue)
        }
    },
    set: (updatedValue) => {      
      const eventUpdate = {
        coinId: props.coin.id,
        inputMintAmount: unmask(throttleMaxMint(updatedValue)),
        directive: null
      }
      emit('updateInputMintQuantity', eventUpdate)
    }
  })

  const setMaxMintVals = () => {
    maxMintSupply.value = parseFloat(props.coin.circulatingSupply * props.mxMintRate).toFixed(2)
  }

  const masks = {
    currency: {
      mask(value) {
        value = parseFloat(value).toFixed(2)
        return value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
      },
      unmask(value) {
        value = parseFloat(value.replace(/[^\d\.]/g, ""))
        return isNaN(value)
          ? 0
          : value
      },
    },
  }

  const inputHandler = (event) => {
    inputFocused.value = event.type === 'focus'
  }
  const keyupHandler = (event) => {
    event.target.blur()
  }
  const unmask = (value) => {
    return masks.currency.unmask(value)
  }
  const mask = (value) => {
    return masks.currency.mask(value)
  }
  const throttleMaxMint = (value) => {
    setMaxMintVals()
    if(parseFloat(value) > maxMintSupply.value) {
      value = maxMintSupply.value
      const msg = `Reduce the number of F${props.coin.ticker} minted. ${props.coin.name}'s max mint cap is currently set to ${(props.mxMintRate * 100).toFixed(2)}% of total supply.`
      emit('errorMsg', msg)
    }
    return value
  }

  setMaxMintVals()
</script>