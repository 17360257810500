<template>
  <svg 
    width="31" 
    height="30" 
    viewBox="0 0 31 30" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.2474 21.3434C13.2474 22.1203 12.6177 22.75 11.8409 22.75C11.0641 22.75 10.4343 22.1203 10.4343 21.3434C10.4343 20.5666 11.0641 19.9369 11.8409 19.9369C12.6177 19.9369 13.2474 20.5666 13.2474 21.3434Z" stroke="#5300BB"/>
    <path d="M13.411 14.3854H20.6648C20.5253 15.3527 20.0529 16.0012 19.5145 16.4269C18.8647 16.9407 18.1183 17.129 17.7288 17.129H10.4653C10.5901 16.1555 11.0755 15.5071 11.6301 15.0814C12.2992 14.5678 13.0621 14.3854 13.411 14.3854Z" stroke="#5300BB"/>
    <path d="M13.411 8.75H24.4214C24.2806 9.71534 23.8037 10.3637 23.2593 10.7901C22.6022 11.3047 21.848 11.4936 21.4578 11.4936H10.4653C10.5901 10.5201 11.0755 9.87171 11.6301 9.44597C12.2992 8.93236 13.0621 8.75 13.411 8.75Z" stroke="#5300BB"/>
  </svg>
</template>