<template>
    <Flare 
      v-if="showDefaultLogo"
      :constant="constant"
      :width="props.width"
      :height="props.height"
      :size="props.size"
      :color="props.color"
      :stroke="stroke"
    />
    <FlareStroke 
      v-else-if="showStrokeLogo"
      :color="props.color"
    />
    <FlareActiveSmall 
      v-else-if="showActiveSmallLogo"
      :width="props.width"
      :height="props.height"
      :size="props.size"
      :color="props.color"
    />
    <FlareDeactiveSmall 
      v-else
    />
</template>
<script setup>
  import { computed } from 'vue'
  import Flare from './Flare.vue'
  import FlareStroke from './FlareStroke.vue'
  import FlareActiveSmall from './FlareActiveSmall.vue'
  import FlareDeactiveSmall from './FlareDeactiveSmall.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    isActiveNetwork: {
      type: Boolean,
      default: false
    },
    isActiveNetworkSubNav: {
      type: Boolean,
      default: false
    },
    showSolidDefaultLogo: {
      type: Boolean,
      default: false
    },
    showCircleLogo: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 14
    },
    height: {
      type: Number,
      default: 14
    },
    size: {
      type: Number,
      default: 1.0
    },
    color: {
      type: String,
      default: '000000'
    },
    stroke: {
      type: Boolean,
      default: false
    }
  })

  const constant = props.constant

  const showDefaultLogo = computed(() => {
    return props.showSolidDefaultLogo && props.isActiveNetwork
  })

  const showStrokeLogo = computed(() => {
    return !props.isActiveNetworkSubNav && props.isActiveNetwork && !props.showCircleLogo
  })

  const showActiveSmallLogo = computed(() => {
    return (props.isActiveNetworkSubNav && props.isActiveNetwork) || (props.showCircleLogo && props.isActiveNetwork)
  })
</script>