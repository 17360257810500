<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24.3544" r="23.25" transform="rotate(-180 24 24.3544)" fill="#F68819" stroke="#fff" stroke-width="1.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="m12.9312 29.3084 1.3946.3931c1.0855.3106 1.6134.0911 1.8361-.5075.2227-.5986 2.1037-8.546 3.0164-12.4449.242-1.0339.0381-1.7561-1.0681-1.9948l-2.2849-.5482.705-3.0175 5.7409 1.3845 1.1379-4.70633 2.7955.70741-1.1028 4.64862 2.378.54 1.031-4.64782 2.7452.7008-1.0597 4.64812c2.4085.7117 5.9281 2.4444 5.7885 5.7124-.1482 3.47-2.2805 4.4179-3.587 4.8622.0647.0418.1341.0857.2073.132 1.0625.6724 2.9291 1.8537 2.8383 4.4352-.1182 3.363-1.9626 5.9146-5.0538 6.3761-2.346.3502-4.1491.0444-5.2675-.1453-.0222-.0037-.0442-.0075-.0658-.0111l-1.0668 4.6554-2.7517-.6434 1.1093-4.6989-2.4067-.5469-1.0953 4.6629-2.745-.6793 1.1025-4.6701-5.6333-1.3713 1.3619-3.2254Zm7.8696 2.028 1.5497-6.3519c2.4838.3883 7.6126 1.6374 7.4014 4.4477-.2001 2.6623-2.9465 3.1568-5.5469 2.5834l-3.4042-.6792Zm2.1636-9.1821 1.37-5.7623c2.125.3493 6.4196 1.1907 6.1807 4.2318-.115 1.4631-1.8381 2.8395-4.5191 2.2138-2.681-.6257-3.0316-.6833-3.0316-.6833Z" fill="#fff"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>