<template>
  <AlgoIcon 
    v-if="coin.ticker === constant.ALGO" 
    :width="width"
    :height="height"
    :size="size"
  />
  <BtcIcon 
    v-else-if="coin.ticker === constant.BTC" 
    :width="width"
    :height="height"
    :size="size"
    />
  <DogeIcon 
    v-else-if="coin.ticker === constant.DOGE" 
    :width="width"
    :height="height"
    :size="size"
  />
  <LtcIcon 
    v-else-if="coin.ticker === constant.LTC" 
    :width="width"
    :height="height"
    :size="size"
  />
  <XrpIcon 
    v-else 
    :width="width"
    :height="height"
    :size="size"
  />
</template>
<script setup>
  import AlgoIcon from './AlgoIcon.vue'
  import BtcIcon from './BtcIcon.vue'
  import DogeIcon from './DogeIcon.vue'
  import LtcIcon from './LtcIcon.vue'
  import XrpIcon from './XrplIcon.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })

  const constant = props.constant
  const coin = props.coin
  const size = props.size
  const width = props.width
  const height = props.height
</script>
<style lang="scss" scoped>
  
</style>