<template>
  <DoughnutChart 
    ref="root" 
    class="chart" 
    :chartData="chartData.data" 
    :options="chartData.options" 
  />
</template>
<script setup>
  import { ref, onMounted, watch } from 'vue'
  import { DoughnutChart } from "vue-chart-3"
  import { Chart, registerables } from 'chart.js'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  Chart.register(...registerables)

  const emit = defineEmits([
    'updateChartSettings'
  ])

  const constant = props.constant
  const data = ref([35, 65])
  const rotation = ref(180)

  let animator = null

  const initializedChartValues = () => {
    let data = []
    data = props.collateralFAssetIsActive ? 
      [props.networkCollateralAllocation[0].set * 100, props.networkCollateralAllocation[1].set * 100, props.networkCollateralAllocation[2].set * 100] :
      [props.networkCollateralAllocation[0].set * 100, props.networkCollateralAllocation[1].set * 100]
    return data
  }

  const initializedChartBackgroundColors = () => {

    if(props.collateralFAssetIsActive) {
      return [
        `rgba(${constant.WHITE_RGB}, 1.0)`,
        `rgba(${constant.BLUE_RGB}, 1.0)`,
        `rgba(${constant.PURPLE_XLIGHT_RGB}, 1.0)`,
      ]
    }
    return [
      `rgba(${constant.WHITE_RGB}, 1.0)`,
      `rgba(${constant.PURPLE_XLIGHT_RGB}, 1.0)`,
    ]
  }

  const initializedChartLabels = () => {
    let labels = []
    labels = props.collateralFAssetIsActive ? ["Stable", "FAssets", "Network"] : ["Stable", "Network"]
    return labels
  }

  const resetChart = () => {
    let chartSettings = {
      id: props.chartData.id,
      src: 'CollateralChart - ResetChart',
      data: initializedChartValues(), //Update Data on load
      labels: initializedChartLabels(),
      backgroundColor: initializedChartBackgroundColors(),
      rotation: rotation.value,
      options: {
        animation: {
          duration: 500
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          title: {
            display: false,
          },
          datalabels: {
            color: 'white',
            formatter: function(value, context) {
              return context.chart.data.labels[context.dataIndex];
            }
          }
        }
      },
    }

    emit('updateChartSettings', chartSettings)
  }

  const swapCharts = () => {
    let chartSettings = {
      id: props.chartData.id,
      data: initializedChartValues(),
      rotation: rotateChart(),
      labels: initializedChartLabels(),
      backgroundColor: initializedChartBackgroundColors(),
    }

    emit('updateChartSettings', chartSettings)
  }

  const rotateChart = () => {
    const len = initializedChartValues().length
    return (((90 / 25) * initializedChartValues()[len - 1]) / 2) + 180
  }
  
  watch(() => [props.collateralFAssetIsActive], (newValue, oldValue) => {
      let chartSettings = {
        id: props.chartData.id,
        data: [0,0,0], //Reset Chart Data
        labels: initializedChartLabels(),
        backgroundColor: initializedChartBackgroundColors(),
      }
      emit('updateChartSettings', chartSettings)

      //Set Chart Data
      setTimeout(swapCharts, 500)
  })

  onMounted(() => {
    if(props.chartData.type === 'dynamic') {
      data.value = props.chartData.data.datasets[0].data
      rotation.value = rotateChart()
      resetChart()
    }
  })

</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .chart {
    position: absolute;
    height: 250px;
    margin: 20px 0;

    @include breakpoint(lg) {
      height: 444px;
    }
    @include breakpoint(xl) {
      height: 444px;
    }
    @include breakpoint(xxl) {
      height: 444px;
    }
    @include breakpoint(xxxl) {
      height: 444px;
    }
  }
</style>