<template>
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.9">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1763 20.7707C27.1414 26.6412 21.5629 30.856 15 30.856C6.71573 30.856 0 24.1402 0 15.856C0 7.57169 6.71573 0.855957 15 0.855957C15.7237 0.855957 16.4355 0.907212 17.1318 1.00629C16.557 2.11327 16.2321 3.37094 16.2321 4.70447C16.2321 9.14247 19.8299 12.7402 24.2679 12.7402C24.4401 12.7402 24.6111 12.7348 24.7806 12.7241C24.4513 13.4472 24.2678 14.2509 24.2678 15.0973C24.2678 17.9834 26.4008 20.3713 29.1763 20.7707Z" fill="#C19745"/>
    <circle cx="7.80902" cy="12.7527" r="2.34467" fill="#453713"/>
    <ellipse cx="23.2604" cy="22.675" rx="2.79199" ry="2.34467" transform="rotate(-25.5022 23.2604 22.675)" fill="#453713"/>
    <circle cx="12.7349" cy="27.047" r="1.85332" fill="#453713"/>
    <circle cx="12.2528" cy="20.3581" r="1.29819" fill="#453713"/>
    <circle cx="16.082" cy="14.4482" r="0.649135" fill="#453713"/>
    <ellipse cx="13.5509" cy="5.09196" rx="0.816049" ry="0.816049" fill="#453713"/>
    <ellipse cx="3.54993" cy="20.4104" rx="0.949951" ry="1.17198" transform="rotate(-12.556 3.54993 20.4104)" fill="#453713"/>
  </g>
  </svg>
</template>