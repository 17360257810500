<template>
  <svg width="52" height="62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m15.771 38.3699 2.9643-1.9537-2.424 5.9497-.3388.8318.7199-.5371 4.5698-3.4092-.9055 2.7164-.2009.6028.5578-.3043 3.293-1.7961c-2.5726 2.8527-6.0176 6.3061-8.9508 9.1691-1.1334 1.1063-2.1896 2.1237-3.0885 2.9832.0423-.0509.0849-.1023.1278-.154 1.5186-1.8285 3.4379-4.0986 5.0463-5.8719l-.3552-.3513L2.24074 59.7411 13.1145 43.093l-.382-.3175-3.91368 3.7385 7.86278-11.8256-1.2819 3.3842-.2737.7225.645-.4252Z" fill="#000" stroke="#000" stroke-width=".5"/>
    <path d="M20.0582 25.6578c2.03-2.6956 3.2573-4.2503 5.6921-7.1422-3.38-.5956-5.3672-.6821-9.1901-.0887-1.407 1.7988-3.231 4.7222-3.8297 5.6043 1.1537-.5841 7.6426.5733 7.3277 1.6266ZM32.9404 35.245c2.1856-2.18 3.5695-3.608 6.1197-6.8115 1.1728 3.2255 1.5298 5.1347 1.6083 9.0025-1.5275 1.6976-4.0903 4.001-4.8553 4.7436.3753-1.2375-1.8898-7.4274-2.8727-6.9346Z" stroke="#000" stroke-width=".75"/>
    <circle cx="39.8064" cy="14.1502" r="3.03571" fill="#000"/>
    <circle cx="39.8064" cy="14.1502" r="4.25" stroke="#000" stroke-width=".5"/>
    <circle cx="21.8751" cy="26.8243" fill="#D9D9D9" stroke="#000" stroke-width=".25" r=".81839"/>
    <circle cx="24.5851" cy="29.4264" fill="#D9D9D9" stroke="#000" stroke-width=".25" r=".81839"/>
    <circle cx="27.6755" cy="32.0096" fill="#D9D9D9" stroke="#000" stroke-width=".25" r=".81839"/>
    <circle cx="30.9853" cy="34.0101" fill="#D9D9D9" stroke="#000" stroke-width=".25" r=".81839"/>
    <path d="M18.9034 27.312C33.1542 5.82136 50.9117 1.18402 50.9117 1.18402s.3685 16.57988-19.5809 35.52438c-5.1226-1.8322-7.9663-4.0605-12.4274-9.3964Zm.7781.9179-1.2631 2.0526c3.3005 3.8444 5.1831 5.3571 9.6085 7.5171l2.0798-1.5545c-4.8229-1.8706-7.8237-4.9472-10.4252-8.0152Z" stroke="#000"/>
    <path d="M50.1236 7.57962c.6702-3.10544.8029-4.43659.7934-6.3956-2.4102.7505-3.7501 1.2865-6.1281 2.36425 1.6667 2.13033 2.8043 3.05361 5.3347 4.03135Z" fill="#000"/></svg>
</template>