<template>
  <div class="draw-container" >
    <DoughnutChart 
      ref="root" 
      class="chart" 
      :chartData="chartData.data" 
      :options="chartData.options" 
    />
    <div 
      v-if="chartData.type === 'dynamic'"
      class="percentage flex"
      :class="{ 
        'color-red': activeNetwork.name === constant.SONGBIRD_NETWORK,
        'color-grey': collateralRequired === 0
      }"
    >
      <span v-if="isTextAnimating">{{ cPercentage }}%</span>
      <span v-else>{{ activeNetwork.collateralPercentageRequired }}%</span>
      <p 
        class="supply-required"
        :class="{ 
          'color-red': activeNetwork.name === constant.SONGBIRD_NETWORK,
          'color-grey': collateralRequired === 0
        }"
      >
        {{ activeNetwork.ticker }} Supply TVL
      </p>
    </div>
    <SimulateSupplyTxt
      v-if="chartData.type === 'dynamic'"
      :constant="constant"
      :activeNetwork="activeNetwork"
      :configs="configs"
      :flrSize="flrSize"
      :sgbSize="sgbSize"
      :totalCollateralRequired="totalCollateralRequired"
      :collateralFAssetIsActive="collateralFAssetIsActive"
      :networkCollateralAllocation="networkCollateralAllocation"
    />
  </div>
</template>
<script setup>
  import { ref, computed, reactive, watch, onMounted, onUnmounted } from "vue"
  import { DoughnutChart } from "vue-chart-3"
  import { Chart, registerables } from 'chart.js'
  import SimulateSupplyTxt from './SimulateChartSupplyTxt.vue'

  Chart.register(...registerables)

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    totalCollateralRequired: {
      type: Number,
      default: 0
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    collateralPercentage: {
      type: Number,
      default: 0.00
    },
    configs: {
      type: Object,
      default: () => {}
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const emit = defineEmits([
    'updateChartGradient',
    'updateChartSettings',
  ])

  const constant = props.constant
  const root = ref('')
  const flrSize = ref(0)
  const sgbSize = ref(0)
  const data = [0, 100]
  const rotation = ref(180)

  const cPercentage = ref(0)
  const animatedPercentageTxt = ref(0)
  const isTextAnimating = ref(true)
  const animationSpeed = 200

  let animator = null
  let animator2 = null
  let animator3 = null
  let animator4 = null

  //NEW
  const coinPrice = computed(() => {
    return props.activeNetwork.modifiedPrice === 0 ? props.activeNetwork.price : props.activeNetwork.modifiedPrice
  })

  const collateralPercentage = computed(() => {
    let percentage = numFormatter(( collateralRequired.value / tradableSupply.value / coinPrice.value) * 100, 2)

    if(percentage >= 100) {
      percentage = 100
    }
    return percentage
  })

  const collateralRequired = computed(() => {
    if(props.collateralFAssetIsActive) {
      return props.totalCollateralRequired * props.networkCollateralAllocation[2].set
    }
    return props.totalCollateralRequired * props.networkCollateralAllocation[1].set
  })

  const tradableSupply = computed(() => {
    return props.activeNetwork.circulatingSupply - props.configs.topHoldersTotalCoinExclusion
  })

  const numFormatter = (value, decimalCount) => {
    return value.toFixed(decimalCount)
  }

  const animateTextDown = () => {
    const value = parseFloat(collateralPercentage.value)
    const to = 0
    const time = value / animationSpeed * 3
    isTextAnimating.value = true

    if(animatedPercentageTxt.value > to) {
      animatedPercentageTxt.value = animatedPercentageTxt.value - time
      cPercentage.value = (animatedPercentageTxt.value).toFixed(1)
      setTimeout(animateTextDown, 1)
    } else {
      cPercentage.value = 0
    }
  }

  const animateTextUp = () => {
    const value = collateralPercentage.value
    const data = parseFloat(cPercentage.value)
    const time = value / animationSpeed * 3

    if(data < value) {
      cPercentage.value = (data + time).toFixed(1)
      setTimeout(animateTextUp, 1)
    } else {
      cPercentage.value = value
      isTextAnimating.value = false
    }
  }

  const calculateChartData = () => {
    const data = []
    const result1 = 100 - (collateralPercentage.value - 100) * -1
    const result2 = 100 - result1

    data.push(result1)
    data.push(result2)
    return data
  }

  const calculateChartRotation = () => {
    const result = 100 - (collateralPercentage.value - 100) * -1
    const percentage = (100 - result)/100
    const rotation = 180 * percentage
    return rotation
  }

  const linearGradient = (x1, y1) => {
    let gradients = []
    const canvas = root.value

    props.chartData.gradientColors.forEach( function( item ){
      let gradient = canvas.chartInstance.$context.chart.ctx.createLinearGradient(0, 0, x1, y1);
      gradient.addColorStop(0.15, item.start);
      gradient.addColorStop(1, item.end);
      gradients.push(gradient);
    })

    const settings = {
      id: props.chartData.id,
      gradients,
    }

    emit('updateChartGradient', settings)
  }

  //Not currently Used
  // const conicGradient = (radians, x1, y1) => {
  //   let gradients = []
  //   const canvas = root.value

  //   props.chartData.gradientColors.forEach( function( item ){
  //     let gradient = canvas.chartInstance.$context.chart.ctx.createConicGradient(radians, x1, y1);
  //     gradient.addColorStop(0, item.end)
  //     gradient.addColorStop(.45, item.end)
  //     gradient.addColorStop(1, item.start)
  //     gradients.push(gradient);
  //   })

  //   const settings = {
  //     id: props.chartData.id,
  //     gradients,
  //   }
  //   emit('updateChartGradient', settings)
  // }

  const logoMobileSize = () => {
    flrSize.value = 8.0
    sgbSize.value = 4.5
  }

  const logoDesktopSize = () => {
    flrSize.value = 12.0
    sgbSize.value = 6.75
  }
  
  const drawGradient = (screenWidth) => {
    if(screenWidth > constant.MOBILE_MAX_WIDTH) {
      linearGradient(300, 340)
      //conicGradient(4.72, 215, 215)
      logoDesktopSize()
    } else {
      linearGradient(220, 210)
      //conicGradient(4.72, 145, 145)
      logoMobileSize()
    }
  }

  const windowResize = (event) => {
    if(props.chartData.type === 'dynamic') {
      drawGradient(event.target.innerWidth )
    }
  }

  const initializeChart = () => {
    animateTextUp()

    let chartSettings = {
      id: props.chartData.id,
      src: 'SimulateChart - InitializeChart',
      data: calculateChartData(),
      rotation: calculateChartRotation(),
      options: {
        animations: {
          duration: 500
        }
      }
    }

    clearTimeout(animator2)
    emit('updateChartSettings', chartSettings)
  }

  const resetChart = () => {
    data.value = props.chartData.data.datasets[0].data
    rotation.value = props.chartData.data.datasets[0].rotation
    const networkColor = props.activeNetwork.name === constant.SONGBIRD_NETWORK ? constant.RED_CHART_MAX_RGB : constant.PURPLE_CHART_MAX_RGB

    let chartSettings = {
      id: props.chartData.id,
      src: 'SimulateChart - ResetChart',
      data: [0, 100],
      rotation: 180,
      options: {
        animations: {
          duration: 500
        }
      },
      borderColor: [
        `rgba(${networkColor}, 1)`, 
        'transparent'
      ],
      gradientColors: [
        { 
          start: `rgba(${networkColor}, 1)`,
          end: 'rgba(255, 255, 255, 1)'
        },
        {
          start: 'transparent',
          end: 'transparent'
        }
      ]
    }
    clearTimeout(animator)
    clearTimeout(animator3)
    emit('updateChartSettings', chartSettings)
  }

  const reAnimateChart = () => {
    animateTextUp()
    let chartSettings = {
      id: props.chartData.id,
      src: 'SimulateChart - ReanimateChart',
      data: calculateChartData(),
      rotation: calculateChartRotation(),
      options: {
        animations: {
          duration: 500
        }
      }
    }
    emit('updateChartSettings', chartSettings)
    clearTimeout(animator2)
    clearTimeout(animator4)
  }

  //Initial Chart Animation
  if(props.chartData.type === 'dynamic') {
    isTextAnimating.value = true
    animator = setTimeout(resetChart, 0)
    animator2 = setTimeout(initializeChart, 750)
  }

  watch(() => [
    props.activeNetwork,
    props.configs.topHoldersTotalCoinExclusion,
    props.configs.settings[constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID - 1].set,
    props.configs.settings[constant.SETTINGS_MINT_COLLATERAL_RATE_ID - 1].set
    ], (newValue, oldValue) => 
  {
    //Static Chart 1 - Abort
    if(props.chartData.type === 'static'){
      return
    }

    if(props.chartData.type === 'dynamic') {

      //Network Change
      if(newValue[0].name != oldValue[0].name) {
        //Set/Change Color Data and Gradient
        resetChart()
        drawGradient(window.innerWidth)

        animator3 = setTimeout(resetChart, 100)
        animator4 = setTimeout(reAnimateChart, 600)
        
        isTextAnimating.value = true
        animatedPercentageTxt.value = parseFloat(collateralPercentage.value)
       
        animateTextDown()
      }

      //Update Top Holder Count
      if(newValue[1] != oldValue[1]) {
        initializeChart()
      }

      //Configuration>Settings Change 
      if(newValue[2] < oldValue[2] || 
        newValue[3] < oldValue[3] || 
        newValue[3] > oldValue[3]) {
        animator = setTimeout(resetChart, 100)
        animator2 = setTimeout(reAnimateChart, 600)
      }
    }
  })

  onMounted(() => {
    if(props.chartData.type === 'dynamic') {
      window.addEventListener("resize", windowResize)
      //Set Color Data and Gradient
      resetChart()
      drawGradient(window.innerWidth)
    }
  })

  onUnmounted(() => {
    if(props.chartData.type === 'dynamic') {
      window.removeEventListener("resize", windowResize);
    }
  })
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .draw-container {
    position: relative;

    .percentage {
      position: absolute;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      bottom: 0;
      height: 70px;
      left: 0;
      right: 0;
      color: $PURPLE;
      text-align: center;
      font-size: $FONT-SMALL;
      font-weight: 600;

      span {
        margin-bottom: 7px;
      }

      @include breakpoint(lg) {
        height: 100px;
        font-size: $FONT-XLARGE;
      }

      @include breakpoint(xl) {
        height: 100px;
        font-size: $FONT-XLARGE;
      }

      @include breakpoint(xxl) {
        height: 100px;
        font-size: $FONT-XLARGE;
      }

      @include breakpoint(xxxl) {
        height: 100px;
        font-size: $FONT-XLARGE;
      }

      .supply-required {
        color: $PURPLE;
        font-size: $FONT-XXXXSMALL;
        line-height: $FONT-XXXXSMALL;
        font-weight: 600;
        margin: -3px 0 0 0;
      }
    }
  }
</style>