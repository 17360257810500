<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.7194 15.6108C13.4457 11.6999 10.885 3.83855 10.8318 3.67969L18.9226 10.3651L14.7194 15.6108Z" :fill="fill"/>
    <path d="M13.4232 25.0254L14.39 17.0801L9.25171 26.2383L13.4232 25.0254Z" :fill="fill"/>
    <path d="M14.2539 21.7697L14.9024 16.2666L21.8031 17.1171L14.2539 21.7697Z" :fill="fill"/>
    <path d="M19.1538 10.7813L21.9311 16.6875L15.0933 15.8789L19.1538 10.7813Z" :fill="fill"/>
    <path d="M20.5422 12.8379L24.1391 11.2418L22.2922 16.4965L20.5422 12.8379Z" :fill="fill"/>
    <path d="M23.5941 14.1151L24.4624 11.6465L25.4644 15.7826L23.5941 14.1151Z" :fill="fill"/>
    <circle cx="15.8582" cy="15" r="14.5" :stroke="fill"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 31
    },
    height: {
      type: Number,
      default: 30
    },
    size: {
      type: Number,
      default: 1.0
    },
    color: {
      type: String,
      default: 'E21D4E'
    }
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
  const fill = `#${props.color}`
</script>