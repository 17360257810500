<template>
  <svg 
    :width="fWidth" 
    :height="fHeight"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle v-if="stroke" 
      cx="2.4964" cy="11.4799" r="1.6163" stroke-width="1%" :fill="fill"
      :stroke="`#${constant.GREY_LIGHT_STROKE}`"
    />
    <circle v-else 
      cx="2.4964" cy="11.4799" r="1.6163" :fill="fill"
    />
    <path
      v-if="stroke"
      d="M10.4351 5.1575H3.8274c-.7843 0-2.9473.7491-2.9473 3.1736h6.6077c.8557 0 2.8998-.773 2.9473-3.1736ZM13.6201.3801H3.8274c-.7843 0-2.9473.7492-2.9473 3.1736h9.7689c.8557 0 2.9236-.773 2.9711-3.1736Z" stroke-width="1%" :fill="fill" 
      :stroke="`#${constant.GREY_LIGHT_STROKE}`"
    />
    <path
      v-else
      d="M10.4351 5.1575H3.8274c-.7843 0-2.9473.7491-2.9473 3.1736h6.6077c.8557 0 2.8998-.773 2.9473-3.1736ZM13.6201.3801H3.8274c-.7843 0-2.9473.7492-2.9473 3.1736h9.7689c.8557 0 2.9236-.773 2.9711-3.1736Z" :fill="fill" 
    />
  </svg>
</template>
<script setup>
  import { ref, watch } from 'vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '000000'
    },
    stroke: {
      type: Boolean,
      default: false
    }
  })
  const constant = props.constant
  const viewBox = `0 0 ${props.width} ${props.height}`
  const fill = `#${props.color}`

  const fWidth = ref(0)
  fWidth.value = props.width * props.size

  const fHeight = ref(0)
  fHeight.value = props.height * props.size
  
  watch(() => [props.size], (newValue) => {
    fWidth.value = props.width * newValue[0]
    fHeight.value = props.height * newValue[0]
  })
</script>