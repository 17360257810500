<template>
  <div class="control-set flex">
    <Button
      :class="{'prevent-click': isActiveSgbNetwork }"
      :isActive="false"
      :eventName="constant.SONGBIRD_NETWORK"
      @clicked="clickedNetwork($event)"
    >
      <SongbirdNetworkIcon
        :isActiveNetworkSubNav="networkScreen.isActive"
        :isActiveNetwork="isActiveSgbNetwork"
        :width="31"
        :height="30"
        :size="1.0"
        :color="constant.RED"
      />
    </Button>
    <Button
      :class="{'prevent-click': isActiveFlrNetwork }"
      :isActive="true"
      :eventName="constant.FLARE_NETWORK"
      @clicked="clickedNetwork($event)"
    >
      <FlareNetworkIcon
        :isActiveNetworkSubNav="networkScreen.isActive"
        :isActiveNetwork="isActiveFlrNetwork"
        :width="31"
        :height="30"
        :size="1.0"
        :color="constant.PURPLE"
      />
    </Button>
  </div>
</template>
<script setup>
  import Button from '../../_generics/Button.vue'

  import FlareNetworkIcon from '../../_icons/Network/FlareNetworkIcons.vue'
  import SongbirdNetworkIcon from '../../_icons/Network/SongbirdNetworkIcons.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: String,
      default: ''
    },
    networkScreen: {
      type: Object,
      default: () => {}
    },
    shareScreen: {
      type: Object,
      default: () => {}
    },
    isActiveFlrNetwork: {
      type: Boolean,
      default: false
    },
    isActiveSgbNetwork: {
      type: Boolean,
      default: false
    },
  })
  const constant = props.constant
  const emit = defineEmits([
    'activateNetwork',
  ])

  const clickedNetwork = (event) => {
    emit('activateNetwork', event)
  }
</script>
<style lang="scss" scope>
  @import '../../../assets/scss/_variables.scss';
  @import '../../../assets/scss/mixins/layout.scss';
</style>