<template>
  <div class="price-slider-container">
    <h3 class="title-xxsmall">{{ coin.ticker }} Price Simulator</h3>
    <div class="price-range-indicator flex">
      <span>{{minimumPriceMultiplier}}x</span>
      <span class="ml-10">1.0x</span>
      <span>{{maximumPriceMultiplier}}x</span>
    </div>
   <InputRange
      :constant="constant"
      :disabled="false"
      :classes="{
        'price-slider' : true,
      }"
      :styles="interactiveRangeStyles"
      :modelValue="modelValue"
      @sliderValue="sliderValueUpdate($event)"
    /> 
    <div class="price-range flex">
      <span>${{currencyFormatter(minimumPrice)}}</span>
      <span>${{currencyFormatter(maximumPrice)}}</span>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed, watch } from 'vue'
  import InputRange from './InputRange.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    }
  })

  const emit = defineEmits([
    'updateModifiedCoinPrice'
  ])

  const constant = props.constant
  const modifiedCoinPrice = ref(0)
  const modelValue = ref(50)
  const minimumPrice = ref(0)
  const maximumPrice = ref(0)
  const minimumPriceMultiplier = ref(0)
  const maximumPriceMultiplier = ref(0)

  let priceRangeTable = []

  const interactiveRangeStyles = computed(() => {
    return {'background' : `linear-gradient(90deg, rgba(${ constant.PURPLE_RGB }, 1) ${modelValue.value}%, rgba(${ constant.PURPLE_XXLIGHT_RGB }, 1) ${modelValue.value}%)`}
  })

  const setMinPrice = () => {
    minimumPrice.value = props.coin.price * getConfigsSettingById(constant.SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID).set
  }

  const setMaxPrice = () => {
    maximumPrice.value = props.coin.price * getConfigsSettingById(constant.SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID).set
  }

  const setMinPriceMultiplier = () => {
    minimumPriceMultiplier.value = getConfigsSettingById(constant.SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID).set
  }

  const setMaxPriceMultiplier = () => {
    maximumPriceMultiplier.value = getConfigsSettingById(constant.SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID).set.toFixed(1)
  }

  const getConfigsSettingById = (settingId) => {
    const setting = props.configs.settings.find((setting) => setting.id === settingId)
    return setting
  }

  const sliderValueUpdate = (event) => {
    modelValue.value = parseInt(event)
    modifiedCoinPrice.value = priceRangeTable[modelValue.value].price
    updateModifiedCoinPrice(modifiedCoinPrice.value)
  }

  const currencyFormatter = (value, decimal = 2) => {
    return value.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  watch(() => [
      props.configs.settings[constant.SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID - 1].set, 
      props.configs.settings[constant.SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID - 1].set
    ], (newValue, oldValue) => {

    modifiedCoinPrice.value = props.coin.modifiedPrice

    setMinPrice()
    setMaxPrice()

    setMaxPriceMultiplier()
    setMinPriceMultiplier()

    if(modifiedCoinPrice.value != 0) {
      //Mod Price - Lowered to Maximum Price
      if(modifiedCoinPrice.value > maximumPrice.value) {
        modifiedCoinPrice.value = maximumPrice.value
      }

      //Mod Price - Raised to Minimum Price
      if((modifiedCoinPrice.value < minimumPrice.value)) {
        modifiedCoinPrice.value = minimumPrice.value
      }
    }

    initializePriceSetter()
    updateModifiedCoinPrice(modifiedCoinPrice.value)
  })

  const createPriceRangeTableData = () => {
    priceRangeTable = []
    for(let i = 0; i < 101; i++) {

      if (i <= 50 ) {
        priceRangeTable.push({
          id: i + 1,
          price: (((props.coin.price - minimumPrice.value) / 50) * i) + minimumPrice.value
        })
      } else if ( i > 50  ) {
        priceRangeTable.push({
          id: i + 1,
          price: (((maximumPrice.value - props.coin.price) / 50) * (i - 50)) + props.coin.price
        })
      }
    }
  } 

  const getRangeTick = (price) => {
    const len = priceRangeTable.length
    let id = 0
    for(let i = 0; i < len; i++) {
      if(priceRangeTable[i].price < price) {   
        id = i + 1
      }
    }
    return id
  }

  const updateModifiedCoinPrice = (price) => {
    const mCoin = {
      id: props.coin.id,
      modifiedPrice: price
    }
    emit('updateModifiedCoinPrice', mCoin)
  }

  const setInitialRangeInputValue = () => {
    const price = props.coin.modifiedPrice != 0 ? props.coin.modifiedPrice : props.coin.price
    const tickId = getRangeTick(price)
    return tickId
  }

  //Intialize Price Simulator - Set ModelValue
  const initializePriceSetter = () => {
    setMinPrice()
    setMaxPrice()
    setMaxPriceMultiplier()
    setMinPriceMultiplier()

    createPriceRangeTableData()
    modelValue.value = setInitialRangeInputValue()
  }

  initializePriceSetter()
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .price-slider-container {
    position: relative;
    margin: 10px auto 0 auto;
    overflow-y: hidden;
    min-width: 340px;
    width: 70%;

    .price-slider {
      margin-top: 10px;
      width: 100%;
    }

    .price-range {
      padding-top: 25px;
    }

    .price-range-indicator {
      padding: 0;
      margin: 10px auto 0 auto;
      width: 100%;
    }
  }
</style>