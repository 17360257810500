<template>
    <div class="mint-quantity">
      <form 
        class="mb-20" 
        @submit.prevent="onSubmit"
      >
        <div class="input-fasset flex">
          <label 
            class="flex" 
            :for="`quantity${coin.id}`"
          >
            F{{ coin.ticker }}
          </label>
          <InputCurrencyMask 
            :constant="constant"
            :coin="coin"
            :configs="configs"
            :mxMintRate="maxMintRate"
            v-model="modelValue" 
            @errorMsg="errorHandler($event)"
            @updateInputMintQuantity="updateInputMintQuantity($event)"
          />
        </div>
        <div class="flex">
          <Button 
            :eventName="`${collateralRate} ${constant.ONE_HUNDRETH}`"
            :class="{'isActive': mintQuantityMatch(constant.ONE_HUNDRETH_NUM) }"
            @clicked="clicked($event)"
          >
            0.01%
          </Button>
          <Button 
            :eventName="`${collateralRate} ${constant.ONE_TENTH}`"
            :class="{'isActive': mintQuantityMatch(constant.ONE_TENTH_NUM) }" 
            @clicked="clicked($event)"
          >
            0.10%
          </Button>
          <Button 
            :eventName="`${collateralRate} ${constant.ONE}`"
            :class="{'isActive': mintQuantityMatch(constant.ONE_NUM) }" 
            @clicked="clicked($event)"
          >
            1.00%
          </Button>
          <Button 
            :eventName="`${collateralRate} ${constant.MAX}`"
            :class="{'isActive': mintQuantityMatch(maxMintRate) }"
            @clicked="clicked($event)"
          >
           {{ currencyFormatter(maxMintRatePercentage) }}%

          </Button>
          <Button 
            :eventName="`${collateralRate} ${constant.RESET}`" 
            @clicked="clicked($event)"
          >
            <Reset />
          </Button>
        </div>
      </form>
      <div class="collateral-backing">
        <p>Network Collateral Rate is set to {{ requiredCollateralRate() }}%</p>
      </div>
      <div
        class="error-msg"
      >
        <Transition name="error"> 
          <p v-if="errorMsg.length"
          >{{ errorMsg }}
          </p>
        </Transition>
      </div>
    </div>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import { event } from "vue-gtag"
  import Reset from '../_icons/Reset.vue'
  import Button from '../_generics/Button.vue'
  import InputCurrencyMask from '../_generics/InputCurrencyMask.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    maxMintRate: {
      type: Number,
      default: 0
    },
  })

  const emit = defineEmits([
    'updateMintQuantity',
    'errorMsg'
  ])

  const constant = props.constant
  const errorMsg = ref('')

  const collateralRate = computed(() => {
    return getConfigsSettingById(constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set
  })

  const maxMintRatePercentage = computed(() => {
    return getConfigsSettingById(constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID).set * 100
  })

  const currencyFormatter = (value, decimal = 2) => {
    return value.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const modelValue = computed(() => {
    return props.coin.mintQuantity
  })

  const mintQuantityMatch = (mpercentage) => {
    const mQuantity = modelValue.value
    const cSupply = props.coin.circulatingSupply * mpercentage
    const result = mQuantity.toFixed(2) === cSupply.toFixed(2) ? true : false
    return result
  }

  const updateInputMintQuantity = (event) => {
    trackEvent(`mint_fasset_coin_${props.coin.name}`, `mint_fasset_network_${props.activeNetwork.name}`, 'mint_fasset_click')
    emit('updateMintQuantity', event)
  }

  const clicked = (event) => {
    const eventValues = event.split(' ')
    const eventDirective = eventValues[1].trim()
    const updatedEvent = {
      configId: props.configs.id,
      coinId: props.coin.id,
      inputMintAmount: null,
      directive: eventDirective
    }
    trackEvent(`mint_fasset_coin_${props.coin.name}`, `mint_fasset_network_${props.activeNetwork.name}`, 'mint_fasset_click')
    emit('updateMintQuantity', updatedEvent)
  }

  const calCollateralRate = () => {
    return getConfigsSettingById(constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set
  }

  const requiredCollateralRate = () => {
    return (calCollateralRate() * 100).toFixed(0)
  }

  const onSubmit = () => {
    //console.log('Handle Submit: ' + updateValue.value)
  }
  
  const errorHandler = (event) => {
    errorMsg.value = event
    setTimeout(() => errorMsg.value = '', 10000)
  }

  const getConfigsSettingById = (settingId) => {
    const setting = props.configs.settings.find((setting) => setting.id === settingId)
    return setting
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .mint-quantity {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 15px;
    }

    .collateral-backing {
      p {
        padding: 0;
        margin: 0 0 20px 0;
        font-size: $FONT-XXSMALL;
      }
    }

    form {
      margin: 0 auto;
      min-width: 320px;
      width: 50%;

      .input-fasset {
        justify-content: center;
        margin: 0;
        height: 50px;
        min-width: 320px;

        label, input {
          height: 100%;
        }

        label {
          width: 30%;
          justify-content: center;
        }
        input {
          width: 70%;
          display: flex;
        }
      }
    }

    .flex {
      margin: 10px 0;
      height: 35px;

      button {
        color: $GREY-XLIGHT;
        font-size: $FONT-XXSMALL;
        background-color: $WHITE-MEDIUM;
        width: 18%;
        height: 100%;

        &.isActive {
          color: $PURPLE_XLIGHT;
          font-weight: 600;
        }
      }
    }
  }

  .error-msg {
    display: block;
    margin-right: auto;
    margin-left: auto;
    
    p {
      text-align: center;
    }
  }

  .error-enter-from {
    opacity: 0;
    height: 0px;
    margin-top: 0;
  }
  .error-enter-to {
    opacity: 1;
    height: 30px;
    margin-top: 10px;
  }
  .error-enter-active {
    transition: all 0.35s ease-out;
  }

  .error-leave-from {
    height: 30px;
    opacity: 1;
    margin-top: 10px;
  }
  .error-leave-to {
    height: 0px;
    opacity: 0;
    margin-top: 0;
  }
  .error-leave-active{
    transition: all .25s ease-in;
  }
</style>