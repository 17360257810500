<template>
  <svg width="106" height="51" viewBox="0 0 106 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.67386 40.527C8.28986 40.215 7.90586 39.987 7.52186 39.843C7.13786 39.687 6.76586 39.609 6.40586 39.609C5.94986 39.609 5.57786 39.717 5.28986 39.933C5.00186 40.149 4.85786 40.431 4.85786 40.779C4.85786 41.019 4.92986 41.217 5.07386 41.373C5.21786 41.529 5.40386 41.667 5.63186 41.787C5.87186 41.895 6.13586 41.991 6.42386 42.075C6.72386 42.159 7.01786 42.249 7.30586 42.345C8.45786 42.729 9.29786 43.245 9.82586 43.893C10.3659 44.529 10.6359 45.363 10.6359 46.395C10.6359 47.091 10.5159 47.721 10.2759 48.285C10.0479 48.849 9.70586 49.335 9.24986 49.743C8.80586 50.139 8.25386 50.445 7.59386 50.661C6.94586 50.889 6.20786 51.003 5.37986 51.003C3.66386 51.003 2.07386 50.493 0.609863 49.473L2.12186 46.629C2.64986 47.097 3.17186 47.445 3.68786 47.673C4.20386 47.901 4.71386 48.015 5.21786 48.015C5.79386 48.015 6.21986 47.883 6.49586 47.619C6.78386 47.355 6.92786 47.055 6.92786 46.719C6.92786 46.515 6.89186 46.341 6.81986 46.197C6.74786 46.041 6.62786 45.903 6.45986 45.783C6.29186 45.651 6.06986 45.531 5.79386 45.423C5.52986 45.315 5.20586 45.195 4.82186 45.063C4.36586 44.919 3.91586 44.763 3.47186 44.595C3.03986 44.415 2.64986 44.181 2.30186 43.893C1.96586 43.605 1.68986 43.245 1.47386 42.813C1.26986 42.369 1.16786 41.811 1.16786 41.139C1.16786 40.467 1.27586 39.861 1.49186 39.321C1.71986 38.769 2.03186 38.301 2.42786 37.917C2.83586 37.521 3.32786 37.215 3.90386 36.999C4.49186 36.783 5.14586 36.675 5.86586 36.675C6.53786 36.675 7.23986 36.771 7.97186 36.963C8.70386 37.143 9.40586 37.413 10.0779 37.773L8.67386 40.527Z" fill="#0C0C0C"/>
    <path d="M17.2506 41.391V50.625H13.9926V41.391H17.2506ZM13.7766 37.845C13.7766 37.593 13.8246 37.359 13.9206 37.143C14.0166 36.915 14.1486 36.717 14.3166 36.549C14.4846 36.381 14.6766 36.249 14.8926 36.153C15.1206 36.057 15.3606 36.009 15.6126 36.009C15.8646 36.009 16.0986 36.057 16.3146 36.153C16.5426 36.249 16.7406 36.381 16.9086 36.549C17.0766 36.717 17.2086 36.915 17.3046 37.143C17.4006 37.359 17.4486 37.593 17.4486 37.845C17.4486 38.097 17.4006 38.337 17.3046 38.565C17.2086 38.781 17.0766 38.973 16.9086 39.141C16.7406 39.309 16.5426 39.441 16.3146 39.537C16.0986 39.633 15.8646 39.681 15.6126 39.681C15.3606 39.681 15.1206 39.633 14.8926 39.537C14.6766 39.441 14.4846 39.309 14.3166 39.141C14.1486 38.973 14.0166 38.781 13.9206 38.565C13.8246 38.337 13.7766 38.097 13.7766 37.845Z" fill="#0C0C0C"/>
    <path d="M20.917 41.391H24.175V42.525C25.063 41.673 26.059 41.247 27.163 41.247C28.531 41.247 29.575 41.823 30.295 42.975C31.027 41.811 32.071 41.229 33.427 41.229C33.859 41.229 34.267 41.295 34.651 41.427C35.035 41.547 35.365 41.745 35.641 42.021C35.917 42.285 36.133 42.633 36.289 43.065C36.457 43.497 36.541 44.019 36.541 44.631V50.625H33.283V46.071C33.283 45.603 33.247 45.219 33.175 44.919C33.115 44.619 33.019 44.385 32.887 44.217C32.767 44.049 32.623 43.935 32.455 43.875C32.287 43.815 32.107 43.785 31.915 43.785C30.871 43.785 30.349 44.547 30.349 46.071V50.625H27.091V46.071C27.091 45.603 27.061 45.219 27.001 44.919C26.941 44.607 26.851 44.361 26.731 44.181C26.623 44.001 26.479 43.881 26.299 43.821C26.119 43.749 25.909 43.713 25.669 43.713C25.465 43.713 25.273 43.743 25.093 43.803C24.913 43.863 24.751 43.983 24.607 44.163C24.475 44.331 24.367 44.571 24.283 44.883C24.211 45.183 24.175 45.579 24.175 46.071V50.625H20.917V41.391Z" fill="#0C0C0C"/>
    <path d="M47.9979 40.041H43.7499V42.309H47.6199V45.297H43.7499V50.625H40.2219V37.053H47.9979V40.041Z" fill="#6401E1"/>
    <path d="M54.1357 45.981C54.1357 46.281 54.1897 46.563 54.2977 46.827C54.4057 47.079 54.5497 47.301 54.7297 47.493C54.9217 47.685 55.1437 47.835 55.3957 47.943C55.6597 48.051 55.9417 48.105 56.2417 48.105C56.5297 48.105 56.7997 48.051 57.0517 47.943C57.3157 47.835 57.5377 47.685 57.7177 47.493C57.9097 47.301 58.0597 47.079 58.1677 46.827C58.2877 46.575 58.3477 46.305 58.3477 46.017C58.3477 45.729 58.2877 45.459 58.1677 45.207C58.0597 44.943 57.9097 44.715 57.7177 44.523C57.5377 44.331 57.3157 44.181 57.0517 44.073C56.7997 43.965 56.5297 43.911 56.2417 43.911C55.9417 43.911 55.6597 43.965 55.3957 44.073C55.1437 44.181 54.9217 44.331 54.7297 44.523C54.5497 44.715 54.4057 44.937 54.2977 45.189C54.1897 45.429 54.1357 45.693 54.1357 45.981ZM58.2397 41.391H61.5157V50.625H58.2397V49.599C57.5437 50.475 56.6017 50.913 55.4137 50.913C54.7417 50.913 54.1237 50.793 53.5597 50.553C52.9957 50.301 52.5037 49.953 52.0837 49.509C51.6637 49.065 51.3337 48.543 51.0937 47.943C50.8657 47.343 50.7517 46.689 50.7517 45.981C50.7517 45.321 50.8657 44.697 51.0937 44.109C51.3217 43.509 51.6397 42.987 52.0477 42.543C52.4557 42.099 52.9417 41.751 53.5057 41.499C54.0697 41.235 54.6937 41.103 55.3777 41.103C56.5297 41.103 57.4837 41.505 58.2397 42.309V41.391Z" fill="#6401E1"/>
    <path d="M71.2928 43.767C70.6928 43.455 70.1048 43.299 69.5288 43.299C69.2288 43.299 68.9828 43.359 68.7908 43.479C68.6108 43.599 68.5208 43.761 68.5208 43.965C68.5208 44.073 68.5328 44.163 68.5568 44.235C68.5928 44.295 68.6588 44.355 68.7548 44.415C68.8628 44.475 69.0128 44.529 69.2048 44.577C69.4088 44.625 69.6728 44.685 69.9968 44.757C70.8968 44.937 71.5688 45.261 72.0128 45.729C72.4568 46.185 72.6788 46.773 72.6788 47.493C72.6788 48.033 72.5708 48.519 72.3548 48.951C72.1388 49.383 71.8388 49.749 71.4548 50.049C71.0708 50.337 70.6088 50.559 70.0688 50.715C69.5288 50.883 68.9348 50.967 68.2868 50.967C67.0388 50.967 65.8088 50.613 64.5968 49.905L65.7848 47.601C66.6968 48.225 67.5608 48.537 68.3768 48.537C68.6768 48.537 68.9228 48.471 69.1148 48.339C69.3068 48.207 69.4028 48.039 69.4028 47.835C69.4028 47.715 69.3848 47.619 69.3488 47.547C69.3248 47.463 69.2648 47.391 69.1688 47.331C69.0728 47.259 68.9288 47.199 68.7368 47.151C68.5568 47.091 68.3168 47.031 68.0168 46.971C67.0088 46.767 66.3008 46.467 65.8928 46.071C65.4968 45.663 65.2988 45.105 65.2988 44.397C65.2988 43.881 65.3948 43.419 65.5868 43.011C65.7788 42.591 66.0548 42.237 66.4148 41.949C66.7748 41.661 67.2068 41.439 67.7108 41.283C68.2268 41.127 68.7968 41.049 69.4208 41.049C70.4408 41.049 71.4308 41.247 72.3908 41.643L71.2928 43.767Z" fill="#6401E1"/>
    <path d="M81.891 43.767C81.291 43.455 80.703 43.299 80.127 43.299C79.827 43.299 79.581 43.359 79.389 43.479C79.209 43.599 79.119 43.761 79.119 43.965C79.119 44.073 79.131 44.163 79.155 44.235C79.191 44.295 79.257 44.355 79.353 44.415C79.461 44.475 79.611 44.529 79.803 44.577C80.007 44.625 80.271 44.685 80.595 44.757C81.495 44.937 82.167 45.261 82.611 45.729C83.055 46.185 83.277 46.773 83.277 47.493C83.277 48.033 83.169 48.519 82.953 48.951C82.737 49.383 82.437 49.749 82.053 50.049C81.669 50.337 81.207 50.559 80.667 50.715C80.127 50.883 79.533 50.967 78.885 50.967C77.637 50.967 76.407 50.613 75.195 49.905L76.383 47.601C77.295 48.225 78.159 48.537 78.975 48.537C79.275 48.537 79.521 48.471 79.713 48.339C79.905 48.207 80.001 48.039 80.001 47.835C80.001 47.715 79.983 47.619 79.947 47.547C79.923 47.463 79.863 47.391 79.767 47.331C79.671 47.259 79.527 47.199 79.335 47.151C79.155 47.091 78.915 47.031 78.615 46.971C77.607 46.767 76.899 46.467 76.491 46.071C76.095 45.663 75.897 45.105 75.897 44.397C75.897 43.881 75.993 43.419 76.185 43.011C76.377 42.591 76.653 42.237 77.013 41.949C77.373 41.661 77.805 41.439 78.309 41.283C78.825 41.127 79.395 41.049 80.019 41.049C81.039 41.049 82.029 41.247 82.989 41.643L81.891 43.767Z" fill="#6401E1"/>
    <path d="M93.1192 44.667C93.0112 44.211 92.7892 43.845 92.4532 43.569C92.1172 43.293 91.7092 43.155 91.2292 43.155C90.7252 43.155 90.3112 43.287 89.9872 43.551C89.6752 43.815 89.4772 44.187 89.3932 44.667H93.1192ZM89.3032 46.557C89.3032 47.961 89.9632 48.663 91.2832 48.663C91.9912 48.663 92.5252 48.375 92.8852 47.799H96.0352C95.3992 49.911 93.8092 50.967 91.2652 50.967C90.4852 50.967 89.7712 50.853 89.1232 50.625C88.4752 50.385 87.9172 50.049 87.4492 49.617C86.9932 49.185 86.6392 48.669 86.3872 48.069C86.1352 47.469 86.0092 46.797 86.0092 46.053C86.0092 45.285 86.1292 44.595 86.3692 43.983C86.6092 43.359 86.9512 42.831 87.3952 42.399C87.8392 41.967 88.3732 41.637 88.9972 41.409C89.6332 41.169 90.3472 41.049 91.1392 41.049C91.9192 41.049 92.6212 41.169 93.2452 41.409C93.8692 41.637 94.3972 41.973 94.8292 42.417C95.2612 42.861 95.5912 43.407 95.8192 44.055C96.0472 44.691 96.1612 45.411 96.1612 46.215V46.557H89.3032Z" fill="#6401E1"/>
    <path d="M103.223 44.109V50.625H99.9646V44.109H98.8846V41.391H99.9646V38.619H103.223V41.391H105.077V44.109H103.223Z" fill="#6401E1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.9427 10.1301C57.2899 6.14273 62.0844 1.86339 68.4133 2.06429C76.9169 2.33422 83.1738 9.21009 82.9215 17.1156C82.801 20.8905 81.3522 24.5957 78.6 27.3539C75.8086 30.1514 71.8643 31.7847 67.1603 31.6867C63.7534 31.6157 60.0907 30.1796 56.8358 26.6921C55.7895 25.5712 54.7712 24.1364 53.8077 22.6885C52.7563 24.2136 51.6412 25.7456 50.5507 26.8113C47.0274 30.2546 43.4965 31.6875 39.7358 31.6875C34.4 31.6875 30.5084 29.4018 28.0263 26.3354C25.6507 23.4007 24.6108 19.82 24.6108 17C24.6108 14.0805 25.6023 10.4322 27.9883 7.44212C30.4792 4.32051 34.3869 2.06247 39.7358 2.06247C46.1542 2.06247 50.7259 6.15739 53.9427 10.1301ZM58.2923 16.2942C58.4003 16.4606 58.5069 16.625 58.612 16.7872C58.7225 16.9577 58.8314 17.1257 58.9386 17.2909C60.258 19.323 61.2561 20.8027 62.1359 21.7453C64.131 23.8829 65.9691 24.4103 67.3113 24.4383C70.1731 24.4979 72.1663 23.5374 73.4679 22.233C74.8086 20.8893 75.6082 18.9845 75.6752 16.8843C75.7979 13.0398 72.743 9.45538 68.1833 9.31064C65.1667 9.21488 62.1301 11.4683 58.8472 15.5845C58.66 15.8192 58.4751 16.0559 58.2923 16.2942ZM49.4603 16.1916C49.2496 15.9043 49.0362 15.62 48.8192 15.3391C45.8467 11.4897 42.9909 9.31247 39.7358 9.31247C36.6473 9.31247 34.805 10.5232 33.6553 11.9641C32.4006 13.5364 31.8608 15.5445 31.8608 17C31.8608 18.2424 32.3817 20.193 33.6614 21.7739C34.8346 23.2232 36.693 24.4375 39.7358 24.4375C41.33 24.4375 43.1063 23.9493 45.4835 21.6262C46.1981 20.9278 47.1487 19.594 48.5438 17.541C48.6562 17.3757 48.7706 17.2071 48.887 17.0355C49.0731 16.7612 49.2642 16.4794 49.4603 16.1916Z" fill="url(#paint0_linear_1584_973)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.9295 11.5266C57.2819 7.34119 62.0602 2.73806 68.3856 2.93885C76.4131 3.19367 82.2836 9.6723 82.0469 17.0877C81.9329 20.6604 80.5624 24.1484 77.9806 26.7359C75.369 29.3532 71.6602 30.9052 67.1786 30.8119C64.0208 30.7461 60.5783 29.4196 57.4754 26.0951C56.2229 24.7531 54.9962 22.9205 53.8218 21.1164C53.82 21.1191 53.8183 21.1217 53.8165 21.1243C52.5435 22.9975 51.2106 24.943 49.9392 26.1855C46.5542 29.4936 43.235 30.8125 39.7358 30.8125C34.6768 30.8125 31.0306 28.6561 28.7064 25.7849C26.463 23.0136 25.4858 19.6296 25.4858 17C25.4858 14.2572 26.4228 10.8069 28.6722 7.98788C31.0012 5.06911 34.6597 2.93747 39.7358 2.93747C46.142 2.93747 50.6567 7.28805 53.8649 11.4427C53.8865 11.4706 53.908 11.4986 53.9295 11.5266ZM57.2235 16.2558C57.4475 16.5992 57.6662 16.9367 57.8795 17.2659C57.9893 17.4353 58.0977 17.6026 58.2047 17.7674C59.5137 19.7835 60.554 21.3328 61.4962 22.3424C63.6434 24.6429 65.7016 25.2799 67.2931 25.3131C70.3772 25.3773 72.6059 24.3356 74.0873 22.851C75.5984 21.3366 76.4763 19.2145 76.5497 16.9122C76.6881 12.5776 73.2468 8.59593 68.2111 8.43608C64.7346 8.32573 61.456 10.9102 58.1631 15.0389C57.8435 15.4397 57.5305 15.8461 57.2235 16.2558ZM50.5299 16.1772C50.198 15.7129 49.8593 15.2543 49.5118 14.8043C46.5069 10.913 43.4234 8.43747 39.7358 8.43747C36.3745 8.43747 34.2829 9.77457 32.9713 11.4183C31.5801 13.1618 30.9858 15.3678 30.9858 17C30.9858 18.4328 31.5693 20.5801 32.9813 22.3244C34.3124 23.9689 36.4162 25.3125 39.7358 25.3125C41.5914 25.3125 43.5796 24.7103 46.095 22.252C46.8861 21.4789 47.8891 20.0611 49.2675 18.0329C49.3797 17.8678 49.4937 17.6997 49.6095 17.5291C49.9049 17.0936 50.2119 16.6411 50.5299 16.1772Z" fill="url(#paint1_linear_1584_973)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M56.6108 16.875C56.6108 26.1948 49.0556 33.75 39.7358 33.75C30.416 33.75 22.8608 26.1948 22.8608 16.875C22.8608 7.55519 30.416 0 39.7358 0C49.0556 0 56.6108 7.55519 56.6108 16.875ZM39.7358 32.75C48.5034 32.75 55.6108 25.6425 55.6108 16.875C55.6108 8.10748 48.5034 1 39.7358 1C30.9683 1 23.8608 8.10748 23.8608 16.875C23.8608 25.6425 30.9683 32.75 39.7358 32.75Z" fill="url(#paint2_linear_1584_973)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M51.1108 17C51.1108 26.3888 58.722 34 68.1108 34C77.4997 34 85.1108 26.3888 85.1108 17C85.1108 7.61116 77.4997 0 68.1108 0C58.722 0 51.1108 7.61116 51.1108 17ZM68.1108 33C59.2743 33 52.1108 25.8366 52.1108 17C52.1108 8.16344 59.2743 1 68.1108 1C76.9474 1 84.1108 8.16344 84.1108 17C84.1108 25.8366 76.9474 33 68.1108 33Z" fill="url(#paint3_linear_1584_973)"/>
    <defs>
      <linearGradient id="paint0_linear_1584_973" x1="28.2358" y1="16.8743" x2="79.3038" y2="16.8743" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6300E0" stop-opacity="0"/>
      <stop offset="1" stop-color="#6300E0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1584_973" x1="36.3608" y1="16.8743" x2="75.3608" y2="16.8743" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0C0C0C"/>
      <stop offset="1" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1584_973" x1="23.8608" y1="17.25" x2="51.9233" y2="17.1875" gradientUnits="userSpaceOnUse">
      <stop/>
      <stop offset="1" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1584_973" x1="84.1108" y1="18.2598" x2="56.2046" y2="18.4062" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2A0060"/>
      <stop offset="1" stop-color="#2A0060" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
  <span class="trademark">&trade;</span>
</template>