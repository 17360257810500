<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.25 24.4923C.25 11.3244 10.8841.65129 24 .6513c13.1159 0 23.75 10.6731 23.75 23.841 0 13.1679-10.6341 23.841-23.75 23.841S.25 37.6602.25 24.4923Z" fill="#fff" stroke="#E61E57" stroke-width=".5"/>
    <mask id="a" fill="#fff">
      <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093 0 30.8817 0 24.4923c0-6.3893 2.52856-12.517 7.02943-17.0349C11.5303 2.93946 17.6348.4013 24 .4013v48.182Z"/>
    </mask>
    <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093 0 30.8817 0 24.4923c0-6.3893 2.52856-12.517 7.02943-17.0349C11.5303 2.93946 17.6348.4013 24 .4013v48.182Z" fill="#E61E57" stroke="#E61E57" stroke-width="1.5" mask="url(#a)"/>
    <circle cx="6.53366" cy="30.4586" r="2.03366" fill="#fff"/>
    <path d="M16.5225 22.5034H8.20844c-.98692 0-3.70844.9427-3.70844 3.9932h8.3141c1.0766 0 3.6486-.9727 3.7084-3.9932ZM20.5303 16.4923H8.20869c-.98693 0-3.70845.9426-3.70845 3.9932H16.7919c1.0767 0 3.6786-.9727 3.7384-3.9932Z" fill="#fff"/>
    <path d="M37.2497 16.4923h-3.3718l-2.465 8.5769-2.0244.6282-.3385 1.4167 1.8456-.5641-1.7689 5.9423h10.7285l.8558-2.8077h-7.344l1.2772-4.2692 2.2096-.6795.1469-1.391-1.8456.5641 2.0946-7.4167Z" fill="#E61E57"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>