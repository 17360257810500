<template>
  <div class="sub-header">
    <Button class="control-btn" 
      :eventName="'edit'" 
      @clicked="clicked($event)"
    >
      <Edit />
    </Button>
    <h1>Minted FAssets</h1>
  </div>
  <div class="minted-assets">
    <div class="grid">
      <div>Asset</div>
      <div>Price</div>
      <div>Quantity</div>
      <div>Supply&nbsp;%</div>
      <div class="desktop">Collateral</div>
      <div class="last-column">TVL</div>
      <SimulateFassetsThumbnail
        v-for="coin in coinsMinted"
        :constant="constant" 
        :coin="coin" 
        :key="coin.id"
        :configs="configs"
        :collateralFAssetIsActive="collateralFAssetIsActive"
        :networkCollateralAllocation="networkCollateralAllocation"
      />
    </div>
    <div 
      v-if="coinsMinted.length === 0"
      class="container"
    >
      <p class="color-orange">No FAssets have been minted. Go mint some FAssets!</p>
    </div>
  </div>
</template>
<script setup>
  import SimulateFassetsThumbnail from './SimulateFassetsThumbnail.vue'
  import Button from '../_generics/Button.vue'
  import Edit from '../_icons/Edit.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coinsMinted: {
      type: Array,
      default: () => {}
    },
    eventName: {
      type: String,
      default: 0
    },
    configs: {
      type: Object,
      default: () => {}
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const constant = props.constant
  const emit = defineEmits(['clicked'])

  const clicked = (event) => {
    emit('clicked', props.eventName)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .sub-header {
    h1 {
      color: $WHITE;
      padding: 0;
    }
  }

  .minted-assets {
    margin: 0 5px;

    .grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      margin: 0 auto 150px auto;
      text-align: center;
      color: $GREY-XLIGHT;
      max-width: $MAX-CONTENT-WIDTH;

      @include breakpoint(sm) {
        grid-template-columns: repeat(6, 1fr);
      }
      @include breakpoint(md) {
        grid-template-columns: repeat(6, 1fr);
      }
      @include breakpoint(lg) {
        grid-template-columns: repeat(6, 1fr);
      }
      @include breakpoint(xl) {
        grid-template-columns: repeat(6, 1fr);
      }
      @include breakpoint(xxl) {
        grid-template-columns: repeat(6, 1fr);
      }
      @include breakpoint(xxxl) {
        grid-template-columns: repeat(6, 1fr);
      }

      div {
        font-size: $FONT-XSMALL;
        margin-bottom: 7px;
        
        @include breakpoint(sm) {
          font-size: $FONT-XSMALL;
        }
        @include breakpoint(md) {
          font-size: $FONT-SMALL;
        }
        @include breakpoint(lg) {
          font-size: $FONT-DEFAULT-SIZE;
        }
        @include breakpoint(xl) {
          font-size: $FONT-DEFAULT-SIZE;
        }
        @include breakpoint(xxl) {
          font-size: $FONT-DEFAULT-SIZE;
        }
        @include breakpoint(xxxl) {
          font-size: $FONT-DEFAULT-SIZE;
        }

        &:first-child {
          text-align: left;
          margin-left: 10px;

          @include breakpoint(sm) {
            margin-left: 15px;
          }
          @include breakpoint(md) {
            margin-left: 20px;
          }
          @include breakpoint(lg) {
            margin-left: 20px;
          }
          @include breakpoint(xl) {
            margin-left: 20px;
          }
          @include breakpoint(xxl) {
            margin-left: 20px;
          }
          @include breakpoint(xxxl) {
            margin-left: 20px;
          }
        }

        &.last-column {
          text-align: right;
          margin-right: 10px;

          @include breakpoint(sm) {
            margin-right: 20px;
          }
          @include breakpoint(md) {
            margin-right: 20px;
          }
          @include breakpoint(lg) {
            margin-right: 20px;
          }
          @include breakpoint(xl) {
            margin-right: 20px;
          }
          @include breakpoint(xxl) {
            margin-right: 20px;
          }
          @include breakpoint(xxxl) {
            margin-right: 20px;
          }
        }

        &.desktop {
          @include breakpoint(sm) {
            display: block;
          }
          @include breakpoint(md) {
            display: block;
          }
        }
      }
    }

    .container {
      height: 100%;
      margin-top: -150px;
      background-color: $WHITE-LIGHT;
      padding: 15px 0px;

      p {
        font-size: $FONT-XSMALL;
      }
    }
  }
</style>