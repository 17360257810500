<template>
  <svg 
    :width="fWidth" 
    :height="fHeight"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g 
      :opacity="opacity" 
      :fill="`#${color}`"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.167 11.6715c0 3.6148-2.9173 6.5323-6.50001 6.5323-3.58272 0-6.5-2.9175-6.5-6.5323 0-3.61476 2.91728-6.5322 6.5-6.5322.86731 0 1.69561.17097 2.45301.48141l-.0072-1.22246 1.9815-.01164C11.8048 3.59532 10.289 3.1393 8.66699 3.1393c-4.69442 0-8.499998 3.82001-8.499998 8.5322 0 4.7123 3.805578 8.5323 8.499998 8.5323 4.69441 0 8.50001-3.82 8.50001-8.5323 0-.1664-.0048-.3317-.0141-.4958l-2.0035.0118c.0117.1598.0176.3212.0176.484Z"/>
      <path d="m14.3356 6.65937-5.31984.79828 3.52684-5.88884 1.793 5.09056Z"/>
    </g>
  </svg>
</template>
<script setup>
  import { ref, watch } from 'vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 18
    },
    height: {
      type: Number,
      default: 21
    },
    size: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: 'FFFFFF'
    },
    opacity: {
      type: Number,
      default: 0.5
    }
  })
  const constant = props.constant
  const viewBox = `0 0 ${props.width} ${props.height}`
  const fill = `#${props.color}`

  const fWidth = ref(0)
  fWidth.value = props.width * props.size

  const fHeight = ref(0)
  fHeight.value = props.height * props.size
</script>