<template>
  <Button
    v-if="showCookieAccess"
    class="cookie-btn"
    :eventName="constant.COOKIE_DISCLOSURE"
    @clicked="clicked($event)"
  >
    <Cookie />
  </Button>
</template>
<script setup>
  import { computed } from 'vue'
  import Button from '../../_generics/Button.vue'
  import Cookie from '../../_icons/Cookie.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    cookies: {
      type: Object,
      default: () => {}
    },
    cookieDisclosureScreen: {
      type: Object,
      default: () => {}
    }
  })

  const showCookieAccess = computed(() => {
    return !props.cookieDisclosureScreen.isActive && !props.cookies.agreed
  })

  const emit = defineEmits([
    'activateScreenSubNav'
  ])

  const clicked = (event) => {
    emit('activateScreenSubNav', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/_variables.scss';
  @import '../../../assets/scss/mixins/layout.scss';
    
    .cookie-btn {
      position: relative;
      height: 30px;
      width: 30px;
      margin-right: 15px;
      padding: 0;
      bottom: 0;
      border-radius: 25px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

   .cookie-enter-from {
    opacity: 0;
    bottom: -200px;

    @include breakpoint(sm) {
      bottom: -195px;
    }
    @include breakpoint(md) {
      bottom: -170px;
    }
    @include breakpoint(lg) {
      bottom: -140px;
    }
    @include breakpoint(xl) {
      bottom: -140px;
    }
    @include breakpoint(xxl) {
      bottom: -125px;
    }
    @include breakpoint(xxxl) {
      bottom: -125px;
    }
  }

  .cookies-from-to {
    opacity: 0;
  }
  .cookies-enter-to {
    opacity: 0.8;
  }
  .cookies-enter-active {
    transition: all 0.15s ease-out;
    transition-delay: 1s;
  }
  
  .cookies-leave-from {
    opacity: 0.8;
  }
  .cookies-leave-to {
    opacity: 0;
  }
  .cookies-leave-active{
    transition: all .15s ease-in;
  }
</style>