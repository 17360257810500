<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.43597 14.7681C8.62286 13.32 9.35862 12.6205 9.35862 12.6205C9.35862 12.6205 10.1449 11.788 10.8409 12.4609C11.5874 13.498 10.7066 14.3386 10.7066 14.3386L8.73028 16.5599C8.73028 16.5599 7.76734 17.4803 7.73672 19.0941C7.70611 20.708 8.68193 21.7879 8.68193 21.7879C8.68193 21.7879 9.7829 23.0581 11.4961 22.6961C12.4521 22.5304 13.0643 21.7879 13.0643 21.7879L15.2233 19.3273C15.2233 19.3273 16.147 18.3455 16.8666 19.3825C17.2372 20.1864 16.9418 20.5545 16.5981 21.0332C16.2544 21.5118 15.1696 22.6899 14.6755 23.2729C14.1814 23.8558 12.9302 25.1004 10.9429 25.1383C9.00954 25.1751 7.48967 23.684 7.48967 23.684C7.48967 23.684 5.59387 21.9904 5.76036 19.0634C5.76036 17.3208 6.24907 16.2163 7.43597 14.7681Z" fill="#5300BB" stroke="#5300BB" stroke-width="0.25"/>
    <path d="M21.689 15.2302C20.5021 16.6783 19.7664 17.3779 19.7664 17.3779C19.7664 17.3779 18.9801 18.2103 18.2841 17.5374C17.5376 16.5004 18.4184 15.6597 18.4184 15.6597L20.3947 13.4384C20.3947 13.4384 21.3577 12.518 21.3883 10.9042C21.4189 9.29032 20.4431 8.21041 20.4431 8.21041C20.4431 8.21041 19.3421 6.94021 17.6289 7.30226C16.6729 7.46795 16.0607 8.21041 16.0607 8.21041L13.9017 10.671C13.9017 10.671 12.978 11.6528 12.2584 10.6158C11.8878 9.81194 12.1832 9.44378 12.5269 8.96514C12.8706 8.48651 13.9554 7.30838 14.4495 6.72544C14.9436 6.1425 16.1948 4.89789 18.1821 4.86004C20.1155 4.82323 21.6353 6.31432 21.6353 6.31432C21.6353 6.31432 23.5311 8.0079 23.3646 10.9349C23.3646 12.6775 22.8759 13.782 21.689 15.2302Z" fill="#5300BB" stroke="#5300BB" stroke-width="0.25"/>
    <path d="M11.3508 17.5356C11.3508 17.1428 11.5281 16.8851 11.6301 16.7501C11.7321 16.6151 15.8138 11.9885 16.0339 11.7185C16.2541 11.4485 16.5624 11.327 16.8073 11.3564C17.1134 11.3932 17.3175 11.5528 17.441 11.6816C17.5646 11.8105 17.774 12.0314 17.774 12.5284C17.774 12.8721 17.6236 13.1175 17.441 13.363L13.4131 17.9712C13.2762 18.143 12.9354 18.6769 12.4061 18.6769C12.0329 18.6769 11.8422 18.5327 11.6704 18.3486C11.4985 18.1645 11.3508 17.9283 11.3508 17.5356Z" fill="#5300BB" stroke="#5300BB" stroke-width="0.15"/>
  </svg>
</template>
<script setup>

</script>
<style lang="scss" scoped>
  
</style>