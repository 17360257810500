import { defineStore } from 'pinia'
//import coins from '../data/coins.json'
import Constant from '../global/Constant.js'

export const useCoinStore = defineStore('coinStore', {
  state: () => ({
    coins: [
      {
        id: 1,
        keys:['c1mdp', 'c1mq'],
        name: "Bitcoin",
        ticker: "BTC",
        price: 27606.95,
        modifiedPrice: 0,
        mintQuantity: 0,
        circulatingSupply: 19190000,
        fasset: true
      },
      {
        id: 2,
        keys:['c2mdp', 'c2mq'],
        name: "Litecoin",
        ticker: "LTC",
        price: 63.11,
        modifiedPrice: 0,
        mintQuantity: 0,
        circulatingSupply: 73545958,
        fasset: true
      },
      {
        id: 3,
        keys:['c3mdp', 'c3mq'], 
        name: "XRP",
        ticker: "XRP",
        price: 0.5044,
        modifiedPrice: 0,
        mintQuantity: 0,
        circulatingSupply: 52787284454,
        fasset: true
      },
      {
        id: 4,
        keys:['c4mdp', 'c4mq'],
        name: "Dogecoin",
        ticker: "DOGE",
        price: 0.05889,
        modifiedPrice: 0,
        mintQuantity: 0,
        circulatingSupply: 140607466384,
        fasset: true
      },
      {
        id: 5,
        keys:['c5mdp', 'c5mq'], 
        name: "Algorand",
        ticker: "ALGO",
        price: 0.09686,
        modifiedPrice: 0,
        mintQuantity: 0,
        circulatingSupply: 7825706023,
        fasset: true
      },
      // {
      //   id: 6, 
      //   name: "Solana",
      //   ticker: "SOL",
      //   price: 21.28,
      //   modifiedPrice: 0,
      //   mintQuantity: 0,
      //   circulatingSupply: 415996007,
      //   fasset: false
      // } 
    ],
    totalCollateral: 0,
    configurationSettings: {},
    error: {
      msg: '',
      val: false
    }
  }),
  getters: {
    getCoins() {
      return this.coins
    },
    getCoinsMinted() {
      const coinsMinted = this.getCoins.filter(c => c.mintQuantity > 0)
      return coinsMinted
    },
    getTotalCollateral() {
      return this.totalCollateral
    },
    isMinted() {
      return this.getCoinsMinted && this.getCoinsMinted.length > 0 ? true : false
    },
    mintCount() {
      return this.coins.reduce((previousValue, currentValue) => {
        return currentValue.mintQuantity > 0 ? previousValue + 1 : previousValue
      }, 0)
    },
  },
  actions: {
    async fetchCoinData() {
      //console.log('API - fetchCoinData')
      Promise.all([
        await fetch(Constant.COIN_DATA_URL).then(res => res.json()),
      ]).then((data) => {
        if(data && data[0].coins) {
          this.updateCoinData(data[0].coins)
        }
      }).catch((e) => {
        console.log('We had an issue fetching COIN data ', e)
        this.error.val = true
        this.error.msg = 'The Coin pricing API is down!'
      })
    },
    getCoin(id) {
      return this.coins.find(c => c.id == id)
    },
    updateCoinData(priceFeed) {
      this.coins.forEach((coin) => {
        const token = priceFeed.find(token => coin.ticker === token.ticker)
          if(token && token.cSupply) {
            coin.circulatingSupply = parseFloat(token.cSupply)
            coin.price = parseFloat(token.price.toFixed(3))
          }
      })
      this.calTotalCollateral()

      //Pricing API Call
      setTimeout(this.fetchCoinData, Constant.FETCH_PRICE_DURATION)
    },
    resetMintedCoins() {
      this.coins.forEach(c => {
        c.modifiedPrice = 0
        c.mintQuantity = 0
      })
      this.totalCollateral = 0
    },
    resetModifiedCoinPrices() {
      this.coins.forEach(c => {
        c.modifiedPrice = 0
      })
    },
    mintCoins(coin, amount) {
      coin.mintQuantity = amount
      this.calTotalCollateral()
    },
    updateMintQuantity(mcoin) {
      const coin = this.getCoin(mcoin.coinId)
      let mintQuantity = 0

      ///Preset or Manual Input
      mintQuantity = mcoin.inputMintAmount != null ? 
        mcoin.inputMintAmount : 
        this.calPresetMintQuantity(parseFloat(coin.circulatingSupply), mcoin)

      this.mintCoins(coin, mintQuantity)
    },
    throttleMinMintPrice() {
      this.coins.forEach(c => {
        if(c.modifiedPrice > 0) {
          let newModPrice = c.price * this.getConfigsSettingById(Constant.SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID).set
          
          //Higher Max Price
          if(newModPrice > c.modifiedPrice) {
            c.modifiedPrice = newModPrice
          }
        }
      })
      this.calTotalCollateral()
    },
    throttleMaxMintPrice() {
      this.coins.forEach(c => {
        if(c.modifiedPrice > 0 ) {
          let newModPrice = c.price * this.getConfigsSettingById(Constant.SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID).set
          
          //Lower Max Price
          if(c.modifiedPrice > newModPrice) {
            c.modifiedPrice = newModPrice
          }

        }
      })
      this.calTotalCollateral()
    },
    throttleAllMaxMintQuantities() {
      this.coins.forEach(c => {
        let maxMintTokenQuantity = c.circulatingSupply * this.getConfigsSettingById(Constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID).set
        
        if(c.mintQuantity > maxMintTokenQuantity) {
          c.mintQuantity = maxMintTokenQuantity
        }
      })

      this.calTotalCollateral()
    },
    calPresetMintQuantity(supply, mcoin) {
      let value = 0

      switch(mcoin.directive) {
        case Constant.RESET : value = 0
        break
        case Constant.ONE_HUNDRETH : value = supply * Constant.ONE_HUNDRETH_NUM
        break
        case Constant.ONE_TENTH : value = supply * Constant.ONE_TENTH_NUM
        break
        case Constant.ONE : value = supply * Constant.ONE_NUM
        break
        case Constant.MAX : value = supply * this.getConfigsSettingById(Constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID).set
        break 
        default: console.log('Directive Not Found')
      }
      return value
    },
    updateModifiedCoinPrice(coin) {
      const mCoin = this.coins.find(c => c.id === coin.id)
      mCoin.modifiedPrice = coin.modifiedPrice
      this.calTotalCollateral()
    },
    calTotalCollateral() {
      this.totalCollateral = 0

      this.coins.forEach(c => {
        const cPrice = c.modifiedPrice > 0 ? c.modifiedPrice : c.price
        this.totalCollateral += (c.mintQuantity * cPrice * this.getConfigsSettingById(Constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set)
      })
    },
    setConfigurationSettings(configs) {
      this.configurationSettings = configs
    },
    getConfigsSettingById(settingId) {
      const setting = this.configurationSettings.settings.find((setting) => setting.id === settingId)
      return setting
    },
    throttleMaxMintAmount(coinId, cAmount){
      const mCoin = this.coins.find(c => c.id === coinId)
      const rate = this.getConfigsSettingById(Constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID).set

      const maxAmount = mCoin.circulatingSupply * rate
      return cAmount <= maxAmount ? cAmount : maxAmount
    },

    isQueryMinted(qData) {
      let quantity = 0
      const len = this.coins.length

      for(let i = 0; i < len; ++i) {
        this.coins[i].keys.forEach(key => {
          if((key.includes('mq')) && qData[key]) {
            quantity += parseFloat(qData[key])
          }
        })
      }

      return quantity > 0 ? true : false
    },

    getQueryParams() {
      const len = this.coins.length
      let queryParams = ''

      for(let i = 0; i < len; ++i) {
        let coin = this.coins[i]

        if(coin.modifiedPrice > 0) {
          queryParams += `&c${i + 1}mdp=${coin.modifiedPrice.toFixed(4)}`
        }

        if(coin.mintQuantity > 0) {
          queryParams += `&c${i + 1}mq=${coin.mintQuantity.toFixed(4)}`
        }
      }
      return queryParams
      //= 	%3D
      //& 	%26
    },

    setQueryCoinData(queryParams) {
      const len = this.coins.length

      //Mint Quantities Self
      for(let i = 0; i < len; ++i) {
        let coin = this.coins[i]
        coin.keys.forEach(key => {

          if(queryParams[key]) {
  
            //Modified Price
            if(key.includes('mdp')) { 
              this.updateModifiedCoinPrice({
                id: coin.id,
                modifiedPrice: parseFloat(queryParams[key]),
              })
            } else {
  
              //Mint Quantity
              if(key.includes('mq')) {
                const mintAmount = parseFloat(queryParams[key])

                this.updateMintQuantity({
                  coinId: coin.id,
                  inputMintAmount: this.throttleMaxMintAmount(coin.id, mintAmount),
                  directive: null
                })
              }
            }
          }

        })
        
      }
    }

  }
})