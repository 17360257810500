<template>
  <svg width="20" height="3" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line 
      x1="0.0415039" 
      y1="1.81305" 
      x2="18.2415" 
      y2="1.81305" 
      :stroke="stroke" 
      stroke-width="2"
    />
  </svg>
</template>
<script setup>
  const props = defineProps({
    color: {
      type: String,
      default: 'FFFFFF'
    },
  })

  const stroke = `#${props.color}`
</script>