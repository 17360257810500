<template>
  <svg 
    width="31" 
    height="30" 
    viewBox="0 0 31 30" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.3992 10.4188L14.8611 14.8344C13.8639 11.7726 12.3163 7.02135 11.5895 4.79206L18.3992 10.4188Z" stroke="#E21D4E" stroke-width="0.75"/>
    <path d="M10.0273 25.6222L13.7885 18.9185L13.0809 24.7344L10.0273 25.6222Z" stroke="#E21D4E" stroke-width="0.75"/>
    <path d="M20.6969 17.3585L14.7173 21.0438L15.231 16.6849L20.6969 17.3585Z" stroke="#E21D4E" stroke-width="0.75"/>
    <path d="M15.8063 15.5856L19.0712 11.4868L21.3043 16.2358L15.8063 15.5856Z" stroke="#E21D4E" stroke-width="0.75"/>
    <path d="M22.2394 15.5165L21.0472 13.0241L23.4976 11.9368L22.2394 15.5165Z" stroke="#E21D4E" stroke-width="0.75"/>
    <path d="M24.817 14.703L24.0312 14.0024L24.396 12.9653L24.817 14.703Z" stroke="#E21D4E" stroke-width="0.75"/>
  </svg>
</template>