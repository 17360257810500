<template>
  <FAlgoIcon 
    v-if="coin.ticker === constant.ALGO" 
    :width="width"
    :height="height"
    :size="size"
  />
  <FBtcIcon 
    v-else-if="coin.ticker === constant.BTC" 
    :width="width"
    :height="height"
    :size="size"
    />
  <FDogeIcon 
    v-else-if="coin.ticker === constant.DOGE" 
    :width="width"
    :height="height"
    :size="size"
  />
  <FLtcIcon 
    v-else-if="coin.ticker === constant.LTC" 
    :width="width"
    :height="height"
    :size="size"
  />
  <FXrpIcon 
    v-else 
    :width="width"
    :height="height"
    :size="size"
  />
</template>
<script setup>
  import FAlgoIcon from './FAlgoIcon.vue'
  import FBtcIcon from './FBtcIcon.vue'
  import FDogeIcon from './FDogeIcon.vue'
  import FLtcIcon from './FLtcIcon.vue'
  import FXrpIcon from './FXrplIcon.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })

  const constant = props.constant
  const coin = props.coin
  const size = props.size
  const width = props.width
  const height = props.height
</script>
<style lang="scss" scoped>
  
</style>