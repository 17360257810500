<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24.0002" cy="24.0004" r="23.1808" transform="rotate(.166 24.0002 24.0004)" fill="#2C4F8B" stroke="#fff" stroke-width="1.5"/>
    <path d="M29.175 7.34125h-6.6308L17.6966 24.1444l-3.981 1.2307-.6656 2.7754 3.6294-1.1052-3.4787 11.6417h21.0981l1.6829-5.5006H21.5395l2.5117-8.3639 4.3452-1.3312.2888-2.7251-3.6293 1.1051L29.175 7.34125Z" fill="#fff"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>