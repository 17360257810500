<template>
  <svg
    v-if="stroke"
    :width="sWidth" 
    :height="sHeight"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.0192 8.12539L6.248 12.832C5.18213 9.55938 3.58592 4.65905 2.76083 2.12782L10.0192 8.12539Z" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M4.89245 17.9466L4.16649 23.9126L1.03418 24.8234L4.89245 17.9466Z" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M12.4343 15.8584L6.16098 19.7247L6.69988 15.1516L12.4343 15.8584Z" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M7.42456 13.7732L10.8637 9.45569L13.216 14.4581L7.42456 13.7732Z" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M14.3811 13.542L13.1855 11.0425L15.6429 9.95201L14.3811 13.542Z" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M17.1042 12.6181L16.4775 12.0594L16.7684 11.2322L17.1042 12.6181Z" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M6.05929 13.8671C4.64773 9.53301 1.80989 0.820828 1.75098 0.644775L10.7174 8.05367L6.05929 13.8671Z" :fill="fill" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`" />
    <path d="M4.62297 24.3006L5.6944 15.4954L0 25.6448L4.62297 24.3006Z" :fill="fill" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M5.54346 20.6926L6.26214 14.5939L13.9097 15.5364L5.54346 20.6926Z" :fill="fill" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M10.9738 8.51492L14.0517 15.0604L6.47383 14.1643L10.9738 8.51492Z" :fill="fill" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M12.5124 10.7941L16.4986 9.02529L14.4518 14.8486L12.5124 10.7941Z" :fill="fill" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
    <path d="M15.8946 12.2096L16.8569 9.4738L17.9674 14.0575L15.8946 12.2096Z" :fill="fill" stroke-width="1%" :stroke="`#${constant.GREY_LIGHT_STROKE}`"/>
  </svg>
  <svg
    v-else
    :width="sWidth" 
    :height="sHeight"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.0192 8.12539L6.248 12.832C5.18213 9.55938 3.58592 4.65905 2.76083 2.12782L10.0192 8.12539Z" :stroke="fill"/>
    <path d="M4.89245 17.9466L4.16649 23.9126L1.03418 24.8234L4.89245 17.9466Z" :stroke="fill"/>
    <path d="M12.4343 15.8584L6.16098 19.7247L6.69988 15.1516L12.4343 15.8584Z" :stroke="fill"/>
    <path d="M7.42456 13.7732L10.8637 9.45569L13.216 14.4581L7.42456 13.7732Z" :stroke="fill"/>
    <path d="M14.3811 13.542L13.1855 11.0425L15.6429 9.95201L14.3811 13.542Z" :stroke="fill"/>
    <path d="M17.1042 12.6181L16.4775 12.0594L16.7684 11.2322L17.1042 12.6181Z" :stroke="fill"/>
    <path d="M6.05929 13.8671C4.64773 9.53301 1.80989 0.820828 1.75098 0.644775L10.7174 8.05367L6.05929 13.8671Z" :fill="fill"/>
    <path d="M4.62297 24.3006L5.6944 15.4954L0 25.6448L4.62297 24.3006Z" :fill="fill"/>
    <path d="M5.54346 20.6926L6.26214 14.5939L13.9097 15.5364L5.54346 20.6926Z" :fill="fill"/>
    <path d="M10.9738 8.51492L14.0517 15.0604L6.47383 14.1643L10.9738 8.51492Z" :fill="fill"/>
    <path d="M12.5124 10.7941L16.4986 9.02529L14.4518 14.8486L12.5124 10.7941Z" :fill="fill"/>
    <path d="M15.8946 12.2096L16.8569 9.4738L17.9674 14.0575L15.8946 12.2096Z" :fill="fill"/>
  </svg>
</template>
<script setup>
  import { ref, watch } from 'vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 18
    },
    height: {
      type: Number,
      default: 26
    },
    size: {
      type: Number,
      default: 1.0
    },
    color: {
      type: String,
      default: '000000'
    },
    stroke: {
      type: Boolean,
      default: false
    }
  })
  const constant = props.constant
  const viewBox = `0 0 ${props.width} ${props.height}`
  const fill = `#${props.color}`

  const sWidth = ref(0)
  sWidth.value = props.width * props.size

  const sHeight = ref(0)
  sHeight.value = props.height * props.size
  
  watch(() => [props.size], (newValue) => {
    sWidth.value = props.width * newValue[0]
    sHeight.value = props.height * newValue[0]
  })
</script>