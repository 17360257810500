<template>
  <div class="form-box">
    <div 
      class="button-box"
      :style="containerWidth"
    >
      <div 
        class="btn-bg"
        :style="[moveButtonTo, buttonWidth]"
      ></div>
      <div>
        <Button
          class="toggle-btn txt-bold"
          :class="{'font-color-change' : !toggleButtons[0].isActive}"
          :style="[buttonWidth]"
          :eventName="toggleButtons[0].eventName" 
          @clicked="clicked($event)"
        >
          {{ toggleButtons[0].name }}
        </Button>
        <Button
          class="toggle-btn txt-bold"
          :class="{'font-color-change' : !toggleButtons[1].isActive}"
          :style="[buttonWidth]"
          :eventName="toggleButtons[1].eventName" 
          @clicked="clicked($event)"
        >
          {{ toggleButtons[1].name }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import Button from './Button.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    containerWidth: {
      type: Number,
      default: 236
    },
    btnWidth: {
      type: Number,
      default: 116
    },
    toggleButtons: {
      type: Array,
      default: () => []
    },
  })
  const emit = defineEmits(['btnToggleClicked'])

  const constant = props.constant

  // const multipleToggleButtons = computed(() => {
  //   return props.toggleButtons.length > 1
  // })

  const containerWidth = computed(() => {
    return `width: ${props.containerWidth}px;`
  })
  const buttonWidth = computed(() => {
    return `width: ${props.btnWidth}px;`
  })
  const moveButtonTo = computed(() => {
   return props.toggleButtons[0].isActive ? 
    `left: ${0}px;` : 
    `left: ${props.btnWidth}px;`
  })

  const clicked = (event) => {
    emit('btnToggleClicked', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .button-box {
    position: relative;
    margin: 10px auto 5px auto;
    height: 50px;
    border-radius: 30px;
    background-color: $WHITE;
    border: 2px solid $WHITE;

    .btn-bg {
      position: absolute;
      background-color: $PURPLE;
      height: 100%;
      left: 0;
      right: 0;
      border-radius: 30px;
      transition: .15s;
    }

    .toggle-btn {
      position: relative;
      font-size: $FONT-SMALL;
      background-color: transparent;
      text-align: center;
      cursor: pointer;
      padding: 0;
      border: 0;
      outline: none;
      color: $WHITE;
      height: 46px;
    }

    .font-color-change {
      color: $GREY;
      transition: color 0.15s ease-in;
    }
  }
</style>