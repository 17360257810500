<template>
  <div class="mint-container">
    <h2>Mintable Assets</h2>
    <ul>
      <li
        class="accordion-item" 
        v-for="coin in coins" 
        :key="coin.id"
      >
          <MintAssetThumbnail
          :id="coin.ticker"
          :constant="constant"
          :coin="coin"
          :isActive="accordionScreens[coin.id - 1].isActive"
          @activateAccordion="activateAccordion($event)" 
        />
        <MintAssetControls 
          :isActiveAccordion="accordionScreens[coin.id - 1].isActive"
          :constant="constant"
          :coin="coin"
          :configs="configs"
          :activeNetwork="activeNetwork"
          :collateralFAssetIsActive="collateralFAssetIsActive"
          :networkCollateralAllocation="networkCollateralAllocation"
          @updateMintQuantity="updateMintQuantity($event)"
          @updateModifiedCoinPrice="updateModifiedCoinPrice($event)"
          @throttleAllMaxMintQuantities="throttleAllMaxMintQuantities($event)"
        />
      </li>
    </ul>
  </div>  
</template>
<script setup>
  import { ref, computed, watch } from 'vue'
  import MintAssetThumbnail from './MintAssetThumbnail.vue'
  import MintAssetControls from './MintAssetControls.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coins: {
      type: Array,
      default: () => []
    },
    isMinted: {
      type: Boolean,
      default: false
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    accordionScreens: {
      type: Array,
      default: () => []
    },
    configs: {
      type: Object,
      default: () => {}
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const emit = defineEmits([
    'activateAccordion', 
    'updateMintQuantity',
    'updateModifiedCoinPrice',
  ])
  const constant = props.constant

  const activateAccordion = (event) => {
    emit(constant.ACCORDION_ACTIVATE, event)
  }

  const updateMintQuantity = (event) => {
    emit('updateMintQuantity', event)
  }

  const updateModifiedCoinPrice = (event) => {
    emit('updateModifiedCoinPrice', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .mint-container {
    position: relative;
    flex-direction: column;
    margin: 15px auto 150px auto;
    padding-bottom: 25px;
    width: 97.5%;
    max-width: $MAX-CONTENT-WIDTH;

    h2 {
      font-size: $FONT-DEFAULT-SIZE;
    }
  }
</style>