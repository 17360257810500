<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="11.8409" cy="21.3434" rx="1.90656" ry="1.90656" :fill="fill"/>
    <path d="M21.2054 13.8854H13.411C12.4857 13.8854 9.93433 14.7692 9.93433 17.629H17.7288C18.7381 17.629 21.1494 16.7172 21.2054 13.8854Z" :fill="fill"/>
    <path d="M24.9625 8.25H13.411C12.4857 8.25 9.93433 9.13372 9.93433 11.9936H21.4578C22.4671 11.9936 24.9064 11.0817 24.9625 8.25Z" :fill="fill"/>
    <path d="M30.4482 15C30.4482 23.0081 23.9564 29.5 15.9482 29.5C7.94011 29.5 1.44824 23.0081 1.44824 15C1.44824 6.99187 7.94011 0.5 15.9482 0.5C23.9564 0.5 30.4482 6.99187 30.4482 15Z" :stroke="fill"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 31
    },
    height: {
      type: Number,
      default: 30
    },
    size: {
      type: Number,
      default: 1.0
    },
    color: {
      type: String,
      default: '4502A5'
    }
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
  const fill = `#${props.color}`
</script>