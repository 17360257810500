<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24.25" r="23.1319" transform="rotate(-.284 24 24.25)" fill="#AF9126" stroke="#fff" stroke-width="1.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="m14.3888 10.1993 12.8087-.0636c5.0938-.0252 12.3269 2.5106 12.3848 14.1819.0571 11.514-8.0899 14.1679-12.245 14.0071l-12.8087.0636-.0564-11.3745-3.9069.0193-.0265-5.341 3.9069-.0194-.0569-11.4734Zm6.2596 5.4575 4.6982-.0233c2.9674-.0148 7.9155.5047 7.9582 9.1098.0426 8.605-6.2875 8.6364-7.8701 8.6443-1.366.0067-2.3791.0148-3.1099.0206-.9837.0077-1.4558.0115-1.5883.0027l-.0316-6.3796 4.9454-.0246-.0258-5.1927-4.9454.0246-.0307-6.1818Z" fill="#fff"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>