<template>
  <div class="excludes">
    <div class="flex">
      <h2>Remove From Tradable Supply</h2>
      <Button
        v-if="modifiedExcludes"
        class="reset-all"
        :eventName="constant.SETTING_RESET"
        @clicked="clicked($event)"
      >
        Reset All
      </Button>
      <span
        v-else
        class="reset-all-disabled"
      >
        Reset All
      </span>
    </div>
    <div class="container">
      <AppHeaderSettingsExcludesItem
        v-for="exclude in excludes"
        :key="exclude.id"
        :constant="constant"
        :exclude="exclude"
        :activeNetwork="activeNetwork"
        @updateConfiguration="updateConfiguration($event)"
      />
    </div>
  </div>
</template>
<script setup>
  import { computed } from 'vue'
  import Button from '../../../_generics/Button.vue'
  import AppHeaderSettingsExcludesItem from './AppHeaderSettingsExcludesItem.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    excludes: {
      type: Array,
      default: () => []
    }
  })

  const emit = defineEmits([
    'updateConfiguration',
    'resetConfiguration'
  ])
  const constant = props.constant

  const modifiedExcludes = computed(() => {
    let excludesFiltered = []
    excludesFiltered = props.excludes.filter(exclude => !exclude.default)
    const result = excludesFiltered.find((exclude) => exclude.omit)
    return result ? true : false
  })

  const updateConfiguration = (event) => {
    emit('updateConfiguration', event)
  }

  const clicked = (event) => {
    const settings = {
      networkId: props.activeNetwork.id,
      networkName: props.activeNetwork.name,
      type: constant.SETTINGS_EXCLUDES
    }
    emit('resetConfiguration', settings)
  }
</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';

  .flex {
    align-items: flex-end;

    .reset-all, .reset-all-disabled {
      font-size: $FONT-XXSMALL;
      margin: 0 15px 4px 0;
      color: $PURPLE;
    }
    .reset-all-disabled {
      color: $GREY-XLIGHT;
      padding: 0 6px;
      margin-bottom: 5px;
    }
    .reset-all {
      display: block;
    }
  }

  h2 {
    text-align: left;
    margin: 10px 5px 5px 20px;
    font-size: $FONT-XSMALL;
    font-weight: 600;
    color: $BLACK;
  }

  .title-xxsmall {
    color: $BLACK;
    text-align: center;
    padding: 0 0 5px 0;
    margin-left: 10px;
  }

  .container {
    border-top: thin solid $BLACK-XLIGHT;
    border-bottom: thin solid $WHITE;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
</style>