<template>
  <div 
    class="exclude-item border-top border-bottom"
    :class="{'p-modified': exclude.omit}"
  >
    <div class="flex">
      <span class="truncate">{{ exclude.name }}</span>
      <div class="flex mr-20">
        <span class="mr-30">{{ currencyTruncator(exclude.count) }}</span>
        <ToggleSwitch 
          :modelValue="exclude.omit"
          :id="exclude.id"
          :activeNetwork="activeNetwork"
          @updateToggleSwitch="updateConfiguration($event)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
  import ToggleSwitch from '../../../_generics/ToggleSwitch'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    exclude: {
      type: Object,
      default: () => {}
    }
  })

  const emit = defineEmits([
    'updateConfiguration',
    'resetConfiguration'
  ])
  const constant = props.constant
  

  const currencyFormatter = (value) => {
    return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const currencyTruncator = (value) => {
    let newValue = parseInt(value)
    let len = newValue.toString().length
    
    if(len >= 7 && len < 10)
      return newValue = `${currencyFormatter(newValue/1000000)}M`
    else if(len >= 10) 
      return newValue=`${currencyFormatter(newValue/1000000000)}B`
    return newValue = currencyFormatter(newValue)
  }

  const updateConfiguration = (event) => {
    const settings = {
      id: event.id,
      networkName: event.networkName,
      checked: event.checked,
      type: constant.SETTINGS_EXCLUDES
    }
    emit('updateConfiguration', settings)
  }
</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';
  @import '../../../../assets/scss/mixins/layout.scss';

  .exclude-item {
    text-align: left;
    height: 70px;
    width: 100%;

    &.p-modified {
     background-color: $WHITE-OFF;
    }

    .flex {
      color: $GREY;
      font-size: $FONT-SMALL;
      margin-left: 30px;
      height: 100%;

      @include breakpoint(md) {
        margin-left: 40px;
      }
      @include breakpoint(lg) {
        margin-left: 40px;
      }
      @include breakpoint(xl) {
        margin-left: 40px;
      }
      @include breakpoint(xxl) {
        margin-left: 40px;
      }
      @include breakpoint(xxxl) {
        margin-left: 40px;
      }
    }
  }
</style>