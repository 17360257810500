<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.25 24.4923C.25 11.3244 10.8841.65129 24 .65129S47.75 11.3244 47.75 24.4923c0 13.1679-10.6341 23.841-23.75 23.841S.25 37.6602.25 24.4923Z" fill="#fff" stroke="#E61E57" stroke-width=".5"/>
    <mask id="a" fill="#fff">
      <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093 0 30.8817 0 24.4923c0-6.3893 2.52856-12.517 7.02943-17.0349C11.5303 2.93945 17.6348.4013 24 .40129V48.5833Z"/>
    </mask>
    <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093 0 30.8817 0 24.4923c0-6.3893 2.52856-12.517 7.02943-17.0349C11.5303 2.93945 17.6348.4013 24 .40129V48.5833Z" fill="#E61E57" stroke="#E61E57" stroke-width="1.5" mask="url(#a)"/>
    <circle cx="6.53366" cy="30.4586" r="2.03366" fill="#fff"/>
    <path d="M16.5225 22.5034H8.20844c-.98692 0-3.70844.9427-3.70844 3.9932h8.3141c1.0766 0 3.6486-.9727 3.7084-3.9932ZM20.5303 16.4923H8.20869c-.98693 0-3.70845.9426-3.70845 3.9932H16.7919c1.0767 0 3.6786-.9727 3.7384-3.9932Z" fill="#fff"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="m28.5138 28.0135.8168.2311c.6357.1826.9449.0536 1.0753-.2984.1305-.3519 1.2321-5.0241 1.7667-7.3162.1417-.6078.0223-1.0324-.6256-1.1727l-1.3382-.3223.4129-1.774 3.3623.8139.6664-2.7668 1.6372.4159-.6458 2.7329 1.3927.3174.6039-2.7324 1.6077.412-.6206 2.7326c1.4106.4184 3.4719 1.4371 3.3902 3.3583-.0868 2.04-1.3356 2.5972-2.1008 2.8585.0378.0245.0785.0504.1214.0776.6222.3953 1.7155 1.0897 1.6623 2.6074-.0692 1.9771-1.1494 3.4772-2.9599 3.7485-1.374.2059-2.43.0261-3.085-.0854a10.1096 10.1096 0 0 1-.0386-.0066l-.6248 2.7369-1.6116-.3783.6497-2.7624-1.4095-.3215-.6415 2.7413-1.6077-.3994.6457-2.7455-3.2993-.8062.7977-1.8962Zm4.6089 1.1923.9077-3.7343c1.4547.2283 4.4584.9626 4.3347 2.6148-.1171 1.5651-1.7256 1.8559-3.2486 1.5188l-1.9938-.3993Zm1.2672-5.3982.8024-3.3876c1.2445.2054 3.7598.7 3.6198 2.4879-.0673.8602-1.0765 1.6693-2.6466 1.3015-1.5702-.3679-1.7756-.4018-1.7756-.4018Z" fill="#E61E57"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>