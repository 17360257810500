<template>
  <div class="container">
    <h3
      :style="{'color' : itemColor}"
    >
      {{ formatPercentage() }}
    </h3>

    <!-- Mobile -->
    <div 
      class="label flex mobile"
    >
      <slot></slot>
      <span
        class="txt-uppercase txt-bold"
        :class="classes"
      >
        {{ name }}
      </span>
    </div>
    
    <!-- Desktop -->
    <div 
      class="label flex desktop"
      :style="{'background-color' : itemColor}"
    >
      <slot></slot>
      <span
        class="txt-uppercase txt-bold"
        :class="classes"
      >
        {{ name }}
      </span>
    </div>
  </div>
</template>
<script setup>
  import { computed } from 'vue'
  const props = defineProps({
    percentage: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'FFFFFF'
    }
  })

  const itemColor = computed(() => {
    return `#${props.color}`
  })

  const classes = computed(() => {
    if(props.name === 'Stable')
      return 'purple'
  })

  const formatPercentage = () => {
    const percentage = props.percentage * 100
    return `${percentage.toFixed(1)}%`
  }

</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .container {
    height: auto;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    z-index: 1;

    h3 {
      font-size: $FONT-XSMALL;
      margin-top: 5px;

      @include breakpoint(lg) {
        font-size: $FONT-DEFAULT-SIZE;
      }
      @include breakpoint(xl) {
        font-size: $FONT-DEFAULT-SIZE;
      }
      @include breakpoint(xxl) {
        font-size: $FONT-DEFAULT-SIZE;
      }
      @include breakpoint(xxxl) {
        font-size: $FONT-DEFAULT-SIZE;
      }
    }

    .label {
      justify-content: center;
      margin: 0 0;
      min-height: 30px;
      width: 95px;
      background-color: transparent;

      @include breakpoint(lg) {
        background-color: $BLUE;
      }
      @include breakpoint(xl) {
        background-color: $BLUE;
      }
      @include breakpoint(xxl) {
        background-color: $BLUE;
      }
      @include breakpoint(xxxl) {
        background-color: $BLUE;
      }

      &.mobile {
        @include breakpoint(lg) {
          display: none;
        }
        @include breakpoint(xl) {
          display: none;
        }
        @include breakpoint(xxl) {
          display: none;
        }
        @include breakpoint(xxxl) {
          display: none;
        }
      }

      &.desktop {
        display: none;

        @include breakpoint(lg) {
          display: flex;
        }
        @include breakpoint(xl) {
          display: flex;
        }
        @include breakpoint(xxl) {
          display: flex;
        }
        @include breakpoint(xxxl) {
          display: flex;
        }
      }

      span {
        color: $BLACK;
        font-size: $FONT-XXSMALL;
        color: $WHITE;


        &.purple {
          color: $PURPLE;
        }
      }
    }
  }
</style>