<template>
  <div class="collateral-container">
    <h2>Network Collateral Management</h2>
    <!-- <div class="network-collateral-type">
      <p
        :class="{'isSelected': !collateralFAssetIsActive}"
      >
        duo
      </p>
      <ToggleSwitch
        :id="collateralManagementDualIsActive.id"
        :classes="'collateral-type-toggle'"
        :modelValue="collateralFAssetIsActive"
        :activeNetwork="activeNetwork"
        @updateToggleSwitch="updateCollateralType($event)"
      />
      <p
        :class="{'isSelected': collateralFAssetIsActive}"
      >
        trio
      </p>
    </div> -->
    <div class="network-collateral">

      <div class="container">
        <MintCollateralChart 
          :constant="constant"
          :chartData="chartData"
          :configs="configs"
          :activeNetwork="activeNetwork"
          :collateralFAssetIsActive="collateralFAssetIsActive"
          :networkCollateralAllocation="networkCollateralAllocation"
          @updateChartSettings="updateChartSettings($event)"
        />
        <div class="network-collateral-percentage flex">
          <h3>{{requiredCollateralRate()}}<span class="title-xxxsmall">%</span></h3>
          <h4>Network Collateral Rate</h4>
        </div>
      </div>
      <MintCollateralLegend   
        :constant="constant"
        :activeNetwork="activeNetwork"
        :collateralFAssetIsActive="collateralFAssetIsActive"
        :networkCollateralAllocation="networkCollateralAllocation"
      />
      
    </div>
    <InputRangeDualCollateral
      v-if="!activeNetwork.collateralManagement[1].isActive"
      :constant="constant"
      :activeNetwork="activeNetwork"
      :networkCollateralAllocation="networkCollateralAllocation"
      @updateChartSettings="updateChartSettings($event)"
      @updateNetworkCollateralAllocation="updateNetworkCollateralAllocation($event)"
    />
    <InputRangeMultiCollateral
      v-else
      :constant="constant"
      :activeNetwork="activeNetwork"
      :networkCollateralAllocation="networkCollateralAllocation"
      @updateChartSettings="updateChartSettings($event)"
      @updateNetworkCollateralAllocation="updateNetworkCollateralAllocation($event)"
    />
  </div>
</template>
<script setup>
  import MintCollateralChart from './MintCollateralChart.vue'
  import MintCollateralLegend from './MintCollateralLegend.vue'
  import InputRangeDualCollateral from '../_generics/InputRangeDualCollateral.vue'
  import InputRangeMultiCollateral from '../_generics/InputRangeMultiCollateral.vue'
  import ToggleSwitch from '../_generics/ToggleSwitch.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    collateralManagementDualIsActive: {
      type: Object,
      default: () => {}
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const constant = props.constant

  const emit = defineEmits([
    'updateChartSettings',
    'updateCollateralType',
    'updateNetworkCollateralAllocation',
  ])

  const updateChartSettings = (event) => {
    emit('updateChartSettings', event)
  }

  const updateNetworkCollateralAllocation = (event) => {
    emit('updateNetworkCollateralAllocation', event)
  }

  const updateCollateralType = (event) => {
    event.checked = !event.checked
    emit('updateCollateralType', event)
  }

  const calCollateralRate = () => {
    return getConfigsSettingById(constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set
  }

  const requiredCollateralRate = () => {
    return (calCollateralRate() * 100).toFixed(0)
  }

  const getConfigsSettingById = (settingId) => {
    const setting = props.configs.settings.find((setting) => setting.id === settingId)
    return setting
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .collateral-container {
    width: 97.5%;
    margin: 15px auto 150px auto;
    padding-bottom: 25px;
    flex-direction: column;
    max-width: $MAX-CONTENT-WIDTH;

    .network-collateral-type {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: $WHITE;
        font-size: $FONT-XSMALL;
        margin: 10px 15px;

        @include breakpoint(lg) {
          font-size: $FONT-SMALL;
        }
        @include breakpoint(xl) {
          font-size: $FONT-SMALL;
        }
        @include breakpoint(xxl) {
          font-size: $FONT-SMALL;
        }
        @include breakpoint(xxxl) {
          font-size: $FONT-SMALL;
        }

        &.isSelected {
          color: $PURPLE-XLIGHT;
        }
      }
    }

    .network-collateral {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      @include breakpoint(lg) {
        flex-direction: row;
      }
      @include breakpoint(xl) {
        flex-direction: row;
      }
      @include breakpoint(xxl) {
        flex-direction: row;
      }
      @include breakpoint(xxxl) {
        flex-direction: row;
      }

      .container {
        position: relative;
        flex-direction: column;
        height: auto;

        @include breakpoint(lg) {
          flex-direction: row;
        }
        @include breakpoint(xl) {
          flex-direction: row;
        }
        @include breakpoint(xxl) {
          flex-direction: row;
        }
        @include breakpoint(xxxl) {
          flex-direction: row;
        }

        .flex {
          position: absolute;
          flex-direction: column;

          p {
            font-size: $FONT-XXXXSMALL;
          }
        }
        .network-collateral-percentage {

          h3 {
            line-height: 1.25;
            font-size: $FONT-XXXXLARGE;
            margin: 0;
          }

          h4 {
            font-size: $FONT-XXXXSMALL;
            font-weight: 100;

            @include breakpoint(lg) {
              font-size: $FONT-XSMALL;
            }
            @include breakpoint(xl) {
              font-size: $FONT-XSMALL;
            }
            @include breakpoint(xxl) {
              font-size: $FONT-XSMALL;
            }
            @include breakpoint(xxxl) {
              font-size: $FONT-XSMALL;
            }
          }
        }
      }
    }
    
    h2 {
      margin-top: 20px;
    }
  }

  .collateral-type-toggle[type="checkbox"] {
    background-color: $WHITE;

    &::before {
      background-color: $PURPLE;
    }

    &:checked[type="checkbox"] {
      background-color: $WHITE;
    }
  }
</style>