import { defineStore } from 'pinia'
import Constant from '../global/Constant.js'

export const useChartStore = defineStore('chartStore', {
  state: () => ({
    charts: [ 
      {
        id: 1,
        type: 'static',
        target: 'simulate',
        data: {
          labels: [],
          datasets: [{
            data: [100, 0],
            backgroundColor: [
              `rgba(${Constant.GREY_LIGHT_RGB}, 1)`,
              'transparent',
            ],
            borderColor: [
              `rgba(${Constant.GREY_LIGHT_STROKE_RGB}, 1)`, 
              'transparent'
            ],
            borderWidth: '1.5',
            circumference: 360,
            rotation: 180,
          }],
        },
        options: {
          animation: {
            duration: 0,
            enabled: false
          },
          cutout: '60%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            title: {
              display: false,
            },
          }
        },
        gradientColors: [],
      },
      {
        id: 2,
        type: 'dynamic',
        target: 'simulate',
        data: {
          labels: ["Purple", "Grey"],
          datasets: [{
            data: [0, 100],
            backgroundColor: [
              `rgba(${Constant.PURPLE_CHART_MAX_RGB}, .25)`, 
              'transparent'
            ],
            borderColor: [
              `rgba(${Constant.PURPLE_CHART_MAX_RGB}, 1)`, 
              'transparent'
            ],
            borderWidth: '1.5',
            circumference: 360,
            rotation: 180,
          }],
        },
        options: {
          animation: {
            duration: 500,
          },
          cutout: '60%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            title: {
              display: false,
            },
          }
        },
        gradientColors: [
          { 
            start: `rgba(${Constant.PURPLE_CHART_MAX_RGB}, 1)`, 
            end: `rgba(${Constant.WHITE_RGB}, 1)`, 
          },
          {
            start: 'transparent',
            end: 'transparent'
          }
        ],
      },
      {
        id: 3,
        type: 'dynamic',
        target: 'collateral',
        data: {
          labels: ["Stable", "Network"],
          datasets: [{
            data: [65, 35],
            backgroundColor: [
              `rgba(${Constant.WHITE_RGB}, 1.0)`,
              `rgba(${Constant.PURPLE_XLIGHT_RGB}, 1.0)`,
            ],
            borderColor: [
              'transparent'
            ],
            circumference: 360,
            rotation: 243,
          }],
        },
        options: {
          animation: {
            duration: 500,
          },
          hover: {
            mode: null,
          },
          cutout: '60%',
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    ],
  }),
  getters: {
    getChartsData() {
      return this.charts
    }
  },
  actions: {
    setChartSettings(settings) {
      const chart = this.charts.find((chart) => chart.id === settings.id)
      chart.options.animation.duration = 500
      chart.data.datasets[0].data = settings.data
      chart.data.datasets[0].rotation = settings.rotation
      if(chart.target === 'simulate') {
        if(settings.options && settings.options.animation && settings.options.animation.duration) {
          chart.options.animation.duration = settings.options.animation.duration
        }

        if(settings.labels) {
          chart.data.datasets[0].labels = settings.labels
        }

        if(settings.borderColor) {
          chart.data.datasets[0].borderColor = settings.borderColor
        }

        if(settings.gradientColors) {
          chart.gradientColors = settings.gradientColors
        }
      }

      if(chart.target === 'collateral') {
        if(settings.labels) {
          chart.data.datasets[0].labels = settings.labels
        }

        if(settings.backgroundColor) {
          chart.data.datasets[0].backgroundColor = settings.backgroundColor
        }

        if(settings.options && settings.options.plugins) {
          chart.options.plugins = settings.options.plugins
        }
      }
    },
    setChartGradient(settings) {
      const chart = this.charts.find((chart) => chart.id === settings.id)
      chart.data.datasets[0].backgroundColor = settings.gradients
    },
  }
})