<template>
  <div class="price-controller">
    <div class="flex">
      <div 
        class="simulated-price-container"
        :class="{
          'bg-color-red': activeNetwork.name == constant.SONGBIRD_NETWORK, 
          'bg-color-grey': totalCollateralRequired === 0
        }"
      >  
        <ActiveNetworkLogo 
          :constant="constant"
          :activeNetwork="activeNetwork"
          :flrSize="1.8"
          :sgbWidth="18"
          :sgbHeight="26"
          :sgbSize="1.125"
          :color="constant.WHITE"
        />
        <span>
          ${{ numFormatter(coinPrice, 4) }}
        </span>
      </div>
      <h4
        :class="{ 
          'color-red': activeNetwork.name === constant.SONGBIRD_NETWORK,
          'color-grey': totalCollateralRequired === 0
        }"
      >
        Minimum {{ activeNetwork.ticker }} Price
      </h4>
    </div>

    <div class="price-controller-set">
      <SimulatePriceControllerInput
        :id="1"
        :inputType="'static'"
        :modelType="'static'"
        :constant="constant"
        :activeNetwork="activeNetwork"
        :configs="configs"
        :totalCollateralRequired="totalCollateralRequired"
        :sliderFillOpacity="1.0"
        :sliderEmptyOpacity="1.0"
        :collateralFAssetIsActive="collateralFAssetIsActive"
        :networkCollateralAllocation="networkCollateralAllocation"
      />
      <SimulatePriceControllerInput
        :id="2"
        :inputType="'static'"
        :constant="constant"
        :activeNetwork="activeNetwork"
        :configs="configs"
        :totalCollateralRequired="totalCollateralRequired"
        :sliderFillOpacity="0.4"
        :sliderEmptyOpacity="0.0"
        :collateralFAssetIsActive="collateralFAssetIsActive"
        :networkCollateralAllocation="networkCollateralAllocation"
        @updateModifiedNetworkPrice="updateModifiedNetworkPrice($event)"
      />
      <SimulatePriceControllerInput
        :id="3"
        :constant="constant"
        :activeNetwork="activeNetwork"
        :chartData="chartData"
        :configs="configs"
        :totalCollateralRequired="totalCollateralRequired"
        :sliderFillOpacity="0.1"
        :sliderEmptyOpacity="0.0"
        :collateralFAssetIsActive="collateralFAssetIsActive"
        :networkCollateralAllocation="networkCollateralAllocation"
        @updateChartSettings="updateChartSettings($event)"
        @updateModifiedNetworkPrice="updateModifiedNetworkPrice($event)"
        @updateNetworkCollateralPercentage="updateNetworkCollateralPercentage($event)"
      />
      <div class="desktop">
        <p 
          class="labels"
          :class="{ 
            'color-red': activeNetwork.name === constant.SONGBIRD_NETWORK,
            'color-grey': totalCollateralRequired === 0
          }"
        >
        <span class="market-price">Market Price</span><span class="minimum-price">Minimum Price</span><span class="speculative-price">Speculative Price</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import ActiveNetworkLogo from '../_icons/Network/ActiveNetworkLogo.vue'
  import SimulatePriceControllerInput from './SimulatePriceControllerInput.vue'

   const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    totalCollateralRequired: {
      type: Number,
      default: 0
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })
  const constant = props.constant

  const emit = defineEmits([
    'updateChartSettings',
    'updateModifiedNetworkPrice',
    'updateNetworkCollateralPercentage',
  ])

  const updateChartSettings = (event) => {
    emit('updateChartSettings', event)
  }

  const updateModifiedNetworkPrice = (event) => {
    emit('updateModifiedNetworkPrice', event)
  }

  const updateNetworkCollateralPercentage = (event) => {
    emit('updateNetworkCollateralPercentage', event)
  }

  const coinPrice = computed(() => {
    const cPrice = props.activeNetwork.modifiedPrice === 0 ? props.activeNetwork.price : props.activeNetwork.modifiedPrice
    return cPrice
  })

  const numFormatter = (value, decimalCount) => {
    if(value.toFixed(0).length > 3)
      return numberFormatter(value.toFixed(3))

    return value.toFixed(4)
  }
  
  const currencyFormatter = (value) => {
    if( value.toString().length > 9)
      return numberFormatter(value.toFixed(0))
    
    return numberFormatter(value.toFixed(4))
  }

  const numberFormatter = (value) => {
    return value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .price-controller {
    .flex {
      flex-direction: column;
      margin: 20px 0 0 0;
      padding: 0;

      h4{
        color: $PURPLE;
        font-size: $FONT-XXSMALL;
        font-weight: 400;
      }

      .simulated-price-container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $WHITE;
        width: 45%;
        min-width: 220px;
        max-width: 270px;
        font-size: $FONT-XXXLARGE;
        background-color: $PURPLE;
        font-weight: 400;
        border-radius: 50px;
        margin: 0 auto;
        padding: 3px 0;

        svg {
          margin-right: 10px;
        }
      }
    }

    .price-controller-set {
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 70px;
      max-width: $MAX-CONTENT-WIDTH;

      @include breakpoint(lg) {
        padding-bottom: 30px;
      }

      @include breakpoint(xl) {
        padding-bottom: 30px;
      }

      @include breakpoint(xxl) {
        padding-bottom: 30px;
      }

      @include breakpoint(xxxl) {
        padding-bottom: 30px;
      }

      .labels {
        display: flex;
        align-items: flex-start;
        margin: 0;
        padding: 50px 0 0 0;
        font-size: $FONT-XXXSMALL;
        color: $PURPLE;

        .market-price {
          text-align: left;
          width: 10%;
        }
        .minimum-price {
          width: 20%;
          opacity: 0.6;
        }
        .speculative-price {
          width: 70%;
          opacity: 0.4;
        }
      }
    }
  }
</style>