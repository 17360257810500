import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import { createPinia } from 'pinia'
import Constant from './global/Constant.js'
import VueGtag from "vue-gtag"

const app = createApp(App)
const pinia = createPinia()

app.provide('constant', Constant)
app.use(router)
app.use(pinia)
app.use(VueGtag, {
  appName: 'simfasset',
  pageTrackerScreenviewEnabled: true,
  config: { 
    //id: "GTM-58NSC2RQ"
    id: "G-HR1JPDLY9E"
    //id: "G-HR1JPDLY9E, GT-5MCNS3S"
  }
}, router)
app.mount('#app')