<template>
  <div
    id="sub-nav" 
    class="subnav" 
  >
    <div class="logo desktop">
      <Button
        :eventName="constant.GET_STARTED"
        @clicked="logoClicked($event)"
      >
        <SimFassetLogo />
      </Button>
    </div>

    <Button
      class="mobile"
      :eventName="constant.INFORMATION" 
      @clicked="clicked($event)"
    >
      <InformationActive
        v-if="isActiveInfoSubNav"
       />
      <Information
        v-else
       />
    </Button>

    <div class="flex network-price">
      <ActiveNetworkLogo 
        class="small-logo"
        :constant="constant"
        :activeNetwork="activeNetwork"
        :flrSize="1.0"
        :sgbSize="0.6"
      />
      <span class="price">${{ currencyFormatter(activeNetwork.price, 5) }}</span>
    </div>
    <div class="flex">

    <Button
      class="desktop"
      :eventName="constant.INFORMATION" 
      @clicked="clicked($event)"
    >
      <InformationActive
        v-if="isActiveInfoSubNav"
       />
      <Information
        v-else
       />
    </Button>

    <Button 
      :eventName="constant.SETTINGS" 
      @clicked="clicked($event)"
    >
      <SettingsActive
        v-if="isActiveSettingsSubNav"
       />
      <Settings
        v-else
       />
    </Button>

    </div>
  </div>
</template>
<script setup>
  import { computed } from 'vue'
  import { event } from 'vue-gtag'
  import Button from '../../_generics/Button.vue'
  import Settings from '../../_icons/Settings.vue'
  import SettingsActive from '../../_icons/SettingsActive.vue'
  import Information from '../../_icons/Information.vue'
  import InformationActive from '../../_icons/InformationActive.vue'
  import ActiveNetworkLogo from '../../_icons/Network/ActiveNetworkLogo.vue'

  //DESKTOP
  import SimFassetLogo from '../../_icons/SimFassetLogo.vue'
  import QrCode from '../../_icons/QrCode.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    isActiveSettingsSubNav: {
      type: Boolean,
      default: false
    },
    isActiveInfoSubNav: {
      type: Boolean,
      default: false
    },
  })
  const constant = props.constant
  const emit = defineEmits([
    'nextScreen',
    'activateScreenSubNav',
  ])

  const currencyFormatter = (value, decimalCount = 2) => {
    if( value.toString().length > 9)
      return numberFormatter(value.toFixed(decimalCount))

    return numberFormatter(value.toFixed(decimalCount))
  }

  const numberFormatter = (value) => {
    return value.replace(/(\d)(?=(\d{5})+(?:\.\d+)?$)/g, "$1,")
  }

  const logoClicked = (event) => {
    trackEvent('logo', 'getStarted', 'logo_click')
    emit('nextScreen', event)
  }

  const clicked = (event) => {
    if(event === constant.SETTINGS) {
      trackEvent('settings', 'settings_page', 'settings_click')
    } else {
      trackEvent('information', 'information_page', 'information_click')
    }
    emit('activateScreenSubNav', event)
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/_variables.scss';
  @import '../../../assets/scss/mixins/layout.scss';

  #sub-nav {

    &.subnav {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 0 auto;
      background-color: $WHITE;
      padding: 5px 0 5px 0;
      max-width: $MAX-CONTENT-WIDTH;

      @include breakpoint(lg) {
        padding: 20px 0 5px 0;
      }
      @include breakpoint(xl) {
        padding: 20px 0 5px 0;
      }
      @include breakpoint(xxl) {
        padding: 20px 0 5px 0;
      }
      @include breakpoint(xxxl) {
        padding: 20px 0 5px 0;
      }

      .logo {
        margin: 0;
        padding: 0;
        height: 59px;
      }

      .network-price {
        justify-content: center;
        align-items: center;
        width: 150px;

        @include breakpoint(lg) {
          margin-left: -25px;
          width: 120px;
        }
        @include breakpoint(xl) {
          margin-left: -25px;
          width: 120px;
        }
        @include breakpoint(xxl) {
          margin-left: -25px;
          width: 120px;
        }
        @include breakpoint(xxxl) {
          margin-left: -25px;
          width: 120px;
        }

        .price {
          font-size: $FONT-DEFAULT-SIZE;
          color: $BLACK;
          margin-left: 10px;
        }
      }
    }

    button {
      margin: 0 10px;
      background: none;
      border: none;

      &:first-child {
        margin-right: 0;
      }
    }
  }
</style>