import Constant from '../global/Constant.js'
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MintView from '../views/MintView.vue'
import SimulateView from '../views/SimulateView.vue'

const router = createRouter({
  history : createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: `/${Constant.MINTING}`,
      name: Constant.MINTING,
      component: MintView
    },
    {
      path: `/${Constant.SIMULATE}`,
      name: Constant.SIMULATE,
      component: SimulateView
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Not Found',
      component: () => import('@/views/NotFoundView.vue')
    },
  ]
})

export default router