<template>
  <div class="nav-control-panel-phone">
    <Transition name="subnav">  
      <div
        v-if="networkScreen.isActive"
        :class="classes"
        class="subnav-cpanel right"
      >
        <AppNavControlSubPanelNetwork 
          :constant="constant"
          :networkScreen="networkScreen"
          :isActiveSgbNetwork="isActiveSgbNetwork"
          :isActiveFlrNetwork="isActiveFlrNetwork"
          @activateNetwork="activateNetwork($event)"
        />
      </div>
    </Transition>
  </div>
  <div class="nav-control-panel-phone right">
    <Transition name="subnav">
      <div
        v-if="shareScreen.isActive"
        :class="classes"
        class="subnav-cpanel left left-cpanel"
      >
        <AppNavControlSubPanelShare
          :constant="constant"
          :networkScreen="networkScreen"
          :isActiveSms="isActiveSms"
          @shareApplication="shareApplication($event)"
        />
      </div>
    </Transition>
  </div>
</template>
<script setup>
  import { computed } from 'vue'
  import AppNavControlSubPanelNetwork from './AppNavControlSubPanelNetwork.vue'
  import AppNavControlSubPanelShare from './AppNavControlSubPanelShare.vue'

  import Button from '../../_generics/Button.vue'

  import FlareNetworkIcon from '../../_icons/Network/FlareNetworkIcons.vue'
  import SongbirdNetworkIcon from '../../_icons/Network/SongbirdNetworkIcons.vue'
  import SmsShareIcon from '../../_icons/Share/SmsShareIcons.vue'
  import TwitterShareIcon from '../../_icons/Share/TwitterShareIcons.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    networkScreen: {
      type: Object,
      default: () => {}
    },
    shareScreen: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: String,
      default: ''
    },
    isActiveFlrNetwork: {
      type: Boolean,
      default: false
    },
    isActiveSgbNetwork: {
      type: Boolean,
      default: false
    },
    isActiveSms: {
      type: Boolean,
      default: false
    },
  })
  const constant = props.constant
  const emit = defineEmits([
    'activateNetwork',
    'shareApplication'
  ])

  const activateNetwork = (event) => {
    emit('activateNetwork', event)
  }

  const shareApplication = (event) => {
    emit('shareApplication', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/_variables.scss';
  @import '../../../assets/scss/mixins/layout.scss';
  @import '../../../assets/scss/partials/_transitions.scss';

  .subnav-cpanel {
    border-radius: 7px 50px 0 0;
  }
  .left-cpanel {
    border-radius: 50px 7px 0 0;
  }
  
  .nav-control-panel-phone {
    position: absolute;
    height: $NAV-HEIGHT;
    width: 40%;
    bottom: 0;
    background-color: $PURPLE;
    border-top: 3px solid $WHITE;

    @include breakpoint(sm) {
      width: 43%;
    }
    @include breakpoint(md) {
      width: 45%;
    }
    @include breakpoint(lg) {
      width: 46%;
    }
    @include breakpoint(xl) {
      width: 47%;
    }
    @include breakpoint(xxl) {
      width: 47%;
    }
    @include breakpoint(xxxl) {
      width: 47%;
    }
  }
</style>