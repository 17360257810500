<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.25 24.4923C.25 11.3244 10.8841.65126 24 .65126S47.75 11.3244 47.75 24.4923 37.1159 48.3333 24 48.3333.25 37.6602.25 24.4923Z" fill="#fff" stroke="#E61E57" stroke-width=".5"/>
    <mask id="a" fill="#fff">
      <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093.00001 30.8816 0 24.4923c0-6.3893 2.52856-12.517 7.02943-17.03493C11.5303 2.93943 17.6348.40127 24 .40127V48.5833Z"/>
    </mask>
    <path d="M24 48.5833c-6.3652 0-12.4697-2.5381-16.97056-7.0561C2.52857 37.0093.00001 30.8816 0 24.4923c0-6.3893 2.52856-12.517 7.02943-17.03493C11.5303 2.93943 17.6348.40127 24 .40127V48.5833Z" fill="#E61E57" stroke="#E61E57" stroke-width="1.5" mask="url(#a)"/>
    <circle cx="6.53366" cy="30.4586" r="2.03366" fill="#fff"/>
    <path d="M16.5225 22.5034H8.20844c-.98692 0-3.70844.9426-3.70844 3.9932h8.3141c1.0766 0 3.6486-.9727 3.7084-3.9932ZM20.5303 16.4923H8.20869c-.98693 0-3.70845.9426-3.70845 3.9932H16.7919c1.0767 0 3.6786-.9727 3.7384-3.9932Z" fill="#fff"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="m28.6905 16.5222 7.2404-.036c2.8794-.0144 6.9681 1.4245 7.0008 8.047.0323 6.5333-4.573 8.0391-6.9218 7.9479l-7.2404.036-.0318-6.454-2.2085.0109-.015-3.0306 2.2085-.011-.0322-6.5102Zm3.5384 3.0967 2.6557-.0132c1.6774-.0084 4.4745.2864 4.4986 5.169.0241 4.8827-3.5542 4.9005-4.4488 4.905-.7721.0038-1.3448.0084-1.7579.0116-.5561.0045-.8229.0066-.8978.0016l-.0179-3.6199 2.7955-.0139-.0145-2.9465-2.7956.014-.0173-3.5077Z" fill="#E61E57"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
</script>