<template>
  <Button 
    class="flex thumbnail-btn" 
    :eventName="'accordion'" 
    @clicked="clicked($event)"
  >
    <template #accordion>
      <TickerIcon 
        :constant="constant" 
        :coin="coin"  
        :width="48"
        :height="49"
        :size="0.8"
        class="coin" 
      />
      <div class="flex label">
        <span>{{ coin.ticker }}</span> 
        <span class="txt-default">&gt;</span>
        <span 
          class="txt-bold" 
          :class="{ 'minted': isMinted }">F{{ coin.ticker }}
        </span>
      </div>
      <Minus v-if="isActive" class="minus" />
      <Plus v-else class="plus" />
    </template>
  </Button>
</template>
<script setup>
  import { computed } from 'vue'
  import Button from '../_generics/Button.vue'
  import TickerIcon from '../_icons/Tickers/TickerIconManager.vue'
  import Plus from '../_icons/Plus.vue'
  import Minus from '../_icons/Minus.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    },
  })

  const constant = props.constant
  const emit = defineEmits(['activateAccordion'])
  
  const isMinted = computed(() => {
    return props.coin.mintQuantity > 0 ? true : false
  })

  const clicked = (event) => {
    emit(constant.ACCORDION_ACTIVATE, props.coin.id)
  }

</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .thumbnail-btn {
    padding: 0 20px;

    @include breakpoint(sm) {
      padding: 0 30px;
    }
    @include breakpoint(md) {
      padding: 0 30px;
    }
    @include breakpoint(lg) {
      padding: 0 30px;
    }
    @include breakpoint(xl) {
      padding: 0 30px;
    }
    @include breakpoint(xxl) {
      padding: 0 30px;
    }
    @include breakpoint(xxxl) {
      padding: 0 30px;
    }
  }
</style>