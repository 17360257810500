<template>
  <Carousel
    ref="carousel"
    :items-to-show="1"
    :autoplay="5000"
    :wrapAround="true"
    :transition="300"
  >
    <Slide v-for="slide in screensCarousel" :key="slide.id">
      <div
        class="carousel__item"
      >
        <img 
          :alt="slide.name"
          :src="slide.url"
        />
      </div>
    </Slide>

    <template #addons>
      <navigation />
      <pagination />
    </template>
  </Carousel>
</template>
<script setup>
  import { ref, watch } from 'vue'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  import 'vue3-carousel/dist/carousel.css'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    screensCarousel: {
      type: Array,
      default: () => []
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    }
  })

  const carousel = ref(null)

  watch(() => [props.activeNetwork.name], (newValue, oldValue) => {
    carousel.value.slideTo(0)
  })

</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';
</style>