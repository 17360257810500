<template>
  <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24.0002" cy="24.5002" r="23.1808" transform="rotate(.166 24.0002 24.5002)" fill="#fff" stroke="#000" stroke-width="1.5"/>
    <path d="M26.8747 5.87429 8.40443 37.8804l5.90817.0172 14.5632-25.0832 1.5054 6.3168-10.9068 18.7813 5.6594.0164 7.0931-11.8891 3.1062 11.9187 5.0996.0147-4.6742-18.049 3.3448-6.0851-4.913-.0142-2.1226-7.93556-5.193-.01505Z" fill="#000"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
  })

  const viewBox = `0 0 48 49`
  const width = props.width * props.size
  const height = props.height * props.size
</script>