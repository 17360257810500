<template>
    <svg 
    :width="width" 
    :height="height"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.0192 8.12539L6.248 12.832C5.18213 9.55938 3.58592 4.65905 2.76083 2.12782L10.0192 8.12539Z" :stroke="fill"/>
    <path d="M4.89245 17.9466L4.16649 23.9126L1.03418 24.8234L4.89245 17.9466Z" :stroke="fill"/>
    <path d="M12.4343 15.8584L6.16098 19.7247L6.69988 15.1516L12.4343 15.8584Z" :stroke="fill"/>
    <path d="M7.42456 13.7732L10.8637 9.45569L13.216 14.4581L7.42456 13.7732Z" :stroke="fill"/>
    <path d="M14.3811 13.542L13.1855 11.0425L15.6429 9.95201L14.3811 13.542Z" :stroke="fill"/>
    <path d="M17.1042 12.6181L16.4775 12.0594L16.7684 11.2322L17.1042 12.6181Z" :stroke="fill"/>
  </svg>
</template>
<script setup>
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 18
    },
    height: {
      type: Number,
      default: 26
    },
    size: {
      type: Number,
      default: 1.0
    },
    color: {
      type: String,
      default: '000000'
    }
  })
  const viewBox = `0 0 ${props.width} ${props.height}`
  const width = props.width * props.size
  const height = props.height * props.size
  const fill = `#${props.color}`
</script>