<template>
  <Transition>
    <div
      v-if="isActive"
      class="answer-card"
    > 
      <p 
        v-html="answer"
      >  
      </p>
    </div>
  </Transition>
</template>
<script setup>
  import { computed } from 'vue'
  const props = defineProps({
    id: {
      type: Number,
      default: 0
    },
    constant: {
      type: Object,
      default: () => {}
    },
    answer: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  })

  const constant = props.constant
</script>
<style lang="scss">
  @import '../../../../assets/scss/_variables.scss';
  @import '../../../../assets/scss/mixins/layout.scss';

  .answer-card {
    overflow-y: hidden;

    p {
      margin: 20px 30px 20px 50px;
      color: $GREY;
      text-align: left;
      height: calc($ACCORDION-FAQ-HEIGHT + 20px);
    }
  }

  .v-enter-from {
    height: 0;
  }
  .v-enter-to {
    height: $ACCORDION-FAQ-HEIGHT;
  }
  .v-enter-active {
    transition: height 0.15s ease-out;
  }

  .v-leave-from {
    height: $ACCORDION-FAQ-HEIGHT
  }
  .v-leave-to {
    height: 0;
  }
  .v-leave-active{
    transition: height 0.15s ease-in;
  }
</style>