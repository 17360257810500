<template>
  <nav>
    <!-- Non-Phone Navigation -->
    <AppNavControlPanel 
      :classes="'non-phone'"
      :constant="constant"
      :activeNetwork="activeNetwork"
      :networkScreen="networkScreen"
      :shareScreen="shareScreen"
      :isActiveFlrNetwork="isActiveFlrNetwork"
      :isActiveSgbNetwork="isActiveSgbNetwork"
      :isActiveSms="isActiveSms"
      @activateNetwork="activateNetwork($event)"
      @shareApplication="shareApplication($event)"
    />
    <!-- Phone Navigation -->
    <AppNavControlPanelPhone
      :classes="'phone'"
      :constant="constant"
      :activeNetwork="activeNetwork"
      :networkScreen="networkScreen"
      :shareScreen="shareScreen"
      :isActiveFlrNetwork="isActiveFlrNetwork"
      :isActiveSgbNetwork="isActiveSgbNetwork"
      :isActiveSms="isActiveSms"
      @activateNetwork="activateNetwork($event)"
      @shareApplication="shareApplication($event)"
    />

    <AppNavControlButtons 
      :constant="constant"
      :isActiveSimulateBtn="isActiveSimulateBtn"
      :isActiveFlrNetwork="isActiveFlrNetwork"
      :isActiveNetworkSubNav="networkScreen.isActive"
      :isActiveShareSubNav="shareScreen.isActive"
      :activeNetwork="activeNetwork"
      @clicked="clicked($event)"
    />
    <div>
      <AppCookieAgreement
        :constant="constant"
        :cookieDisclosureScreen="cookieDisclosureScreen"
        :cookies="cookies"
        @updateConfiguration="updateConfiguration($event)"
        @activateScreenSubNav="activateScreenSubNav($event)"
      />
    </div>
    <div class="buttons">
      <AppNavCookieAccess
        :constant="constant"
        :cookieDisclosureScreen="cookieDisclosureScreen"
        :cookies="cookies"
        @activateScreenSubNav="activateScreenSubNav($event)"
      />
      <AppNavAffiliateButton />
    </div>
  </nav>
</template>
<script setup>
  import { computed } from 'vue'
  import AppNavControlPanel from  './AppNavControlPanel.vue'
  import AppNavControlPanelPhone from  './AppNavControlPanelPhone.vue'

  import AppNavControlButtons from './AppNavControlButtons/AppNavControlButtons.vue'
  import AppNavAffiliateButton from './AppNavControlButtons/AppNavAffiliateButton.vue'

  import AppNavCookieAccess from '../AppCookies/AppNavCookieAccess.vue'
  import AppCookieAgreement from '../AppCookies/AppCookiesAgreement.vue'

  //Twitter Share Link
  //https://twitter.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]


  //X Share Link
  //https://x.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]


  //Vue School - Share Example
  //https://twitter.com/intent/tweet?text=Sharing some exciting news!
  //%0A%0A@VueSchool_io is offering FREE access to ALL of  its courses
  //%20%0A%0AThat's 1000+ lessons covering Vue.js 3, Pinia and much more! Including the awesome Vue.js 3 Masterclass!
  //%0A%0ASign up for the Free Weekend:&amp;url=https://vueschool.io/freeweekend23


  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    networks: {
      type: Array,
      default: () => []
    },
    networkScreen: {
      type: Object,
      default: () => {}
    },
    shareScreen: {
      type: Object,
      default: () => {}
    },
    isActiveSimulateBtn: {
      type: Boolean,
      default: false
    },
    isActiveSms: {
      type: Boolean,
      default: false
    },
    cookies: {
      type: Object,
      default: () => {}
    },
    cookieDisclosureScreen: {
      type: Object,
      default: () => {}
    }
  })
  const constant = props.constant
  const emit = defineEmits([
    'nextScreen', 
    'deactivateAccordions',
    'activateScreenSubNav',
    'activateNetwork',
    'updateConfiguration',
    'shareApplication'
  ])

  const isActiveFlrNetwork = computed(() => {
    return props.activeNetwork.isActive && props.activeNetwork.name === constant.FLARE_NETWORK
  })

  const isActiveSgbNetwork = computed(() => {
    return props.activeNetwork.isActive && props.activeNetwork.name === constant.SONGBIRD_NETWORK
  })

  const clicked = (event) => {
    if(event === constant.SIMULATE) {
      emit('nextScreen', event)
      emit(constant.ACCORDION_DEACTIVATE, event)
    } else {
      emit('activateScreenSubNav', event)
    }
  }

  const activateNetwork = (event) => {
    emit('activateNetwork', event)
  }
  const shareApplication = (event) => {
    emit('shareApplication', event)
  }

  const activateScreenSubNav = (event) => {
    emit('activateScreenSubNav', event)
  }

  const updateConfiguration = (event) => {
    emit('updateConfiguration', event)
  }

</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/_variables.scss';
  @import '../../../assets/scss/mixins/layout.scss';

  .buttons {
    display: flex;
    position: absolute;
    bottom: 65px;
    width: 24%;
    max-width: 80px;
    margin: 0 auto;
    left: 5%;
    z-index: -2;

    @include breakpoint(lg) {
      position: relative;
      left: 10px;
      margin-top: -30px;
      width: 100%;
      max-width: $MAX-CONTENT-WIDTH;
    }
    @include breakpoint(xl) {
      position: relative;
      left: 10px;
      margin-top: -30px;
      width: 100%;
      max-width: $MAX-CONTENT-WIDTH;
    }
    @include breakpoint(xxl) {
      position: relative;
      left: 10px;
      margin-top: -30px;
      width: 100%;
      max-width: $MAX-CONTENT-WIDTH;
    }
    @include breakpoint(xxxl) {
      position: relative;
      left: 10px;
      margin-top: -30px;
      width: 100%;
      max-width: $MAX-CONTENT-WIDTH;
    }
  }
</style>