<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 512 512" 
    style="enable-background:new 0 0 512 512" 
    xml:space="preserve"
    width="30"
    height="30"
    >
    <path d="M453.2 96.9c-12.4-15.8-26.6-30.2-42.7-42.4-15.8-12-33.1-22-52.3-30.1-30.7-12.8-66.6-20.5-102.4-20.5-38.4 0-71.7 7.7-102.4 20.5S94.5 55.1 71.5 78.2s-41 51.2-53.8 81.9-17.9 64-17.9 97.3 5.1 66.6 17.9 97.3 28.2 56.3 51.2 76.8l71.7-130.6 71.7 71.7 79.4-189.4-33.3-15.4 46.1-33.3 46.1-33.3 7.7 56.3 7.7 56.3-33.3-15.4-102.5 243.3-76.8-74.2-48.6 92.2c15.4 12.8 33.3 20.5 51.2 28.2 30.7 12.8 66.6 20.5 102.4 20.5 38.4 0 71.7-7.7 102.4-20.5 61.4-25.6 110.1-74.2 133.1-135.7 12.8-30.7 17.9-64 17.9-97.3v-1c-.2-48.9-15.5-96.5-43.5-136.6-5-7.1-10-13.9-15.1-20.4z" 
          style="fill-rule:evenodd;clip-rule:evenodd;fill:#4bc1be" 
          transform="translate(.2 -.1)"
    />
  </svg>
</template>
