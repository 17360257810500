import { defineStore } from 'pinia'
import Constant from '../global/Constant.js'

export const useNetworkStore = defineStore('networkStore', {
  state: () => ({
    networks: [
      {
        id: 1, 
        name: Constant.SONGBIRD_NETWORK,
        ticker: 'SGB',
        price: 0.00391,
        collateralPercentageRequired: 0,
        collateralManagement:[
          {
            id: 1,
            type: 'dual',
            name:'Dual',
            key:'nctp',
            value:'d',
            isActive: false,
            allocation: [
              {
                id: 1,
                key:'ncd1',
                name: "usd",
                ticker: 'Stable',
                set: 0.00,
                default: 0.65,
              },
              {
                id: 2,
                key:'ncd2',
                name: "Songbird",
                ticker: 'SGB',
                set: 0.00,
                default: 0.35,
              },
            ]
          },
          {
            id: 2,
            type:'tri',
            name:'Tri',
            key:'nctp',
            value:'t',
            isActive: true,
            allocation:[
              {
                id: 1,
                key:'nct1',
                name: "usd",
                ticker: 'Stable',
                set: 0.00,
                default: 0.40,
              },
              {
                id: 2,
                key:'nct2',
                name: "ethereum",
                ticker: 'BTC/ETH',
                set: 0.00,
                default: 0.35,
              },
              {
                id: 3,
                key:'nct3',
                name: "Songbird",
                ticker: 'SGB',
                set: 0.00,
                default: 0.25,
              },
            ]
          }
        ],
        modifiedPrice: 0,
        circulatingSupply: 14408597106,
        queryDataKeys:['n', 'nmdp'],
        isActive: false
      },
      {
        id: 2, 
        name: Constant.FLARE_NETWORK,
        ticker: 'FLR',
        price: 0.009353,
        collateralPercentageRequired: 0,
        collateralManagement:[
          {
            id: 1,
            type: 'dual',
            name:'Dual',
            key:'nctp',
            value:'d',
            isActive: false,
            allocation: [
              {
                id: 1,
                key:'ncd1',
                name: "usd",
                ticker: 'Stable',
                set: 0.00,
                default: 0.60,
              },
              {
                id: 2,
                key:'ncd2',
                name: "flare",
                ticker: 'FLR',
                set: 0.00,
                default: 0.40,
              },
            ]
          },
          {
            id: 2,
            type:'tri',
            name:'Tri',
            key:'nctp',
            value:'t',
            isActive: true,
            allocation:[
              {
                id: 1,
                key:'nct1',
                name: "usd",
                ticker: 'Stable',
                set: 0.00,
                default: 0.35,
              },
              {
                id: 2,
                key:'nct2',
                name: "ethereum",
                ticker: 'BTC/ETH',
                set: 0.00,
                default: 0.45,
              },
              {
                id: 3,
                key:'nct3',
                name: "flare",
                ticker: 'FLR',
                set: 0.00,
                default: 0.20,
              },
            ]
          }
        ],
        modifiedPrice: 0,
        circulatingSupply: 42854959669, //22887638033
        queryDataKeys:['n', 'nmdp'],
        isActive: false
      },
    ],
    network: {},
    error: {
      msg: '',
      val: false
    }
  }),
  getters: {
    getActiveNetwork() {
      return this.network
    },
    getNetworks() {
      return this.networks
    },

  },
  actions: {
    async fetchAllNetworkData() {
      Promise.all([
        await fetch(Constant.COIN_DATA_URL).then(res => res.json())
      ]).then((data) => {
        if(data && data[0].coins) {
          this.updateNetworkPrices(data[0].coins)
        }
      }).catch((e) => {
        console.log('We had an issue fetching NETWORK prices ', e)
        this.error.val = true
        this.error.msg = 'The Netowrk pricing API is down!'
      })
    },
    updateNetworkPrices(priceFeed) {
      this.getNetworks.forEach((network) => {
        const token = priceFeed.find(token => network.ticker === token.ticker)
          if(token && token.cSupply) {
            if(token.cSupply > network.circulatingSupply) {
              network.circulatingSupply = parseFloat(token.cSupply)
            }
            network.price = parseFloat(token.price)
          }
      })

      //Pricing API Call
      setTimeout(this.fetchAllNetworkData, Constant.FETCH_PRICE_DURATION)
    },
    deactivateNetworks() {
      this.networks.forEach((n) => {
        n.isActive = false
      })
    },
    resetModifiedCoinPrice(networkName) {
      const id = this.getNetworkId(networkName)
      const network = this.networks.find((n) => n.id === id)
      network.modifiedPrice = 0
    },
    setActiveNetwork(networkName){
      const id = this.getNetworkId(networkName)
      this.deactivateNetworks()

      const network = this.networks.find((n) => n.id === id)
      network.isActive = !network.isActive
      this.network = network
    },
    setModifiedPrice(priceDetails) {
      const network = this.networks.find((network) => network.id === priceDetails.id)
      network.modifiedPrice = priceDetails.price
    },
    getNetworkId(networkName) {
      return this.networks.find((network) => network.name.toLowerCase() === networkName.toLowerCase()).id
    },
    getNetworkNameById(id) {
      return this.networks.find((network) => network.id === id)
    },

    updateNetworkCollateralType(type) {
      this.getActiveNetwork.collateralManagement[0].isActive = type.checked
      this.getActiveNetwork.collateralManagement[1].isActive = !type.checked
    },
    updateNetworkCollateralAllocation(collateral) {
      const network = this.networks.find((network) => network.id === collateral.id)
      if(network.collateralManagement[0].isActive) {
        network.collateralManagement[0].allocation[0].set = collateral.data[0] / 100
        network.collateralManagement[0].allocation[1].set = collateral.data[1] / 100
      } else {
        network.collateralManagement[1].allocation[0].set = collateral.data[0] / 100
        network.collateralManagement[1].allocation[1].set = collateral.data[1] / 100
        network.collateralManagement[1].allocation[2].set = collateral.data[2] / 100
      }
    },
    updateNetworkCollateralPercentage(collateralDetails) {
      const network = this.networks.find((network) => network.id === collateralDetails.id)
      network.collateralPercentageRequired = collateralDetails.collateralPrecentageRequired
    },
    getNetworkCoinCollateralAllocationPercentage() {
      //console.log(this.getActiveNetwork.collateralManagement[1].allocation[2].set)
      //console.log(this.getActiveNetwork.collateralManagement[0].allocation[1].set)

      if(this.getActiveNetwork.collateralManagement[1].isActive)
        return this.getActiveNetwork.collateralManagement[1].allocation[2].set
      return this.getActiveNetwork.collateralManagement[0].allocation[1].set
    },

    removeQueryParam(qData, removeItem) {
      const data = qData.replace(removeItem, '')
      return data
    },
    getCollateralQueryParams() {
      const activeNetwork = this.getActiveNetwork
      let collateralQueryData = ''

      activeNetwork.collateralManagement.forEach((collateralType) => {

        //Set Network Collateral Type
        if(collateralType.isActive) {
          collateralQueryData += `&${collateralType.key}=${collateralType.value}`
        }

        collateralType.allocation.forEach((allocation) => {

          if(allocation.set != allocation.default){
            collateralQueryData += `&${allocation.key}=${allocation.set}`
          }
        })
      })
      return collateralQueryData
    },
    getQueryParams() {
      let queryParams = ''
      const activeNetwork = this.getActiveNetwork
      const activeNetworkParam = activeNetwork.name === Constant.SONGBIRD_NETWORK ? 'n=s' : 'n=f'
      const modifiedPrice = activeNetwork.modifiedPrice > 0 ? activeNetwork.modifiedPrice.toFixed(8) : ''
      
      queryParams += modifiedPrice === '' ? 
        `${activeNetworkParam}` : 
        `${activeNetworkParam}&${activeNetwork.queryDataKeys[1]}=${modifiedPrice}`
      queryParams += this.getCollateralQueryParams()
      return queryParams
      //?   %3F
      //= 	%3D
      //& 	%26
    },
    setNetworksDefaults() {
      this.getNetworks.forEach((network) => {
        network.collateralManagement.forEach((collateralType) => {
          collateralType.allocation.forEach((allocation) => {
              allocation.set = allocation.default
          })
        })
      })
    },
    setQueryNetworkData(queryParams) {
      this.setActiveNetwork(queryParams.n === 's' ? Constant.SONGBIRD_NETWORK : Constant.FLARE_NETWORK)
      if(queryParams.nmdp) {
        const networkPrice = {
          id: this.getActiveNetwork.id,
          price: parseFloat(queryParams.nmdp)
        }

        this.setModifiedPrice(networkPrice)
      }

      this.getActiveNetwork.collateralManagement.forEach((collateralType) => {
        let key = collateralType.key
        
        if(queryParams[key]) {
          if(queryParams[key] === collateralType.value) {
            collateralType.isActive = true
          } else {
            collateralType.isActive = false
          }
        }

        collateralType.allocation.forEach((allocation) => {
          let aKey = allocation.key
          if(queryParams[aKey]) {
            allocation.set = parseFloat(queryParams[aKey])
          }
        })
      })
    },

  }
})